import xpSolvService from "../axios/axios";

class HomeworkService {

    createHomework(data) {
        const config = {
            headers: {
                    'content-type': 'multipart/form-data'
                        }
                }
        return Promise.resolve(xpSolvService.post('genhw', data, config));
    }

    getHomeworkDetail(data){
        return Promise.resolve(xpSolvService.post('hwhist', data));
    }

    getHomeworkData(data){
        return Promise.resolve(xpSolvService.post('detailhw', data));
    }

    postComment(data){
        return Promise.resolve(xpSolvService.post('commenthw', data));
    }
    
}

export default HomeworkService;