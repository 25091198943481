import { Button, Stack, TextareaAutosize } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import base64 from 'react-native-base64';
import { useLocation } from 'react-router-dom';


import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import * as Yup from 'yup';
import Page from '../components/Page';
import { homeWorkApi } from '../service/index';


const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },

  headBG: {
    backgroundColor: '#e0e0e0'
  },
  borderRight500: {
    borderRight: '1px solid #e0e0e0'
  },

  bordertext: {
    border: "1px solid #e1e1e1",
    padding: "24px",
    borderRadius: "18px",
    width: "90%",
    margin: "auto"
  },
  bordepart: {
    borderBottom: "1px solid #e1e1e1",
    padding: "15px 0"
  },

  commentarea1: {
    /* background: "#089293", */
    background: "#D1F2EB",
    borderRadius: "15px 15px 0 15px ",
    padding: "8px",
    /* color: '#fff', */
    color: '#424949',
    width: '392px',
    marginLeft: 'auto',
  },
  commentpera: {
    fontSize: 13,
    fontWeight: '800',
  },
  commentarea2: {
    background: "#F2F3F4",
    color: '#000',
    borderRadius: "15px 15px 15px 0",
    padding: "8px",
    width: '392px',
    marginRight: 'auto',

  },
  headingpart2: {
    fontWeight: "500",
    color: "#089293",
    fontSize: "14px"
  },
  headingpart: {
    fontWeight: "400  !important",
    color: "#000 !important",
    fontSize: "14px"
  },  
  submissionDate: {
    fontWeight: "800  !important",
    color: "#000 !important",
    fontSize: "14px"
  },
  buttoncommen: {
    padding: '15px 23px',
    position: "absolute",
    right: "0",
    background: 'transparent',
    "&:hover": {
      background: '#fff',
    },
  },
  chipsmain: {
    background: "#007c7c14",
    marginRight: "5px",
    padding: "3px 8px",
    borderRadius: "27px",
    color: "#000",
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "30px",
    float: 'left',
    marginBottom: 6,
  },
  themeheading: {
    color: "#089293 !important",
    fontSize: "20px"

  },
  themeheading3: {
    color: "#000 !important"
  },
  widthtexrarea: {
    width: '100% !important',
    height: '100px !important',
    paddingright: 73,
    border: '2px solid lightgrey',
    borderRadius: '10px',
    "&:onfocus": {
      border: '2px solid lightgrey !important',
    },
  }
});




export default function HomeworkDetails() {
  const { state } = useLocation();
  const classes = useStyles();
  const [userDetails, setUserDetails] = useState();
  const [idStudentSchool, setIdStudentSchool] = useState(0);
  const [studentName, setStudentName] = useState('');
  const [isTeacher, setIsTeacher] = useState(false);
  const [idHomework, setIdHomework] = useState(0);
  const [homeworkDetails, setHomeworkDetails] = useState();
  const [comments, setComments] = useState([]);
  const [chapters, setChapters] = useState('');
  const [hWdetails, setHWDetails] = useState();
  const [imagesInfo, setImagesInfo] = useState([]);
  const [role, setRole] = useState('');
  const [additionalNote, setAdditionalNote] = useState('');

  useEffect(() => {
    if (state) {
      setIdHomework(state);
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      setUserDetails(userInfo[0]?.StudentInfo);
      setStudentName(userInfo[0].StudentInfo[0]?.FirstName);
      setIdStudentSchool(userInfo[0].StudentInfo[0]?.idStudentSchool);
      setRole(userInfo[0].StudentInfo[0]?.role);
      if (userInfo[0].StudentInfo[0]?.role === 'Teacher') {
        setIsTeacher(true);
      }
      fetchHWDetails(state, userInfo[0].StudentInfo[0]?.idStudentSchool);
    }
  }, []);

  const handleChangeText = (event) => {
    formik.setFieldValue("newComment", event.target.value);
  }
  const validationRules = {
    newComment: Yup.string().required('Comment required')
  }
  const TestSchema = Yup.object().shape(validationRules);

  const formik = useFormik({
    initialValues: {
      newComment: "",
    },
    validationSchema: TestSchema,
    onSubmit: (data) => {
      postNewComment(data);
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const postNewComment = (data) => {
    try {
      const reqsBody = {
        "idHW": idHomework,
        "idStudentSchool": idStudentSchool,
        "commentHW64": base64.encode(data.newComment)
      };
      homeWorkApi.postComment(reqsBody).then(
        (resp) => {
          if (resp.status === 200) {
            if (resp.data && resp.data.status === 200 && resp.data.data !== "NA") {
              fetchHWDetails(idHomework, idStudentSchool);
              formik.resetForm();
            }
            else {
              alert(resp.data.message);
            }
          }
        },
        (error) => {
          alert("Error in HomeworkDetail");
        }
      );
      formik.setSubmitting(false);
    }
    catch (error) {
      console.log("Homework Catch", error);
    }
  }
  const fetchHWDetails = (idHomework, idStudentSch) => {
    try {
      const reqsBody = {
        "idHW": idHomework,
        "idStudentSchool": idStudentSch
      };
      homeWorkApi.getHomeworkData(reqsBody).then(
        (resp) => {
          if (resp.status === 200) {
            if (resp.data && resp.data.status === 200 && resp.data.data !== "NA") {
              setHomeworkDetails(resp.data.data[0]);
              setComments(resp.data.data[0].CommentInfo);
              setChapters(resp.data.data[0].ChapterInfo[0].Chapter);
              setHWDetails(resp.data.data[0].HWDetailInfo[0])
              setImagesInfo(resp.data.data[0].ImageInfo);

              const base64Note = base64.decode(resp.data.data[0].HWDetailInfo[0]?.Notes64);
              setAdditionalNote(base64Note);
            }
            else {
              alert(resp.data.message);
            }

          }
        },
        (error) => {
          alert("Error in HomeworkDetail");
        }
      );
    }
    catch (error) {
      console.log("Homework Detail Catch", error);
    }
  }

  return (
    <Page title="Homework Details| XPSOLV">
      <Stack className={classes.bordertext}>
        <Typography variant="h4" gutterBottom className={classes.themeheading}>
          <Box component="span" display="inline" className={classes.themeheading3}>Homework</Box> Details
        </Typography>

        <Grid container spacing={2} className={classes.bordepart}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom className={classes.headingpart2}>
              Received On

            </Typography>
            <Box component="p" display="inline" className={classes.headingpart}> {hWdetails?.PublishDate}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom className={classes.headingpart2}>
              {/* Published By */} Teacher
            </Typography>
            <Box component="p" display="inline" className={classes.headingpart}> {hWdetails?.FirstName} {hWdetails?.LastName}
            </Box>
          </Grid>
          
          <Grid item xs={12}>
            <Typography variant="p" gutterBottom className={classes.headingpart2}>
              Submission Date               
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="p" gutterBottom>              
              <Box component="span" display="inline" className={classes.submissionDate}>
                {hWdetails?.SubmissionDate}
              </Box>
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.bordepart} >
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom className={classes.headingpart2}>
              Subject

            </Typography>
            <Box component="p" display="inline" className={classes.headingpart}> {hWdetails?.Subject}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom className={classes.headingpart2}>
              Chapter

            </Typography>
            {chapters && chapters.split("|")?.map((chptr, index) => (
              <Box key={index} component="p" display="inline" className={classes.chipsmain}> {chptr}
              </Box>
            ))}
          </Grid>

        </Grid>

        <Grid container spacing={2} className={classes.bordepart} >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="p" marginBottom={2} gutterBottom className={classes.headingpart2}>
              Image | Picture | Screenshot
            </Typography>           
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>            
            {imagesInfo && imagesInfo?.map((img, index) => (
              <InnerImageZoom key={index} style={{ width: '100%', height: "100%" }}
              //      src={`https://xpsolv.com/${homeworkDetails.weblocation}/${img.ImageFileName}`} // Prod
                    src={`https://qa.xpsolv.com/${homeworkDetails.weblocation}/${img.ImageFileName}`} // QA
                alt="imgtest" className={classes.imgplan}
                zoomPreload zoomType='hover' fullscreenOnMobile zoomScale={1.5} hideCloseButton hideHint />
            ))}
          </Grid>
        </Grid>
        <Typography variant="p" gutterBottom className={classes.headingpart2}>
          Description OR Additional Notes
        </Typography>
        <Grid container spacing={2} className={classes.bordepart} >
          <Grid item xs={12}>
            <Typography variant="p" gutterBottom className={classes.headingpart}>
              {additionalNote}
            </Typography>
          </Grid>        
        </Grid>

        <div>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container component={Paper} className={classes.chatSection}>
                <Grid item xs={12}>
                  <Typography variant="p" gutterBottom className={classes.headingpart2}>
                    Comments
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {comments &&
                    comments?.map((comment, index) => (
                      <List key={index} className={classes.messageArea}>
                        {role === comment.Role && <ListItem key="1">
                          <Grid container className={classes.commentarea2} >
                            <Grid item xs={12} >
                              <div align="left" >
                                <p className={classes.commentpera}>{`${comment.FirstName} @ ${comment.CommentDate}`}</p>
                              </div>
                            </Grid>
                            <Grid item xs={12}>
                              <ListItemText align="left" primaryTypographyProps={{ fontSize: '14px', color: '#212F3C' }} primary={base64.decode(comment.commentHW64)}> </ListItemText>
                            </Grid>
                          </Grid>
                        </ListItem>}
                        {role !== comment.Role && <ListItem key="2">
                          <Grid container className={classes.commentarea1} >
                            <Grid item xs={12}>
                              <div align="left" >
                                <p className={classes.commentpera}>{`${comment.FirstName} @ ${comment.CommentDate}`}</p>
                              </div>
                            </Grid>
                            <Grid item xs={12}>
                              <ListItemText align="left" primaryTypographyProps={{ fontSize: '14px', color: '#212F3C' }} primary={base64.decode(comment.commentHW64)}> </ListItemText>
                            </Grid>
                          </Grid>
                        </ListItem>}
                      </List>))}
                  <Divider />
                  <Grid container style={{ paddingTop: '20px' }}>
                    <Grid item xs={12}><Typography variant="p" gutterBottom className={classes.headingpart2}>Add Comments</Typography></Grid>
                    <Grid item xs={12} style={{ position: 'relative', paddingTop: '10px' }}>
                      <TextareaAutosize
                        aria-label="minimum height"
                        defaultValue="Comment Here..."
                        id="outlined-basic-email" 
                        label="Comment here..." 
                        placeholder='Add queries OR clarications...'
                        fullwidth="true"
                        {...getFieldProps('newComment')}
                        onChange={handleChangeText}
                        error={(touched.newComment && errors.newComment)}
                        helpertext={(touched.newComment && errors.newComment)}
                        className={classes.widthtexrarea}
                      />

                    </Grid>
                    <Grid item xs={12}>
                      <Button variant="contained"
                        /* className={classes.buttoncommen} */
                        loading={isSubmitting.toString()}
                        type="submit">
                        Submit
                      </Button>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </div>
      </Stack>
    </Page>
  );
}
