import * as Yup from 'yup';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';

// material
import {
  Card,
  Paper,
  Stack,
  Button,
  Container,
  Typography,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Chip,
  OutlinedInput,
  Box,
  Grid,
  Stepper,
  Step,
  StepLabel,
  FormLabel,
  FormGroup,
  Checkbox
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import { LoadingButton } from '@material-ui/lab';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import { TestApi, utilApi } from '../service/index';
import HSeparator from '../theme/Separator';

const steps = [
  'Primary Question',
  'Question Details',
  'Answer Details',
  'Review & Finish',
];


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  centerwidth: {
    margin: 'auto',
  },
  steppershaodw: {
    boxShadow: '1px 1px 10px #000',
  },
  textcolor: {
    color: '#007c7c',
  },
  textcneter: {
    textAlign: 'center',
    marginBottom: 18,
  },
  centerwidth2: {
    margin: 'auto',
    border: '1px solid #d9d9d9',
    borderRadius: 10,
    marginTop: 40,
  },
  textright: {
    textAlign: 'left',
    borderBottom: '1px solid #d9d9d9',
    padding: '10px 30px',
    fontWeight: 600,
  },
  paddingten: {
    padding: 30,
  },
  paddingten2: {
    padding: 30,
    borderBottom: '1px solid #d9d9d9',
  },
  tenalign2: {
    fontWeight: 500,
    padding: '16px 47px',
    borderRadius: 4,
    marginLeft: '14px',
    display: 'block',
    
  },
  tenalign: {
    fontWeight: 500,
    padding: '12px 72px',
    borderRadius: 4,
    marginLeft: 'auto',
    display: 'block',
  },
  summarytest: {
    fontWeight: 600,
  },
  bgcheckbox: {
    margin: 30,
    padding: '34px',
    background: '#e7fff1',
    maxWidth: '-webkit-fill-available',
    borderRadius: 10,
  },
  colrotxt: {
    color: '#007c7c',
    fontWeight: 600,

  },
  imgplan2: {
    width: '24px',

  },
  colrotxt2: {
    margin: '9px 0',
    fontWeight: 600,
    display: 'inline-flex',
    fontSize: 16,
    width: '72px',
  },
  butnspacing: {
    padding: '0 33px 29px',
  },
  imgplan4: {
    width: '75px',
    margin: 'auto',
  },
  textclear: {
    textAlign: 'center',
    fontWeight: 500,
  },
  boxicon: {
    border: '1px solid #d7d7d7',
    margin: 10,
    padding: 15,
    borderRadius: 6,
  },
  textclear1: {
    color: '#007c7c',
    textAlign: 'center',
    fontWeight: 500,
  },
  bgsummary: {
    background: '#ececec',
    padding: '15px',
    borderRadius: 5,
    marginBottom: 30,
    margin: 10,
  },
  summarytext: {
    margin: 0,
    fontWeight: 600,
  },
  summarytext2: {
    margin: 0,
    fontWeight: 600,
    textAlign: 'right',
  },
  textselect: {
    fontWeight: 600,
  },
  bganker:{
    background:'#007c7c',
    color: "#fff", 
    fontWeight: "500", 
    width: "40px", 
    height: "40px", 
    borderRadius: "51px", 
    justifyContent: "center", 
    alignItems: "center", 
    display: "flex",
    margin: "8px"
  },
  textxolorgreen:{
    color:'#007c7c',
    fontWeight:600,
  },
  questions:{
    fontWeight:600,
  },
  widthicon:{
    maxWidth: '5.333333% !important',
  },
  correctanswer:{
    background:'#ececec',
    padding: "21px", 
    borderRadius: "6px", 
    marginBottom: "25px"
  },
  centericonrigt:{
    justifyContent: "center", 
    alignItems: "center", 
    display: "flex",
    maxWidth: "5.333333% !important"
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, chapters, theme) {
  return {
    fontWeight:
      chapters.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function PlanTest() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { state } = useLocation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState('');
  const [showUser, setShowUser] = useState({
    firstName: '', lastName: '', className: '', board: ''
  })
  const [chapterList, setChapterList] = useState([]);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [initialize, setInitialize] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [downloadId, setDownloadID] = useState('');
  const [chapters, setChapters] = useState([]);
  const [chaptersIds, setChaptersIds] = useState([]);

  useEffect(() => {
    if (state) {
      setSelectedUser(state);
      initializeTest(state.student.id);
    }
  }, []);



  const handleClose = () => {
    setOpen(false);
  };

  const TestSchema = Yup.object().shape({
    testSubject: Yup.string().required('Subject is required'),
    //   chaptersIds: Yup.string().required('Chapter is required'),
    format: Yup.string().required('Format is required'),
    level: Yup.string().required('Level is required')
  });

  const formik = useFormik({
    initialValues: {
      testSubject: "",
      //   chaptersIds: [],
      format: "",
      level: ""
    },
    validationSchema: TestSchema,
    onSubmit: (data) => {
      createTest(data);
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const initializeTest = (data) => {
    try {
      TestApi.initializeTest(data).then(
        (resp) => {
          if (resp.status === 200) {
            if (resp.data.status === 200) {
              setInitialize(resp.data.data);
            }
            else {
              alert(resp.data.message);
            }
          }
          else {
            alert(resp.message);
          }
        },
        (error) => { alert("Error in initializeTest"); }
      );
    }
    catch (error) {
      console.log("initializeTest Catch", error);
    }
  }

  const createTest = (data) => {

    try {
      // setOpen(true);
      const reqsBody = {
        "pFormatId": data.format.toString(),
        "idClassSubject": data.testSubject.toString(),
        "chapterIds": chaptersIds.toString(),
        "levelId": data.level.toString(),
        "idStudentSchool": selectedUser.id.toString(),
        "nonRepeatDuration": "-15"
      };
      console.log("reqsBody", reqsBody);

      TestApi.orderTest(reqsBody).then(
        (resp) => {
          if (resp.status === 200) {
            if (resp.data.status === 200) {
              downloadPDF(resp.data.data);
              setDownloadID(resp.data);
            }
            else {
              alert(resp.data.message);
            }
          }
          else {
            alert(resp.message);
          }
        },
        (error) => { alert("error in create test"); }
      );

      formik.resetForm();
    }
    catch (error) {
      console.log("create test Catch", error);
    }
  }
  const downloadPDF = (testId) => {
    try {
      if (testId) {
        TestApi.downloadTest(testId).then(
          (resp) => {
            if (resp.status === 200) {
              // Generate PDF
              utilApi.base64ToPDF(resp.data.data, "TestPaper.pdf");
              navigate('/dashboard/app', { replace: true });
            }
            else {
              alert(resp.message);
            }
          },
          (error) => { alert("Error in download pdf"); }
        );
      }
    }
    catch (error) {
      console.log("download pdf Catch", error);
    }
  }
  const handleChange = (event) => {
    if (initialize) {

      // eslint-disable-next-line array-callback-return
      initialize?.standardSubjects?.map((item) => {
        if (item.subject.id === event.target.value) {
          setChapterList(item.chapters);
          formik.setFieldValue("testSubject", event.target.value);
        }
      });
    }
  };
  const storeChapterIds = chaptersIds;
  const handleChangeChapter = (event, ids) => {
    const {
      target: { value },
    } = event;
    const name = event.target.innerText;
    // setChapters(
    //   // On autofill we get a the stringified value.
    //   typeof name === 'string' ? name.split(',') : name,
    // );
    setChaptersIds(
      // On autofill we get a the stringified value.

      typeof value === 'string' ? value.split(',') : value,
      // storeChapterIds.forEach(id => value.push(id)),

    );
  };


  return (
    <Page title="Plan Test | XPSOLV">
      <Typography variant="h4" gutterBottom className={classes.textcneter}>
        Add New <Box component="span" display="inline" className={classes.textcolor}>Question</Box>
      </Typography>
      <Box sx={{ width: '70%' }} className={classes.centerwidth}>
        <Stepper activeStep={0} alternativeLabel >
          {steps.map((label) => (
            <Step key={label} >
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box sx={{ width: '70%' }} className={classes.centerwidth2}>
        <Typography variant="h5" gutterBottom className={classes.textright}>
          Primary <Box component="span" display="inline" className={classes.textcolor}>Question</Box>
        </Typography>
        <Grid container className={classes.paddingten}>
          <Grid lg={6} md={6} sm={12} xs={12}>
            <Stack justifyContent="space-between" sx={{ mb: "2rem", mr: "1rem" }}>
              <InputLabel id="demo-simple-select-filled-label" className={classes.label}>Class</InputLabel>
              <Select
                fullWidth
                className={classes.mtten}
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                label="Format"
                {...getFieldProps('format')}
                error={Boolean(touched.format && errors.format)}
                helperText={touched.format && errors.format}
              >
                <MenuItem value="">
                  <em>Select Format</em>
                </MenuItem>
                {initialize?.testFormats?.map((option, index) => (
                  <MenuItem key={index} value={option.id}>
                    {option.pformat}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Grid>
          <Grid lg={6} md={6} sm={12} xs={12}>
            <Stack justifyContent="space-between">
              <InputLabel id="demo-simple-select-filled-label" className={classes.label}>Subject</InputLabel>
              <Select
                fullWidth
                className={classes.mtten}
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                label="Level"
                {...getFieldProps('level')}
                error={Boolean(touched.level && errors.level)}
                helperText={touched.level && errors.level}
              >
                <MenuItem value="">
                  <em>Select Level</em>
                </MenuItem>
                {initialize?.levels?.map((option, index) => (
                  <MenuItem key={index} value={option.id}>
                    {option.level}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Grid>
          <Grid lg={6} md={6} sm={12} xs={12}>
            <Stack justifyContent="space-between" sx={{ mb: "2rem", mr: "1rem" }}>
              <InputLabel id="demo-simple-select-filled-label" className={classes.label}>Chapter</InputLabel>
              <Select
                fullWidth
                className={classes.mtten}
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                label="Format"
                {...getFieldProps('format')}
                error={Boolean(touched.format && errors.format)}
                helperText={touched.format && errors.format}
              >
                <MenuItem value="">
                  <em>Select Format</em>
                </MenuItem>
                {initialize?.testFormats?.map((option, index) => (
                  <MenuItem key={index} value={option.id}>
                    {option.pformat}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Grid>
          <Grid lg={6} md={6} sm={12} xs={12}>
            <Stack justifyContent="space-between">
              <InputLabel id="demo-simple-select-filled-label" className={classes.label}>Topic</InputLabel>
              <Select
                fullWidth
                className={classes.mtten}
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                label="Level"
                {...getFieldProps('level')}
                error={Boolean(touched.level && errors.level)}
                helperText={touched.level && errors.level}
              >
                <MenuItem value="">
                  <em>Select Level</em>
                </MenuItem>
                {initialize?.levels?.map((option, index) => (
                  <MenuItem key={index} value={option.id}>
                    {option.level}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Grid>
          <Grid lg={12} md={6} sm={12} xs={12}>
            <Stack justifyContent="space-between">
              <InputLabel id="demo-simple-select-filled-label" className={classes.label}>Question Type</InputLabel>
              <Select
                fullWidth
                className={classes.mtten}
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                label="Level"
                {...getFieldProps('level')}
                error={Boolean(touched.level && errors.level)}
                helperText={touched.level && errors.level}
              >
                <MenuItem value="">
                  <em>Select Level</em>
                </MenuItem>
                {initialize?.levels?.map((option, index) => (
                  <MenuItem key={index} value={option.id}>
                    {option.level}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Grid>
          <Grid lg={12} md={6} sm={12} xs={12} sx={{ mt: 4 }}>
            <Button variant="contained" className={classes.tenalign}>Next</Button>
          </Grid>
        </Grid>

      </Box>
      <Box sx={{ width: '70%' }} className={classes.centerwidth2}>
        <Typography variant="h5" gutterBottom className={classes.textright} >
          Question <Box component="span" display="inline" className={classes.textcolor}>Details</Box>
        </Typography>

        <Grid container className={classes.paddingten2}>
          <Grid lg={6} md={6} sm={12} xs={12}>
            <Stack justifyContent="space-between" >
              <InputLabel id="demo-simple-select-filled-label" className={classes.label}>Level</InputLabel>
              <Select
                fullWidth
                className={classes.mtten}
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                label="Level"
                {...getFieldProps('level')}
                error={Boolean(touched.level && errors.level)}
                helperText={touched.level && errors.level}
              >
                <MenuItem value="">
                  <em>Select Level</em>
                </MenuItem>
                {initialize?.levels?.map((option, index) => (
                  <MenuItem key={index} value={option.id}>
                    {option.level}
                  </MenuItem>
                ))}
              </Select>
            </Stack>

          </Grid>


          <Grid lg={12} md={6} sm={12} xs={12} >
            <Typography variant="h6" gutterBottom className={classes.textselect} sx={{ mt: 3 }}>
              Summary and  <Box component="span" display="inline" className={classes.textcolor}>Question Header</Box>
            </Typography>
          </Grid>
          <Grid lg={6} md={6} sm={12} xs={12}>
            <Stack justifyContent="space-between" >
              <InputLabel id="demo-simple-select-filled-label" className={classes.label}>Text Goes Here</InputLabel>
              <Select
                fullWidth
                className={classes.mtten}
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                label="Level"
                {...getFieldProps('level')}
                error={Boolean(touched.level && errors.level)}
                helperText={touched.level && errors.level}
              >
                <MenuItem value="">
                  <em>Select Level</em>
                </MenuItem>
                {initialize?.levels?.map((option, index) => (
                  <MenuItem key={index} value={option.id}>
                    {option.level}
                  </MenuItem>
                ))}
              </Select>
            </Stack>

          </Grid>
          <Grid lg={6} md={6} sm={12} xs={12}>
            <Stack justifyContent="space-between" >
              <InputLabel id="demo-simple-select-filled-label" className={classes.label}>Text</InputLabel>
              <Select
                fullWidth
                className={classes.mtten}
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                label="Level"
                {...getFieldProps('level')}
                error={Boolean(touched.level && errors.level)}
                helperText={touched.level && errors.level}
              >
                <MenuItem value="">
                  <em>Select Level</em>
                </MenuItem>
                {initialize?.levels?.map((option, index) => (
                  <MenuItem key={index} value={option.id}>
                    {option.level}
                  </MenuItem>
                ))}
              </Select>
            </Stack>

          </Grid>
          <Grid lg={12} md={6} sm={12} xs={12} sx={{ mt: 3 }}>
            <InputLabel id="demo-simple-select-filled-label" className={classes.label}>Describe Question</InputLabel>
            <FormControl fullWidth variant="standard">
              <TextField id="outlined-basic" label="Describe Question" variant="outlined" />

            </FormControl>
          </Grid>
          <Grid lg={5} md={6} sm={12} xs={12} sx={{ mt: 3 }}>
            
            <FormControl fullWidth variant="standard">
              <TextField id="outlined-basic" label="Browse File for Diagram" variant="outlined" />
             
            </FormControl>
          </Grid>
          <Grid lg={3} md={6} sm={12} xs={12} sx={{ mt: 3 }}>
            <Button
              variant="contained"
              component="label"
              className={classes.tenalign2}
            >
              Upload File
              <input
                type="file"
                hidden
              />
            </Button>
          </Grid>
          <Grid lg={12} md={6} sm={12} xs={12} sx={{ mt: 1 }} >
          <Typography variant="h6" gutterBottom className={classes.textselect} sx={{ mt: 3 }}>
              For  <Box component="span" display="inline" className={classes.textcolor}>MCQ</Box>
            </Typography>
            </Grid>
            <Grid container sx={{ mb: 2 }}>
            <Grid lg={1} md={6} sm={12} xs={12} sx={{ mt: 1 }} >
            <Typography variant="h6" gutterBottom className={classes.bganker} sx={{ mt: 3 }}>
              A
            </Typography>
            </Grid>
            <Grid lg={6} md={6} sm={12} xs={12} sx={{ mt: 1 }} >
            <FormControl fullWidth variant="standard">
              <TextField id="outlined-basic" label="A" variant="outlined" />
             
            </FormControl>

            </Grid>
            </Grid>
            <Grid container sx={{ mb: 2 }}>
            <Grid lg={1} md={6} sm={12} xs={12} sx={{ mt: 1 }} >
            <Typography variant="h6" gutterBottom className={classes.bganker} sx={{ mt: 3 }}>
              B
            </Typography>
            </Grid>
            <Grid lg={6} md={6} sm={12} xs={12} sx={{ mt: 1 }} >
            <FormControl fullWidth variant="standard">
              <TextField id="outlined-basic" label="B" variant="outlined" />
             
            </FormControl>

            </Grid>
            </Grid>
            <Grid container sx={{ mb: 2 }}>
            <Grid lg={1} md={6} sm={12} xs={12} sx={{ mt: 1 }} >
            <Typography variant="h6" gutterBottom className={classes.bganker} sx={{ mt: 3 }}>
              C
            </Typography>
            </Grid>
            <Grid lg={6} md={6} sm={12} xs={12} sx={{ mt: 1 }} >
            <FormControl fullWidth variant="standard">
              <TextField id="outlined-basic" label="C" variant="outlined" />
             
            </FormControl>

            </Grid>
            </Grid>
            <Grid container sx={{ mb: 2 }}>
            <Grid lg={1} md={6} sm={12} xs={12} sx={{ mt: 1 }} >
            <Typography variant="h6" gutterBottom className={classes.bganker} sx={{ mt: 3 }}>
              D
            </Typography>
            </Grid>
            <Grid lg={6} md={6} sm={12} xs={12} sx={{ mt: 1 }} >
            <FormControl fullWidth variant="standard">
              <TextField id="outlined-basic" label="D" variant="outlined" />
             
            </FormControl>

            </Grid>
            </Grid>
            
        </Grid>


        <Grid lg={12} md={6} sm={12} xs={12} className={classes.butnspacing}>
          <Button variant="contained" className={classes.tenalign}>Next</Button>
        </Grid>
      </Box>
      <Box sx={{ width: '70%' }} className={classes.centerwidth2}>
        <Typography variant="h5" gutterBottom className={classes.textright}>
          Answer <Box component="span" display="inline" className={classes.textcolor}>Detail</Box>
        </Typography>
      
        <Grid container className={classes.paddingten}>
        <Grid lg={12} md={6} sm={12} xs={12}>
        <Typography variant="h6" gutterBottom className={classes.textxolorgreen}>
            Question 
          </Typography>
          </Grid>
          <Grid lg={1} md={6} sm={12} xs={12} className={classes.widthicon}>
          <img src="/static/mock-images/avatars/check.png" alt="imgtest" className={classes.imgplan2} />
          </Grid>
          <Grid lg={11} md={6} sm={12} xs={12}>
          <Typography variant="h6" gutterBottom className={classes.questions}>
          How many integers between 2 and 20, even only, can be the sum of two different prime numbers? 

        </Typography>
          </Grid>
          <Grid lg={12} md={6} sm={12} xs={12}>
        <Typography variant="h6" gutterBottom className={classes.textxolorgreen}>
            Answer 
          </Typography>
          </Grid>
          <Grid lg={12} md={6} sm={12} xs={12} sx={{ mt: 1 }} >
            <FormControl fullWidth variant="standard">
              <TextField id="outlined-basic" label="Browse File for Diagram" variant="outlined" />
             
            </FormControl>

            </Grid>
            <Grid lg={5} md={6} sm={12} xs={12} sx={{ mt: 3 }}>
            
            <FormControl fullWidth variant="standard">
              <TextField id="outlined-basic" label="Browse File for Diagram" variant="outlined" />
             
            </FormControl>
          </Grid>
          <Grid lg={3} md={6} sm={12} xs={12} sx={{ mt: 3 }}>
            <Button
              variant="contained"
              component="label"
              className={classes.tenalign2}
            >
              Upload File
              <input
                type="file"
                hidden
              />
            </Button>
          </Grid>
          <Grid lg={12} md={6} sm={12} xs={12} sx={{ mt: 3 }}>
            <Box className={classes.correctanswer}>
            <Typography variant="h6" gutterBottom className={classes.questions}>
          Correct <Box component="span" display="inline" className={classes.textcolor}>Answer</Box>
        </Typography>
        <Grid container>
        <Grid lg={1} md={6} sm={12} xs={12} className={classes.centericonrigt}>
          <img src="/static/mock-images/avatars/check.png" alt="imgtest" className={classes.imgplan2} />
          </Grid>
          <Grid lg={5} md={6} sm={12} xs={12}>
          
          <FormControl fullWidth variant="standard">
              <TextField id="outlined-basic" label="Browse File for Diagram" variant="outlined" />
             
            </FormControl>
      
          </Grid>
          </Grid>
            </Box>
          </Grid>
         
        
          <Grid lg={12} md={6} sm={12} xs={12} >
            <Button variant="contained" className={classes.tenalign}>Next</Button>
          </Grid>
        </Grid>

      </Box>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Assess Test</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Test generated successfully| Please proceed ...
          </DialogContentText>
          <div className={classes.root}>
            {/* <Button variant="contained" onClick={downloadPDF}>Download PDF</Button> */}
            <LoadingButton
              variant="contained"
              loading={isSubmitting}
              onClick={downloadPDF}
            >
              Download PDF
            </LoadingButton>
            <Button variant="contained" color="primary">
              Send Email
            </Button>
            <Button variant="contained" color="secondary">
              Start Online
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}
