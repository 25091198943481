import * as Yup from 'yup';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';

// material
import {
  Card,
  Paper,
  Stack,
  Button,
  Container,
  Typography,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Chip,
  OutlinedInput,
  Box,
  Grid,
  Stepper,
  Step,
  StepLabel,
  FormLabel,
  FormGroup,
  Checkbox,
  ButtonGroup
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import { LoadingButton } from '@material-ui/lab';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import { TestApi, utilApi } from '../service/index';
import HSeparator from '../theme/Separator';

const steps = [
  'Primary Information',
  'Summary & Breakup',
  'Review & Fin',
];


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  centerwidth: {
    margin: 'auto',
  },
  steppershaodw: {
    boxShadow: '1px 1px 10px #000',
  },
  textcolor: {
    color: '#ffffff',
  },
  textcolor2: {
    color: '#478778',
  },
  textcneter: {
    textAlign: 'left',
    marginBottom: 18,
  },
  tenalign: {
    fontWeight: 500,
    padding: '16px 72px',
    borderRadius: 4,
    marginTop: '23px',
  },
  boxborder: {
    border: '1px solid #dbdbdb',
    borderRadius: 12,
    [theme.breakpoints.up('xs')]: {
      margin:'0px 15px',
    },
  },
  spacingslect: {
    marginBottom: 20,
    paddingRight:23,
  },
  borderright:{
    borderRight:'1px solid #dbdbdb',
    [theme.breakpoints.up('xs')]: {
      border:'none',
    },
  },
  imgplan2:{
    width:'30px',
    marginRight:8,
  },
  imgplan3:{
    width:'30px',
    marginRight:8,
    position:'relative',
    top:'-7px',
  },
  questionlist:{
    display:'inline-flex',
    fontSize:17,
    fontWeight:600,
  },
  centerdiv:{
    marginTop: '123px',
    marginLeft: '63px',
    [theme.breakpoints.up('xs')]: {
      marginTop: '0',
    },
  },
  headingschool:{
    display:'inline-flex',
    fontWeight:600,
    fontSize:21,
  },
  borderalign:{
    borderBottom:'1px solid #dbdbdb',
    padding: '13px 13px 5px 25px',
    
  },
  paddingmain:{
    padding: '23px',
  },
  tenalignnew: {
    fontWeight: 500,
    padding: '12px 72px',
    borderRadius: 4,
    background:'#000',
  },
  tenalignnew2: {
    fontWeight: 500,
    padding: '12px 32px',
    borderRadius: 4,
    marginLeft: 'auto',
    display: 'block',

  },
  submitbutn:{
    padding:23,
    borderTop:'1px solid #dbdbdb',
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, chapters, theme) {
  return {
    fontWeight:
      chapters.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function PlanTest() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { state } = useLocation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState('');
  const [showUser, setShowUser] = useState({
    firstName: '', lastName: '', className: '', board: ''
  })
  const [chapterList, setChapterList] = useState([]);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [initialize, setInitialize] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [downloadId, setDownloadID] = useState('');
  const [chapters, setChapters] = useState([]);
  const [chaptersIds, setChaptersIds] = useState([]);

  useEffect(() => {
    if (state) {
      setSelectedUser(state);
      initializeTest(state.student.id);
    }
  }, []);



  const handleClose = () => {
    setOpen(false);
  };

  const TestSchema = Yup.object().shape({
    testSubject: Yup.string().required('Subject is required'),
    //   chaptersIds: Yup.string().required('Chapter is required'),
    format: Yup.string().required('Format is required'),
    level: Yup.string().required('Level is required')
  });

  const formik = useFormik({
    initialValues: {
      testSubject: "",
      //   chaptersIds: [],
      format: "",
      level: ""
    },
    validationSchema: TestSchema,
    onSubmit: (data) => {
      createTest(data);
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const initializeTest = (data) => {
    try {
      TestApi.initializeTest(data).then(
        (resp) => {
          if (resp.status === 200) {
            if (resp.data.status === 200) {
              setInitialize(resp.data.data);
            }
            else {
              alert(resp.data.message);
            }
          }
          else {
            alert(resp.message);
          }
        },
        (error) => { alert("Error in initializeTest"); }
      );
    }
    catch (error) {
      console.log("initializeTest Catch", error);
    }
  }

  const createTest = (data) => {

    try {
      // setOpen(true);
      const reqsBody = {
        "pFormatId": data.format.toString(),
        "idClassSubject": data.testSubject.toString(),
        "chapterIds": chaptersIds.toString(),
        "levelId": data.level.toString(),
        "idStudentSchool": selectedUser.id.toString(),
        "nonRepeatDuration": "-15"
      };
      console.log("reqsBody", reqsBody);

      TestApi.orderTest(reqsBody).then(
        (resp) => {
          if (resp.status === 200) {
            if (resp.data.status === 200) {
              downloadPDF(resp.data.data);
              setDownloadID(resp.data);
            }
            else {
              alert(resp.data.message);
            }
          }
          else {
            alert(resp.message);
          }
        },
        (error) => { alert("error in create test"); }
      );

      formik.resetForm();
    }
    catch (error) {
      console.log("create test Catch", error);
    }
  }
  const downloadPDF = (testId) => {
    try {
      if (testId) {
        TestApi.downloadTest(testId).then(
          (resp) => {
            if (resp.status === 200) {
              // Generate PDF
              utilApi.base64ToPDF(resp.data.data, "TestPaper.pdf");
              navigate('/dashboard/app', { replace: true });
            }
            else {
              alert(resp.message);
            }
          },
          (error) => { alert("Error in download pdf"); }
        );
      }
    }
    catch (error) {
      console.log("download pdf Catch", error);
    }
  }
  const handleChange = (event) => {
    if (initialize) {

      // eslint-disable-next-line array-callback-return
      initialize?.standardSubjects?.map((item) => {
        if (item.subject.id === event.target.value) {
          setChapterList(item.chapters);
          formik.setFieldValue("testSubject", event.target.value);
        }
      });
    }
  };
  const storeChapterIds = chaptersIds;
  const handleChangeChapter = (event, ids) => {
    const {
      target: { value },
    } = event;
    const name = event.target.innerText;
    // setChapters(
    //   // On autofill we get a the stringified value.
    //   typeof name === 'string' ? name.split(',') : name,
    // );
    setChaptersIds(
      // On autofill we get a the stringified value.

      typeof value === 'string' ? value.split(',') : value,
      // storeChapterIds.forEach(id => value.push(id)),

    );
  };


  return (
    <Page title="Plan Test | XPSOLV">

      <Grid container>
        <Grid item lg={12} className={classes.bgprofileinner}>
          <Typography variant="h4" gutterBottom className={classes.textcneter}>
            Apply <Box component="span" display="inline" className={classes.textcolor2}>Pattern</Box>
          </Typography>

        </Grid>




      </Grid>
      <Grid item lg={8} xs={12}  className={classes.boxborder}>
        <Box className={classes.borderalign}>
      <Typography variant="h4" gutterBottom className={classes.headingschool}>
          <img src="/static/mock-images/avatars/school.png" alt="imgtest" className={classes.imgplan3} /> Delhi Public School, Delhi
          </Typography>
          </Box>
        <Box  className={classes.paddingmain}>
        <Grid container>
          <Grid item lg={6} xs={12}  className={classes.borderright}>
        <Grid container className={classes.spacingslect}>
          <Grid item lg={12} xs={12}>

            <InputLabel id="demo-simple-select-filled-label" className={classes.label}>Class</InputLabel>
            <Select
              fullWidth
              className={classes.mtten}
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              label="Format"
              {...getFieldProps('format')}
              error={Boolean(touched.format && errors.format)}
              helperText={touched.format && errors.format}
            >
              <MenuItem value="">
                <em>Select Format</em>
              </MenuItem>
              {initialize?.testFormats?.map((option, index) => (
                <MenuItem key={index} value={option.id}>
                  {option.pformat}
                </MenuItem>
              ))}
            </Select>

          </Grid>
        </Grid>
        <Grid container className={classes.spacingslect}>
          <Grid item lg={12} xs={12}>

            <InputLabel id="demo-simple-select-filled-label" className={classes.label}>Subject</InputLabel>
            <Select
              fullWidth
              className={classes.mtten}
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              label="Format"
              {...getFieldProps('format')}
              error={Boolean(touched.format && errors.format)}
              helperText={touched.format && errors.format}
            >
              <MenuItem value="">
                <em>Select Format</em>
              </MenuItem>
              {initialize?.testFormats?.map((option, index) => (
                <MenuItem key={index} value={option.id}>
                  {option.pformat}
                </MenuItem>
              ))}
            </Select>

          </Grid>
        </Grid>
        <Grid container className={classes.spacingslect}>
          <Grid item lg={12} xs={12}>

            <InputLabel id="demo-simple-select-filled-label" className={classes.label}>Test Format</InputLabel>
            <Select
              fullWidth
              className={classes.mtten}
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              label="Format"
              {...getFieldProps('format')}
              error={Boolean(touched.format && errors.format)}
              helperText={touched.format && errors.format}
            >
              <MenuItem value="">
                <em>Select Format</em>
              </MenuItem>
              {initialize?.testFormats?.map((option, index) => (
                <MenuItem key={index} value={option.id}>
                  {option.pformat}
                </MenuItem>
              ))}
            </Select>

          </Grid>
        </Grid>
        <Grid container className={classes.spacingslect}>
          <Grid item lg={12} xs={12}>

            <InputLabel id="demo-simple-select-filled-label" className={classes.label}>Selection Pattern</InputLabel>
            <Select
              fullWidth
              className={classes.mtten}
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              label="Format"
              {...getFieldProps('format')}
              error={Boolean(touched.format && errors.format)}
              helperText={touched.format && errors.format}
            >
              <MenuItem value="">
                <em>Select Format</em>
              </MenuItem>
              {initialize?.testFormats?.map((option, index) => (
                <MenuItem key={index} value={option.id}>
                  {option.pformat}
                </MenuItem>
              ))}
            </Select>

          </Grid>
        </Grid>
        </Grid>
        <Grid item lg={6}>
          <Box className={classes.centerdiv}>
        <Typography variant="h6" gutterBottom className={classes.textcneter}>
            Select Pattern Defination is
          </Typography>
          <Typography variant="h6" gutterBottom className={classes.questionlist}>
          <img src="/static/mock-images/avatars/mark.png" alt="imgtest" className={classes.imgplan2} /> Fill in the Blanks  <Box component="span" display="inline" className={classes.textcolor2}>- 10%</Box>
          </Typography>
          <Typography variant="h6" gutterBottom className={classes.questionlist}>
          <img src="/static/mock-images/avatars/mark.png" alt="imgtest" className={classes.imgplan2} /> Very Short Questions <Box component="span" display="inline" className={classes.textcolor2}> - 15%</Box>
          </Typography>
          <Typography variant="h6" gutterBottom className={classes.questionlist}>
          <img src="/static/mock-images/avatars/mark.png" alt="imgtest" className={classes.imgplan2} />  Questions <Box component="span" display="inline" className={classes.textcolor2}> - 15%</Box>
          </Typography>
    </Box>
          </Grid>
       
        </Grid>
        </Box>
        <Box className={classes.submitbutn}>
          <Grid container>
            <Grid item lg={6} sm={6}>
            <Button variant="contained" className={classes.tenalignnew}>Apply</Button>
            </Grid>
            <Grid item lg={6} sm={6}>
            <Button variant="contained" className={classes.tenalignnew2}>Copy to Create New Pattern</Button>
              </Grid>
           </Grid>
          </Box>
      </Grid>



      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Assess Test</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Test generated successfully| Please proceed ...
          </DialogContentText>
          <div className={classes.root}>
            {/* <Button variant="contained" onClick={downloadPDF}>Download PDF</Button> */}
            <LoadingButton
              variant="contained"
              loading={isSubmitting}
              onClick={downloadPDF}
            >
              Download PDF
            </LoadingButton>
            <Button variant="contained" color="primary">
              Send Email
            </Button>
            <Button variant="contained" color="secondary">
              Start Online
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}
