import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Box, Stack, AppBar, Toolbar, IconButton,Tooltip } from '@material-ui/core';
// components
import { makeStyles } from '@material-ui/styles';
import { MHidden } from '../../components/@material-extend';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      maxWidth: 345,
    },
    emailphone: {
      color: '#646464',
      fontWeight: 500,
    },
    bordertell: {
      borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
      height: '70px !important',
      justifyContent:'center',
      alignItems:'right',
      display:'flex',  
        background: 'url(/static/mock-images/avatars/papper.png)',
        backgroundColor:'#fff',
    },
  
 
 


  }));
  const classes = useStyles();
  return (
    <RootStyle className={classes.bordertell}>
      <ToolbarStyle >
        <MHidden width="lgUp" >
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>


        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }} >
          {/* <LanguagePopover /> */}
          
          <AccountPopover />
          {/* <p className={classes.emailphone}>Arvind Sharma</p> */}
            <div className={classes.notification2}>
              <Box className={classes.notification}>
                <NotificationsPopover />
              </Box>
            </div>
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
