import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import AboutUs from './pages/AboutUs';
import AccountSummary from './pages/AccountSummary';
import AddHomeWork from './pages/AddHomework';
import AddNewQuestion from './pages/AddNewQuestion';
import ApplyPattern from './pages/ApplyPattern';
import Blog from './pages/Blog';
import BuildPattern from './pages/BuildPattern';
import Checkout from './pages/Checkout';
import ContactUs from './pages/ContactUs';
import CreateHomework from './pages/CreateHomework';
import DashboardApp from './pages/DashboardApp';
import Findpaper from './pages/Findpaper';
import GenerateTest from './pages/GenerateTest';
import HelpVideos from './pages/HelpVideos';
import HelpVideosForTeacher from './pages/HelpVideosForTeacher';
import HomeworkDetails from './pages/HomeworkDetails';
import HomeWorkParent from './pages/HomeWorkParent';
import HomeWorkTeacher from './pages/HomeWorkTeacher';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import PerformanceReport from './pages/PerformanceReport';
import PlanTest from './pages/PlanTest';
import Pricing from './pages/Pricing';
import PriPolicy from './pages/PrivacyPolicy';
import QuestionDetail from './pages/QuestionDetail';
import Refund from './pages/Refund';
import Register from './pages/Register';
import ReviewAnswer from './pages/ReviewAnswer';
import SchoolPattern from './pages/SchoolPattern';
import ShippingAndDelivery from './pages/ShippingAndDelivery';
import Support from './pages/Support';
import TermsAndConditions from './pages/TermsAndConditions';
import TermsOfServices from './pages/TermsOfServices';
import TestHistory from './pages/TestHistory';
import TestReceived from './pages/TestReceived';
import User from './pages/User';
import ViewQuestionTest from './pages/ViewQuestionTest';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Navigate to="/login" replace /> },
        { path: 'app', element: <DashboardApp /> },
        /* { path: 'buildPattern', element: <BuildPattern /> }, */
        { path: 'user', element: <User /> },
        { path: 'plan-test', element: <PlanTest /> },
        { path: 'build-pattern', element: <BuildPattern /> },
        { path: 'review-answer', element: <ReviewAnswer /> },
        { path: 'view-question', element: <ViewQuestionTest /> },
        { path: 'school-pattern', element: <SchoolPattern /> },
        { path: 'question-detail', element: <QuestionDetail /> },
        { path: 'apply-pattern', element: <ApplyPattern /> },
        { path: 'terms-of-services', element: <TermsOfServices /> },
        /* { path: 'privacy-policy', element: <PrivacyPolicy /> }, */
        { path: 'add-new-question', element: <AddNewQuestion /> },
        { path: 'test-history', element: <TestHistory /> },
        { path: 'homework-parent', element: <HomeWorkParent /> },
        { path: 'homework-teacher', element: <HomeWorkTeacher /> },
        { path: 'testReceived', element: <TestReceived /> },
        { path: 'reviewAnswers', element: <Findpaper /> },
        { path: 'performancereport', element: <PerformanceReport /> },
        { path: 'addHomework', element: <AddHomeWork /> },
        { path: 'createHomework', element: <CreateHomework /> },
        { path: 'generateTest', element: <GenerateTest /> },
        { path: 'blog', element: <Blog /> },
        { path: 'checkout', element: <Checkout/> },
        { path: 'account-summary', element: <AccountSummary /> },
        { path: 'hWDetails', element: <HomeworkDetails /> },    
        { path: 'support', element: <Support /> },   
        { path: 'helpvideos', element: <HelpVideos /> },   
        { path: 'helpvideosforteacher', element: <HelpVideosForTeacher /> },        
         /* { path: 'test-formik', element: <FormikTestForm /> } */
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'privacypolicy', element: <PriPolicy /> },
        { path: 'termsandconditions', element: <TermsAndConditions /> },
        { path: 'contactus', element: <ContactUs /> },
        { path: 'shippinganddelivery', element: <ShippingAndDelivery /> },
        /* { path: 'helpvideos', element: <HelpVideos /> },  */
        /* { path: 'support', element: <Support /> }, */
        { path: 'pricing', element: <Pricing /> },
        { path: 'aboutus', element: <AboutUs /> },
        { path: 'refund', element: <Refund /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/login" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
