// import { useForm } from "react-hook-form";
// import { yupResolver } from '@hookform/resolvers';
// material
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography, Alert, AlertTitle } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import Loader from '../components/Loader';
// components
import Page from '../components/Page';
import { historyApi } from '../service/index';
import Iframe from './Iframe'

toast.configure();
const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(1),
		},
	},
  widthbottom:{
    width: "90%", 
    margin: "auto", 
    background: "#fff", 
    border: "1px solid #d8d8d8", 
    padding: "25px", 
    borderRadius: "11px", 
	[theme.breakpoints.up('sm')]: {
		width: "55%", 
	   },
  },
  buttonreview:{
    padding: "17px 32px", 
  width: "100%",
  [theme.breakpoints.up('sm')]: {
    padding: "17px 0px", 
   },
  },
  headingquestion:{
  color:'#fff',padding:'22px 32px 83px',margin:'0',fontSize: 22,
  [theme.breakpoints.up('sm')]: {
    fontSize: 42,
    padding:'72px 32px 143px',
  }, 
},
mbottom:{
  margin: "8px auto",
 [theme.breakpoints.up('sm')]: {
  marginBottom:0,
 },
},
centertext:{
	textAlign:'center',
	marginBottom:12,
	fontSize:16,
},
imagepaper:{
	width: "282px", 
	margin: "auto",
	marginBottom: "21px", 
	borderRadius: "162px"
},
btntext:{
	background: "#007c7c", 
	color: "#fff", 
	padding: "28px 26px",
	width:'100%',
	[theme.breakpoints.up('sm')]: {
		width:'auto',
	   },
	   "&:hover": {
		background: '#007c7c !important',
	  },
},
alertmsg:{
	fontSize: "11px", 
	marginTop: "5px", 
	color: "#f00"
},
colorreport:{
	color:'#007c7c',
	fontSize:16,
},
heightwidth:{
    height:'2000vh',
    margin:0,
    padding:0,
    overflow:'scroll',
	borderTop:'1px solid #d8d8d8',
	marginTop: 30,
	[theme.breakpoints.up('sm')]: {
		height:'2000vh',
	  },
  },
  borderchangetext:{
	  fontSize:'14px !important',
  },
  padding10:{
	paddingTop: '10px'
  }
}));

export default function PerformanceReport() {
	/* const theme = useTheme();
	const navigate = useNavigate();
	const { state } = useLocation(); */
	const classes = useStyles();
	const [filterInfo, setFilterInfo] = useState([]);
	const [subjectInfo, setSubjectInfo] = useState([]);
	/* const [selectedStudent, setSelectedStudent] = useState(); */
	// const [selectedHtmlPage, setHtmlPage] = useState('');
	const [showLoder, setShowLoader] = useState(false);
	const [html, setHtml] = useState('');
	const [userDetails, setUserDetails] = useState([]);
	const [errorMessage, setErrorMessage] = useState('');
	
	useEffect(() => {
		const userInfo = JSON.parse(localStorage.getItem("userInfo"));
		setUserDetails(userInfo[0]?.StudentInfo);
	}, []);

	const handleChange = (event) => 
	{
		if(event.target.name === 'idStudentSchool')
		{
			const idStudentSchool = event.target.value;
			formik.setFieldValue("idClassSubject", '');
			formik.setFieldValue(event.target.name, event.target.value);
			if(idStudentSchool === ''){
				setSubjectInfo([]);
				return false;
			}
			setSubjectInfo([]);
			getStudentSubject(idStudentSchool);
			return false;
		}
		
		setErrorMessage('');
		setSubjectInfo([]);
		formik.setFieldValue("idClassSubject", '');
		formik.setFieldValue(event.target.name, event.target.value);
		if (filterInfo.length > 0 && event.target.value !== '') 
		{
			filterInfo.forEach((item) => {
				if (parseInt(item.idStudentSchool,10) === event.target.value) 
				{
					let newsubjectInfo = [...subjectInfo];
					newsubjectInfo = item.SubjectInfo;
					setSubjectInfo(newsubjectInfo);
					formik.setFieldValue("idClassSubject", '');
				}
			});
		}
	}
	const getStudentSubject = (idStudentSchool) => 
	{
		try 
		{
			const reqsBody = {
				"idStudentSchool": idStudentSchool,
			};
			historyApi.getStudentSubject(reqsBody).then(
				(resp) => 
				{
					if (resp.status === 200) 
					{
						if (resp.data.status === 200) 
						{
							if(resp.data.data !== "NA")
							{
							setFilterInfo(resp.data.data);
							setSubjectInfo(resp.data.data[0].SubjectInfo);
							}
						}
						else 
						{
							setErrorMessage(resp.data.message);
						}
					}
					else {
						setErrorMessage(resp.message);
					}
				},
				(error) => { setErrorMessage("Error in getStudentSubject"); }
			);
		}
		catch (error) {
			console.log("getStudentSubject Catch", error);
		}
	}

	const generateReport = (data) => 
	{
		try 
		{
			setShowLoader(true);
			historyApi.generateReport(data).then(
			(resp) => 
			{
			  if(resp.status === 200) 
			  {
				setShowLoader(false);
				setHtml(resp.data.data);
			  }
			  else 
			  {
				setErrorMessage(resp.message);
				setShowLoader(false);
			  }
			},
			(error) => { setShowLoader(false); setErrorMessage("Error in generateReport"); }
		  );
		  formik.setSubmitting(false);
		}
		catch (error) {
			console.log("generateReport Catch", error);
		}
	}

	const TestSchema = Yup.object().shape({
		idStudentSchool: Yup.string().required('Student is required'),
		idClassSubject: Yup.string().required('Subject is required'),
	});

	const formik = useFormik({
		initialValues: {
			idStudentSchool: "",
			idClassSubject: ""
		},
		validationSchema: TestSchema,
		onSubmit: (data) => {
			console.log(data);
			generateReport(data);

		},
	});
	const { errors, touched,  isSubmitting, handleSubmit, getFieldProps } = formik;

	return (

<Page title="Performance Report | XPSOLV">
<Typography variant="h4" component="h4" className={classes.centertext} >
  Performance <Box component="span" className={classes.colorreport} sx={{ display: 'inline' }}>Report</Box>

</Typography>
<div className={classes.widthbottom}>

<FormikProvider value={formik}>
<Form autoComplete="off" noValidate onSubmit={handleSubmit}>
 <Grid item container style={{backgroundImage: "#999"}} >
 

    <Grid item lg={4} md={4} sm={12}  xs={12} className={classes.mbottom}>
	<FormControl fullWidth>
  <InputLabel id="demo-simple-select-label" className={classes.borderchangetext}>Student</InputLabel>
  <Select
	fullWidth
	className={classes.borderchangetext}
	labelId="demo-simple-select-filled-label"
	id="demo-simple-select-filled"
	label="Student"
	
	{...getFieldProps('idStudentSchool')}
	onChange={handleChange}
	error={Boolean(touched.idStudentSchool && errors.idStudentSchool)}
	helpertext={touched.idStudentSchool && errors.idStudentSchool}
>
	<MenuItem value="">
		<em className={classes.borderchangetext}>Select Student</em>
	</MenuItem>
	
	{userDetails.map((option, index) => (
		<MenuItem key={index} value={option.idStudentSchool} className={classes.borderchangetext}>
			{option.FirstName} {option.LastName}
		</MenuItem>
	))}
</Select>
</FormControl>
{touched.idStudentSchool && errors.idStudentSchool &&
<p className={classes.alertmsg}>{errors.idStudentSchool}</p> }

    </Grid>
    <Grid item lg={4} md={4} sm={12} xs={12} className={classes.mbottom}>
    <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label" className={classes.borderchangetext}>Subject</InputLabel>
  <Select
	fullWidth
	className={classes.borderchangetext}
	labelId="demo-simple-select-filled-label"
	id="demo-simple-select-filled"
	label="Subject"
	
	{...getFieldProps('idClassSubject')}
	error={Boolean(touched.idClassSubject && errors.idClassSubject)}
	helpertext={touched.idClassSubject && errors.idClassSubject}
>
	<MenuItem value="">
		<em className={classes.borderchangetext}>Select Subject</em>
	</MenuItem>
	
	{subjectInfo.map((option, index) => (
		<MenuItem key={index} value={option.idClassSubject} className={classes.borderchangetext}>
			{option.SubjectName}
		</MenuItem>
	))}
</Select>
</FormControl>
{touched.idClassSubject && errors.idClassSubject &&
<p className={classes.alertmsg}>{errors.idClassSubject}</p> }
    </Grid>
    <Grid item lg={2} md={2} sm={12} xs={12} className={classes.mbottom}>
    <LoadingButton
		spacing={2}
		size="large"
		type="submit"
		loading={isSubmitting}
		className={classes.btntext}
	>
		View
	</LoadingButton>
  </Grid>
  <Grid item lg={12} md={2} sm={12} xs={12} className={classes.mbottom}>
		{errorMessage && <Alert severity="error" className={classes.alertalignment}>
			<AlertTitle>Error</AlertTitle>
			{errorMessage}
		</Alert> }
 </Grid>
</Grid>
</Form>
</FormikProvider>
</div>
<Grid item container className={classes.padding10}>
      {html && 
        /* <iframe  className={classes.heightwidth} src={`/qp/${selectedHtmlPage}`} frameBorder="0"  scrolling="no"  width="100%" height="2000px"  title="description" /> */
		<Iframe content={html} id="printf" name="printf" className={classes.heightwidth}/> 
	  }
      </Grid>
	  <Loader isLoader={showLoder} showHide={setShowLoader} />
  </Page>
		);
	
}

