import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';


// material
import { Stack, TextField, FormLabel, Button, Grid, Alert } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import GlobalStyles from '../../theme/globalStyles';
// ----------------------------------------------------------------------
import { TestApi, userApi } from '../../service/index';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  paper: {
    padding: theme.spacing(2)
  },
  specing :{
    marginTop:'12px !important',
  },
  specingtwo :{
     marginBottom:12,
  },
  textField :{
    width:'100%',
  },
  label:{
    fontSize:13,
    marginBottom:12,
  },
  colrobg: {
    background:'#007c7c',
    color:'#fff',
    "& label.Mui-focused": {
      color: "#007c7c"
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#007c7c"
      }
    }
  },
 
}));

export default function UpdateProfileForm({ allUserInfo, userInfo, onChildClick }) {
  const navigate = useNavigate();
  const [value, setValue] = useState('F');
  const [allClasses, setAllClasses] = useState([]);
  const [allSchools, setAllSchools] = useState([]);
  const classes = useStyles();
  const [isApprove, setApproveValue] = useState(true);
  const [isConfirm, setConfirmValue] = useState(false);
  



  useEffect(() => {
    getClasses();
    getSchools();
  }, []);

  const RegisterSchema = Yup.object().shape({
    gender: Yup.string().required('Gender is required'),
    dob: Yup.string().required('DOB is required'),
    schoolId: Yup.string().required('School is required'),
    classId: Yup.string().required('Class is required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    mobile: Yup.string().required("Mobile number is Required").min(10, 'Mobile number is not valid').max(10,'Mobile number is not valid')
  });

  const formik = useFormik({
    initialValues: {
      gender: (userInfo?.student?.gender === null ? '' : userInfo?.student?.gender),
      dob: (userInfo?.student?.dob ? userInfo?.student?.dob : ''),
      schoolId: (userInfo?.school?.id ? userInfo.school?.id : ''),
      email: (userInfo?.student?.email ? userInfo.student?.email : ''),
      mobile: (userInfo?.student?.mobile ? userInfo.student?.mobile : ''),
      classId: (userInfo?.standard?.classNumber ? userInfo?.standard?.classNumber : ''),
      rollNumber: (userInfo?.rollNumber ? userInfo?.rollNumber : '')
    },
    validationSchema: RegisterSchema,
    onSubmit: (data) => {
      updateProfile(data);
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const setUserInfo = (userResp) => {

    userInfo.school = userResp?.school;
    userInfo.standard = userResp?.standard;
    userInfo.id = userResp?.id;
    userInfo.student.gender = userResp?.student?.gender;
    userInfo.student.dob = userResp?.student?.dob;
    userInfo.rollNumber = userResp?.rollNumber;
    userInfo.student.email = userResp?.student?.email;
    userInfo.student.mobile = userResp?.student?.mobile;

    if (allUserInfo) {
      // eslint-disable-next-line array-callback-return
      allUserInfo.map((option) => {
        if (option.student.id === userInfo?.student?.id) {
          option = userInfo;
        }
      });
      localStorage.setItem("userInfo", JSON.stringify(allUserInfo));
      onChildClick();
    }
  };
  const updateProfile = (data) => {
    console.log(data);
    try {
   
      const studentID = userInfo.student.id;
      if(isApprove && ((userInfo?.school?.id && data?.schoolId !== userInfo?.school?.id) || (userInfo?.standard?.id && data?.classId !== userInfo?.standard?.id)))
      {
        setApproveValue(false);
        setConfirmValue(true);
        formik.setSubmitting(false);
        return false;
      }
     
      if (studentID && (isApprove || isConfirm )) {
        userApi.updateUser(studentID, data).then(
          (resp) => {
            if (resp.status === 200) {

              if (resp.data.status === 201) {
                setUserInfo(resp.data.data);
                console.log("Update Contact", resp);
              }
              else {
                alert(resp.data.message);
                onChildClick();
              }

            }
          },
          (error) => { alert("Error in Update Contact"); }
        );
      }
      else{
        formik.setSubmitting(false);
      }

    }
    catch (error) {
      console.log("Update Contact Catch", error);
    }
  }
  const getSchools = () => {
    try {
      TestApi.getSchools().then(
        (resp) => {
          if (resp.status === 200) {
            setAllSchools(resp.data);
          }
        },
        (error) => { alert("Error in Schools"); }
      );
    }
    catch (error) {
      console.log("Schools Catch", error);
    }
  }
 
  const getClasses = () => {
    try {
      TestApi.getClasses().then(
        (resp) => {
          if (resp.status === 200) {
            setAllClasses(resp.data);
          }
        },
        (error) => { alert("Error in Classes"); }
      );
    }
    catch (error) {
      console.log("Classes Catch", error);
    }
  }
  return (
    <FormikProvider value={formik}>
      <GlobalStyles />
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {isApprove  &&
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} className={classes.specing}>
        <Grid item sm={12}>
        <FormLabel component="legend" className={classes.label}>Email</FormLabel>
        <TextField
              fullWidth
              
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />     
        </Grid>
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} className={classes.specing}>
        <Grid item sm={12}>
        <FormLabel component="legend" className={classes.label}>Mobile Number</FormLabel>
        <TextField
              fullWidth
              
              {...getFieldProps('mobile')}
              type="number"
              error={Boolean(touched.mobile && errors.mobile)}
              helperText={touched.mobile && errors.mobile}
            
             
            />
        </Grid>
        </Stack>
       
        {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} className={classes.specing}>
        <Grid item sm={12}>
        <FormLabel component="legend" className={classes.label}>School</FormLabel>
            <Select 
              fullWidth
              {...getFieldProps('schoolId')}
              className={classes.fontchange}
              labelId="select-school"
              id="select-school"
              label="School"
              error={Boolean(touched.schoolId && errors.schoolId)}
              helperText={touched.schoolId && errors.schoolId}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {allSchools.map((option, index) => (
                <MenuItem key={index} value={option.id}>
                  {option.schoolName}
                </MenuItem>
              ))}
            </Select>
            {touched.schoolId && errors.schoolId &&
            <FormHelperText className="Mui-error">{touched.schoolId && errors.schoolId}</FormHelperText>
            }
            </Grid>
          </Stack> */}
{/*           
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} className={classes.specing}>
          <Grid item sm={6}>
          <FormLabel component="legend" className={classes.label}>Class</FormLabel>
            <Select
              fullWidth
              {...getFieldProps('classId')}
              className={classes.fontchange}
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              label="Class"
              error={Boolean(touched.classId && errors.classId)}
              helperText={touched.classId && errors.classId}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {allClasses.map((option, index) => (
                <MenuItem key={index} value={option.id}>
                  {option.className}
                </MenuItem>
              ))}
            </Select>
            {touched.classId && errors.classId &&
            <FormHelperText className="Mui-error">{touched.classId && errors.classId}</FormHelperText>
            }
           </Grid>
           <Grid item sm={6}>
           <FormLabel component="legend" className={classes.label}>Roll Number</FormLabel>
            <TextField
              fullWidth
              
              {...getFieldProps('rollNumber')}
            />
            </Grid>
          </Stack> */}
          
          {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} className={classes.specing}>
          <Grid item sm={6}>
          <FormLabel component="legend" className={classes.label}>DOB</FormLabel>
            <TextField
              id="date"
         
              type="date"
              defaultValue="2017-05-24"
              {...getFieldProps('dob')}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              error={Boolean(touched.dob && errors.dob)}
              helperText={touched.dob && errors.dob}
            />
            </Grid>
            <Grid item sm={6}>
            <FormLabel component="legend" className={classes.label}>Gender</FormLabel>
            <RadioGroup aria-label="gender" name="gender" onChange={handleChange} {...getFieldProps('gender')} error={Boolean(touched.gender && errors.gender)}>
            <Grid container spacing={3} >
            <Grid item sm={6}>
              <FormControlLabel value="F" control={<Radio />} label="Female"  />
              </Grid>
              <Grid item sm={6}>
              <FormControlLabel value="M" control={<Radio />} label="Male" />
              </Grid>
              </Grid>
              {touched.gender && errors.gender &&
            <FormHelperText className="Mui-error">{touched.gender && errors.gender}</FormHelperText>
            }
            </RadioGroup>
            </Grid>
          </Stack> */}
        

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            className={classes.colrobg}
            loading={isSubmitting}
          >
            Update Contact 
          </LoadingButton>
        </Stack>
      }
      {!isApprove &&
       <><div className="row">
            <Alert severity="warning">Are you sure?</Alert>
          </div><div>
              <Stack spacing={2} direction="row">

                <LoadingButton
                  fullWidth
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Yes
                </LoadingButton>
                <Button fullWidth variant="contained" color="error" size="medium" type="button" onClick={() => {
                  setApproveValue(true);
                  setConfirmValue(false);
                } }>No</Button>

              </Stack>
            </div></>
      }
      </Form>
    </FormikProvider>
  );
}
