import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';
import base64 from 'react-native-base64';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Webcam from "react-webcam";
// material
import { Box, Button, Grid, TextareaAutosize, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import '../components/css/style.css';

import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// component
import * as Yup from 'yup';
import Loader from '../components/Loader';
import Page from '../components/Page';
import { homeWorkApi } from '../service/index';


toast.configure();

const useStyles = makeStyles((theme) => ({
    heightwidth: {
        height: '1000vh',
        margin: 0,
        padding: 0,
        overflow: 'scroll',
    },
    btnsend: {
        display: 'initial',
        justifyContent: 'none',
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'end',
            display: 'flex',

        },
    },
    btnprint: {
        height: "56px",
        marginTop: "7px",
        marginRight: "10px",
        padding: "0 14px",
        float: 'left',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
            height: '56px',

        },
    },
    btnprint2: {
        marginRight: 10,
        height: '56px',
        marginTop: '7px',
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    aligndiv: {
        display: 'block',
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            display: 'inline-flex',
        },
    },
    widthdropdown: {
        width: '163px',
        float: 'left',
    },
    bordermain: {
        border: "1px solid #ddd",
        borderRadius: "11px",
        padding: "25px 17px",
        width: "91%",
        margin: "auto",
        [theme.breakpoints.up('sm')]: {
            width: "563px",
            padding: "25px",


        },
    },
    displaymain: {
        margin: "auto",
        display: "flex",
        justifyContent: "center",
        border: "2px dashed #d3d3d3",
        padding: "20px",
        width: "123px",
        marginTop: "14px",
        borderRadius: "12px"
    },
    imgplan: {
        width: "25px",
        margin: "18px",
    },
    headingpart: {
        color: '#089293',
    },
    headingpart2: {
        textAlign: 'center',
    },
    imgplan2: {
        margin: '20px 0',
        borderRadius: 15,
    },
    maincardborder: {
        borderTop: '1px solid #e9e9e9 !important',
        borderBottom: '1px solid #e9e9e9 !important',
        margin: "20px 0",
    },
    peraone: {
        textAlign: "center",
        marginTop: "7px",
        fontSize: "13px",
        color: "red",
    },
    colorred: {
        color: 'red',
        fontSize: 13,
    },
    textcenter: {
        textAlign: 'center',
        margin: "auto",
        display: "block",
        marginBottom: "7px"
    },
    headingpart4: {
        /* fontSize: 14, */
        fontWeight: "500",
        color: "#089293",
        fontSize: "14px"
    },
    removebg: {
        background: 'none',
        boxShadow: 'none',
        '&:hover': {
            background: 'none',
        }
    },
    imgplan00: {
        width: '15px',
        marginTop: '13px',
        float: 'right',
    },
    imgecross: {
        width: 10,
    },
    imagepo: {
        position: 'relative',
        border: "1px solid #ddd",
        margin: "27px 13px",
        padding: "13px",
        borderRadius: "29px"
    }
}));


export default function CreateHomework() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const classes = useStyles();
    const [showLoder, setShowLoader] = useState(false);
    const [image, setImage] = useState([]);
    const [stop, setStop] = useState(false);
    const [homeworkDetails, setHomeworkDetails] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [isTeacher, setIsTeacher] = useState(false);
    const [submissionDate, setSubmissionDate] = React.useState(dayjs());
    const defaultAdditionalNote = 'Please enter your text here...';
    const [blobImage, setBlobImage] = useState([]);

    useEffect(() => {
        if (state) {
            setHomeworkDetails(state);
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            setSelectedUser(userInfo[0]?.StudentInfo[0]);
            if (userInfo[0]?.StudentInfo && userInfo[0].StudentInfo.length === 1 && userInfo[0].StudentInfo[0]?.role === 'Teacher') {
                setIsTeacher(true);
            }
        }
    }, []);

    const validationRules = {
        additionalNotes: Yup.string().required('Additional Notes is required')
    }
    const TestSchema = Yup.object().shape(validationRules);

    const formik = useFormik({
        initialValues: {
            additionalNotes: "",
        },
        validationSchema: TestSchema,
        onSubmit: (data) => {
            PublishHomework(data);
        },
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    const videoConstraints = {
        width: 220,
        height: 200,
        facingMode: "user"
    };

    const webcamRef = React.useRef(null);

    const openCamera = () => {
        setStop(true);
    };

    const removeImage = (event, index) => {
        const imageArr = [];
        image?.forEach(img => { imageArr.push(img); });
        delete imageArr[index];
        setImage(imageArr);
    };

    const addMore = () => {
        setStop(true);
    }

    const handleChangeText = (event) => {
        formik.setFieldValue("additionalNotes", event.target.value);
    }

    const handleChangeUploadImage = (event) => {
        console.log(event.target.files);
        setSelectedImage(event.target.files[0]);
        const Images = [];
        image?.forEach(img => { Images.push(img); });
        Array.from(event.target.files)?.forEach(file => { Images.push(file); });
        setImage(Images);
    }

    const [selectedImage, setSelectedImage] = useState();

    const capture = React.useCallback(
        async () => {
            const imageSrc = webcamRef.current.getScreenshot();
            const Images = [];
            const blobImages = [];
            if (image.length > 0) {
                image?.forEach((img) => {
                    Images.push(img);
                });

            }
            if (blobImage.length > 0) {
                blobImage?.forEach((blbimg) => {
                    blobImages.push(blbimg);
                });
            }
            const blob = await fetch(imageSrc).then((res) => res.blob());
            Images.push(imageSrc);
            blobImages.push(blob);
            setBlobImage(blobImages);
            setImage(Images);
            setStop(false);
        });


    const calenderChange = (newValue) => {
        setSubmissionDate(newValue);
    };

    const PublishHomework = (data) => {
        setShowLoader(true);
        try {
            const encNote = data.additionalNotes;
            const encodedNote = base64.encode(encNote);
            const reqsBody = {
                "idClassSubject": homeworkDetails.idClassSubject,
                "chapterids": homeworkDetails.chapterids,
                "idStudentSchool": homeworkDetails.idStudentSchool,
                "notes64": encodedNote,
                "submissionDate": submissionDate.format("YYYY-MM-DD"),
                "SectionList": homeworkDetails.SectionList
            };
            const formData = new FormData();
            formData.append("data", JSON.stringify(reqsBody));
            image?.forEach((img, index) => {
                const num = index + 1;
                const fileName = `file${num}`;
                formData.append(fileName, img);
            });
            homeWorkApi.createHomework(formData).then(
                (resp) => {
                    if (resp.status === 200) {
                        navigate('/dashboard/homework-teacher');
                    }
                    else {
                        navigate('/dashboard/homework-teacher');
                    }
                    setShowLoader(false);
                },
                (error) => { alert("Error in homework"); }
            );
            formik.setSubmitting(false);
            setShowLoader(false);

        }
        catch (error) {
            console.log("create homework Catch", error);
        }
    }

    const formData = new FormData();
    formData.append('image', image);

    return (
        <Page title="Create Homework | XPSOLV">
            <Typography variant="h4" gutterBottom className={classes.headingpart2}>
                Create <Box component="span" display="inline" className={classes.headingpart}>Homework</Box>
            </Typography>
            <div className={classes.bordermain}>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Grid container>
                            <Grid item className={classes.cardborder} lg={12} md={12} sm={12} xs={12}>
                                <Typography variant="h6" gutterBottom className={classes.textcenter}>
                                    Upload a photo from Gallery on mobile OR drive on computer 
                                </Typography>
                                <Typography variant="p" gutterBottom className={classes.textcenter}>
                                    (* Only JPG, PNG, GIFF are allowed)

                                </Typography>
                                <div className={classes.displaymain}>

                                    {/* <Button
                                        variant="contained"
                                        component="label"
                                        className={classes.removebg}
                                        // onClick={openCamera}
                                        >
                                        <img src="/static/mock-images/avatars/camera.png" alt="imgtest" className={classes.imgplan} />
                                        <input
                                            type="file"
                                            multiple
                                            name="myImage"
                                            accept="image/*" capture
                                            onChange={(event) => handleChangeUploadImage(event)}
                                        />
                                    </Button> */}
                                    <Button
                                        variant="contained"
                                        component="label"
                                        className={classes.removebg}
                                    >
                                        <img src="/static/mock-images/avatars/cloud-upload-alt.png" alt="imgtest" className={classes.imgplan} />
                                        <input
                                            type="file"
                                            accept="image/*"
                                            //    capture="filesystem"
                                            capture="none"
                                            multiple
                                            name="myImage"
                                            onChange={(event) => handleChangeUploadImage(event)}
                                        />
                                    </Button>
                                    {/* {selectedImage && 
                                        <div>
                                        <img alt="not fount" width="250px" src={URL.createObjectURL(selectedImage)} />
                                        <br />
                                        {<button onClick={()=>setSelectedImage(null)}>Remove</button> }
                                        </div>
                                    } */}

                                </div>

                                {stop &&
                                    <div className="webcam-container">
                                        <Webcam
                                            audio={false}
                                            height={200}
                                            ref={webcamRef}
                                            mirrored
                                            screenshotFormat="image/jpeg"
                                            width={220}
                                            videoConstraints={videoConstraints}
                                        />
                                        <button
                                            onClick={(e) => { e.preventDefault(); capture(); }}>
                                            Capture </button>
                                    </div>}
                                {/* {image && <button onClick={addMore}>Add More </button>  } */}

                            </Grid>
                            <Grid item className={classes.maincardborder} lg={12} md={12} sm={12} xs={12}>
                                <Grid container>
                                    {image?.map((img, index) => (
                                        <Grid key={`${index}_Grid`} item lg={6} md={6} sm={6} xs={6} >
                                            <div className={classes.imagepo}>
                                                <Button onClick={(event) => removeImage(event, index)} className={classes.imgplan00}><img src="/static/mock-images/avatars/crosspin.png" alt="imgtest" className={classes.imgecross} /></Button>

                                                <img src={URL.createObjectURL(img)} alt="imgtest" className={classes.imgplan2} />
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid item className={classes.cardborder} lg={12} md={12} sm={12} xs={12}>
                                <Typography variant="label" gutterBottom className={classes.headingpart4}>
                                    Additional Notes
                                </Typography>

                                <TextareaAutosize
                                    aria-label="minimum height"
                                    minRows={3}
                                    /* defaultValue={defaultAdditionalNote} */
                                    placeholder='Describe the homework with additional notes.... '
                                    onChange={handleChangeText}                                    
                                    {...getFieldProps('additionalNotes')}
                                    style={{ width: '100%', padding: 10, borderRadius: 6, border: "1px solid #d9d9d9" }}
                                    error={errors.additionalNotes}
                                    helpertext={errors.additionalNotes}
                                />
                                {touched.additionalNotes && errors.additionalNotes && <p className={classes.colorred}>{errors.additionalNotes}</p>}
                            </Grid>
                            <Grid item className={classes.cardborder} lg={4} md={12} sm={12} xs={12}>
                                <Typography variant="label" gutterBottom className={classes.headingpart4}>
                                    Submission Date

                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        inputFormat="MM/DD/YYYY"
                                        value={submissionDate}
                                        onChange={calenderChange}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <Button variant="contained" style={{
                            width: "100%",
                            padding: "14px",
                            marginTop: "25px"
                        }}
                            loading={isSubmitting.toString()}
                            type="submit"
                        >Publish</Button>
                    </Form>
                </FormikProvider>
            </div>
            <Loader isLoader={showLoder} showHide={setShowLoader} />
        </Page>
    );
}
