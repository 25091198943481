import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

export default function PrivacyPolicy() {

    return (
        <div style={{marginTop:120,padding:' 0 50px',}}>
            <Link to="/login" component={RouterLink} style={{display: "flex", width: "75px", padding: "9px 7px 0 6px", lineHeight: "24px", background: "#089293", border: "none", color: "#fff",borderRadius: 6,marginBottom: "14px"}}> <img src="/static/mock-images/avatars/angle-left-free-icon-font.png" alt="imgtest"  style={{width:15,marginTop: "3px", marginRight: "6px",marginBottom: "11px"}}/>back</Link>
            <h2>Privacy Policy</h2>
            
            <div >
                <h3 style={{padding:' 15px 0',fontSize: 16,}}>User Policy</h3>
                <p style={{fontSize:14,}}>Xpsolv is an online Practice Test platform operated & governed by SELTCORP. By using Xpsolv, you accept and agree to be bound by the following terms & conditions. Nothing contained in these Terms of Service (ToS) should be assumed to grant any rights to a third party beneficiary.</p>
            </div>

            <div>
                <h3 style={{padding:' 15px 0',fontSize: 16,}}>Rights To Modify/ Upgrade/ Revision</h3>
                <p style={{fontSize:14,}}>
                    SELTCORP reserves its right to modify/ upgrade or terminate the Xpsolv service for any reason, without prior notice, without liability to user or any other members or third party. SELTCORP also reserves the right to revise the Terms of Service from time to time without prior notice. The user agrees to regularly review the Terms of Service to keep himself updated/ informed of any such changes.
                </p>
            </div>

            <div>
                <h3 style={{padding:' 15px 0',fontSize: 16,}}>Eligibility And Access To Service</h3>
                <p style={{fontSize:14,}}>
                    The decision to grant access to the services of Xpsolv is the prerogative of it’s parent entity (SELTCORP) and its decision in this regard shall be final and binding on the existing and potential user(s). The user consents that Xpsolv reserves its right to take all necessary measures to deny access to its services or termination of services, if the terms and conditions of the present agreement are not complied with or there is any violation of copyright, trademark or other valuable proprietary right as described in this agreement. In order to use the service, user must have access to internet, mobile devices and all necessary equipment like computer, modem, ISP connectivity or necessary plan subscription for ISPs. This does not form a part of the service.
                </p>
            </div>

            <div>
                <h3 style={{padding:' 15px 0',fontSize: 16,}}>Terms Of Service</h3>
                <p style={{fontSize:14,marginBottom:10}}>
                    <ul>
                        <li style={{fontSize:14,marginBottom:10,marginLeft:25}}>The contents used on Xpsolv is developed on the concepts covered in the structured curriculum syllabus prescribed for students of various standards/ classes. The usage of the content made available through XpSolv is not endorsed as a substitution to the curriculum-based education provided by the educational institutions but is intended to supplement the same by providing a platform to test the knowledge to gain excellence. </li>
                        <li style={{fontSize:14,marginBottom:10,marginLeft:25}}>The Company's products and / or Services, including the Application and content, are compatible only with certain devices/tablets/hardware. The Company shall not be obligated to provide workable products and / or services for any instruments that are not recognized by the Company or those instruments that may be purchased from any third party which are not compatible with the Company's products and Services. The company reserves the right to upgrade the tablet/ type of compatible devices as required from time to time.</li>
                        <li style={{fontSize:14,marginBottom:10,marginLeft:25}}>The use of the website or mobile app (Xpsolv), or any of its services or features and the information and material contained therein implies and constitutes the user's consent to this agreement, the user unequivocally agrees to abide by these terms and conditions. </li>
                        <li style={{fontSize:14,marginBottom:10,marginLeft:25}}>The information, material, and content have been made available on Xpsolv by exercising due diligence and is correct & true to the best of the knowledge and intent of the site. However, the user agrees to independently verify the authenticity and veracity of the answers, information, material, and content posted on the site. </li>
                        <li style={{fontSize:14,marginBottom:10,marginLeft:25}}>The user agrees that the time limits, if any, stipulated by the site for providing the answers to the queries of the user are only indicative and not definitive. The user understands that the queries of the user may not be answered in a time bound manner due to a high volume of queries at a given point of time.</li>
                        <li style={{fontSize:14,marginBottom:10,marginLeft:25}}>The user understands that questions, answers, and practice test papers posted on the site have been prepared by the site based on the limited resources available at its disposal and human errors may inadvertently creep in despite the site&rsquo;s best efforts. The user agrees not to hold the site responsible for such errors or omissions The site does not provide any time limit or provides any user agrees not to hold the site responsible for such errors or omissions. The site does not provide any time limit or provides any express or implied time warranty of any kind, regarding any matter pertaining to this service. Xpsolv shall not be liable, at any time, for any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communications line failure, theft or destruction or unauthorized access to, alteration of, or use of information contained at this site.</li>
                        <li style={{fontSize:14,marginBottom:10,marginLeft:25}}>The user is aware and understands that Xpsolv does not provide any judgment or warranty regarding the authenticity or correctness of the content of other services or sites that the screen links refer to. The user is aware and understands that a link to another service or site is not an endorsement of the service or site and Xpsolv shall not be responsible in any manner whatsoever for the consequences of any act or omission on the part of the user based on the information contained in such other services or sites.</li>
                        <li style={{fontSize:14,marginBottom:10,marginLeft:25}}>Xpsolv reserves its right to monitor the use of all or any part of its service without any prior intimation or any obligation to the user.</li>
                        <li style={{fontSize:14,marginBottom:10,marginLeft:25}}>The user agrees that he shall not alter the information, content or material or use of such information, content or material contained on the site for any purpose that constitutes a violation of the copyright of Xpsolv or of a third-party information provider. The user agrees not to copy, reproduce, republish, upload, post, transmit or distribute the material in any way for any use without obtaining the prior written consent from SELTCORP.</li>
                        <li style={{fontSize:14,marginBottom:10,marginLeft:25}}>The user agrees that he shall not solicit or invite other users to share the credits/points/balance/anything else that he earns, that accrue to him in his individual capacity; and agrees not to sell the credits/points/balance/anything else for any consideration to a third party. In case of a violation of this condition the user shall become liable to pay for the credits/points/balance/anything else that he has sold or shared and shall forfeit all his credits/points/balance/anything else accumulated and his account shall be terminated.</li>
                        <li style={{fontSize:14,marginBottom:10,marginLeft:25}}>If the user does not agree to any of the terms mentioned in this agreement, then the user should exit the site through his browser or uninstall the mobile application.</li>
                    </ul>
                </p>
            </div>

            <div>
                <h3 style={{padding:' 15px 0',fontSize: 16,}}>Secure Access To Service</h3>
                <p style={{fontSize:14,}}>
                    The access to the site & mobile app is based on One-time Password and user undertakes to maintain the confidentiality of the OTP & account, and shall be held solely responsible for all acts and omissions that occur under his account if OTP is compromised. Xpsolv can not and will not be liable for any loss or damage arising from the user's failure to comply with the terms and conditions of this agreement. The user should always remember to LOG OFF COMPLETELY from Xpsolv, if he is accessing it from a public place.
                </p>
            </div>

            <div>
                <h3 style={{padding:' 15px 0',fontSize: 16,}}>Acts of Prohibitions</h3>
                <p style={{fontSize:14,marginBottom:10}}>
                    The Xpsolv service is made available for personal, non-commercial use only. Broadcasting the same to other individuals/ group of people or institution(s) through any means, including but not limited to through the internet, television, handheld devices, and other devices, is not permitted and will be considered as a breach of Intellectual Property Rights of the Company. The Company reserves the right to terminate the subscription without any prior notice and to initiate any legal proceedings in case of such breach.
                </p>
                <p style={{fontSize:14,marginBottom:10}}>
                     The user is prohibited from using the Xpsolv service for any illegal or unauthorized purpose. The site may at its sole discretion remove information, content or material that is incorrect, inaccurate, unlawful, fraudulent, threatening, libellous, defamatory, obscene or otherwise objectionable, or infringes or violates any party's intellectual property or other proprietary rights or these Terms of Service.
                </p>
                <ul>
                    <li style={{fontSize:14,marginBottom:10,marginLeft:25}}>Modifying, adapting, translating, or reverse engineering any portion of the Xpsolv service.</li>
                    <li style={{fontSize:14,marginBottom:10,marginLeft:25}}>Removing any copyright, trademark or other proprietary rights notices contained in or on the Xpsolv service.</li>
                    <li style={{fontSize:14,marginBottom:10,marginLeft:25,}}>Using any bots, robot, spider, site search/retrieval application, or other device to retrieve or index any portion or the Xpsolv service.</li>
                    <li style={{fontSize:14,marginBottom:10,marginLeft:25,}}>Collecting any information about other users (including names, phone numbers and/or email addresses) for unauthorized purposes.</li>
                    <li style={{fontSize:14,marginBottom:10,marginLeft:25,}}>Reformatting or framing any portion of the web pages that are part of the Xpsolv service, creating user accounts by automated means or under false or fraudulent pretences.</li>
                    <li style={{fontSize:14,marginBottom:10,marginLeft:25,}}>Creating or transmitting unwanted electronic communications such as spam or chain letters to other user or otherwise interfering with other user's enjoyment of the service.</li>
                    <li style={{fontSize:14,marginBottom:10,marginLeft:25,}}>Submitting information or material of any third party without such third party's prior written consent.</li>
                    <li style={{fontSize:14,marginBottom:10,marginLeft:25,}}>Directing any user (for example, by linking) to any information or material of any third party without such third party's prior written consent or the user&rsquo;s consent.</li>
                    <li style={{fontSize:14,marginBottom:10,marginLeft:25,}}>Submitting information or material that infringe, misappropriate, or violate the intellectual property, publicity, privacy, or other proprietary rights of any party.</li>
                    <li style={{fontSize:14,marginBottom:10,marginLeft:25,}}>Transmitting any viruses, worms, defects, Trojan horses, or other items of a destructive nature.</li>
                    <li style={{fontSize:14,marginBottom:10,marginLeft:25,}}>Submitting information or material that are unlawful or promote or encourage illegal activity; or submitting false or misleading information.</li>
                    <li style={{fontSize:14,marginBottom:10,marginLeft:25,}}>Submitting information that is obscene, voyeuristic or contains pornography or contain an indecent representation of women within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986; or cause to be displayed or transmitted or circulated through the site in any electronic form, any material which is lascivious or appeal to the prurient interest or if its effect is such as to tend to deprave and corrupt persons who are likely, having regard to the fact that the site is meant for educational and academic pursuit by students who are yet to reach an age capable of discerning the propriety of the material contained or embodied in such transmission.</li>
                    <li style={{fontSize:14,marginBottom:10,marginLeft:25,}}>Upload, post, email or otherwise transmit any Content that is unlawful, harmful, threatening, abusive, harassing, tortuous, defamatory, vulgar, obscene, libellous, invasive of another's privacy, hateful, or racially, ethnically or otherwise objectionable, or a threat to the nation's communal harmony, peace and security.</li>
                    <li style={{fontSize:14,marginBottom:10,marginLeft:25,}}>Upload, post, email or otherwise transmit any Content that the user doesn't have a right to transmit under any law or under contractual or fiduciary relationships (such as inside information, proprietary and confidential information learned or disclosed as part of employment relationships or under non-disclosure agreements).</li>
                    <li style={{fontSize:14,marginBottom:10,marginLeft:25,}}>Interfere with or disrupt the Service or servers or networks connected to the Service, or disobey any requirements, procedures, policies, or regulations of networks connected to the Service.</li>
                    <li style={{fontSize:14,marginBottom:10,marginLeft:25,}}>Intentionally or unintentionally violate any applicable local, state, national or international law</li>
                    <li style={{fontSize:14,marginBottom:10,marginLeft:25,}}>Any other conduct, act or behaviour that is illegal or unlawful.</li>
                </ul>
                <p style={{fontSize:14,}}>
                    While Xpsolv prohibits such conduct and content on its site, the user understands and agrees that he/she nonetheless may be exposed to such information materials and that he shall immediately inform Xpsolv of such violation. If the user continues to use the Xpsolv service, he shall do so at his own risk and peril.
                </p>
            </div>

            <div>
                <h3 style={{padding:' 15px 0',fontSize: 16,}}>Proprietary Rights</h3>
                <p style={{fontSize:14,}}>
                    User acknowledges that all Intellectual Property Rights including but not limited to Copyright, Patent, Trademarks, trade secrets in the products, modules, contents, and programs shall continue to always vest with the Company. The student/parent shall not have the right to claim any rights over the Intellectual Property in the programs by virtue of creating/ printing test papers generated through the Xpsolv platform. Further, under no circumstances, shall the student/parent acquire the right to make copies. The user acknowledges that in the event of breach or threatened breach of this Clause, monetary damages may not be an adequate remedy, therefore, the Company in addition to other rights and remedies shall be entitled to injunctive relief to restrain the student/parent from any such breach, threatened or actual. User further acknowledges and agrees that material and content contained in sponsor advertisements or information presented to the user through the Xpsolv services or advertisers is protected by copyrights, trademarks, service marks, patents or other proprietary rights and laws. Except as expressly authorized by SELTCOPR or advertisers, user agrees not to modify, rent, lease, loan, sell, distribute or create derivative works based on the Xpsolv service, in whole or in part.
                </p>

            </div>

            <div>
                <h3 style={{padding:' 15px 0',fontSize: 16,}}>Disclaimer Of Warranties</h3>
                <p style={{fontSize:14,marginBottom:10}}>
                    User expressly understands and agrees that:
                </p>
                <ul>
                    <li style={{fontSize:14,marginBottom:10,marginLeft:25,}}>Use of the service is at the user's sole risk. The service is provided on an as is and as available basis. Xpsolv expressly disclaims all warranties of any kind, whether express or implied, including, but not limited to the implied warranties of suitability for a particular purpose and non-infringement.</li>
                    <li style={{fontSize:14,marginBottom:10,marginLeft:25,}}>Xpsolv makes no warranty that (i) the service will meet the user&rsquo;s requirements, (ii) the service will be uninterrupted, timely, secure, or error-free, (iii) the results that may be obtained from the use of the service will be accurate or reliable, (iv) the quality of any products, services, information, or other material purchased or obtained by the user through the service will meet user's expectations, and (v) any errors in the software will be corrected.</li>
                    <li style={{fontSize:14,marginBottom:10,marginLeft:25,}}>Any material downloaded or otherwise obtained using the service is done at the user's own discretion and risk and that user will be solely responsible for any damage to the user's computer system or loss of data that results from the download of any such material.</li>
                    <li style={{fontSize:14,marginBottom:10,marginLeft:25,}}>No advice or information, whether oral or written, obtained by the user from SELTCORP or through or from the service shall create any warranty not expressly stated in the ToS.</li>
                </ul>
            </div>

            <div>
                <h3 style={{padding:' 15px 0',fontSize: 16,}}>Limitation Of Liability</h3>
                <p style={{fontSize:14,}}>
                    Under no circumstances will SELTCORP be liable to the user for any indirect, incidental, consequential, special, or exemplary damage arising out of or in connection with use of the Xpsolv service, whether or not SELTCORP has been advised of the possibility of such damages. Such limitation of liability shall apply (i) whether the damages arise from use or misuse of and reliance on the Xpsolv service, from inability to use the Xpsolv service, or from the interruption, suspension, or termination of the Xpsolv service (including such damages incurred by third parties), and (ii) notwithstanding any failure of essential purpose of any limited remedy and to the fullest extent permitted by law.
                </p>
            </div>

            <div>
                <h3 style={{padding:' 15px 0',fontSize: 16,}}>Indemnity</h3>
                <p style={{fontSize:14,}}>
                    The user agrees to indemnify and hold SELTCORP, and its subsidiaries, affiliates, officers, agents, co-branders or other partners, and employees, harmless from any claim or demand, including reasonable attorney's fees, made by any third party due to or arising out of content the user submits, posts or transmits through the service, the user's use of the Service, the user's connection to the Service, the user's violation of the ToS, or the user's violation of any rights of another.
                </p>
            </div>

            <div>
                <h3 style={{padding:' 15px 0',fontSize: 16,}}>No Resale Of Service</h3>
                <p style={{fontSize:14,}}>
                    The user agrees not to reproduce, duplicate, copy, sell, resell, or exploit for any commercial purposes, any portion of the Service, use of the Service, or access to the Service.
                </p>
            </div>

            <div>
                <h3 style={{padding:' 15px 0',fontSize: 16,}}>Modifications To Service</h3>
                <p style={{fontSize:14,}}>
                    SELTCORP reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the service (or any part thereof) with or without notice. The user agrees that SELTCORP shall not be liable to the user or to any third party for any modification, suspension or discontinuance of the service.
                </p>
            </div>

            <div>
                <h3 style={{padding:' 15px 0',fontSize: 16,}}>Notice</h3>
                <p style={{fontSize:14,}}>
                    Notices to the user may be made via email or regular mail, push notiifcation. The service of the site may also provide notices of changes to the TOS or other matters by displaying notices or links to notices to the user generally on the service.
                </p>
            </div>

            <div>
                <h3 style={{padding:' 15px 0',fontSize: 16,}}>Trademark Information</h3>
                <p style={{fontSize:14,}}>
                    The site design and the service marks, other logos, products, and service names are trademarks of SELTCORP in the name of Xpsolv. The user agrees not to display or use in any manner, the trademarks of Xpsolv.
                </p>
            </div>

            <div>
                <h3 style={{padding:' 15px 0',fontSize: 16,}}>Copyright</h3>
                <p style={{fontSize:14,}}>
                    SELTCORP is a registered entity under the laws of India with all rights reserved here-in. The copyright of all materials provided on this website are held by SELTCORP. None of the materials displayed on or within any Xpsolv including but not limited to website, mobile app (Android & Apple store) etc, may be copied, reproduced, distributed, republished, downloaded, displayed, posted, modified, altered, reverse engineered, or transmitted in any form or by any means, including, but not limited to, electronic, mechanical, photocopying, recording, screen snapshot/ screen captured, or otherwise, without prior written permission of SELTCORP, the copyright owner.
                </p>
            </div>

            <div>
                <h3 style={{padding:' 15px 0',fontSize: 16,}}>Violations</h3>
                <p style={{fontSize:14,}}>
                    Please report any violation of the terms and conditions of this agreement to mail@seltcorp.com
                </p>
            </div>

            <div>
                <h3 style={{padding:' 15px 0',fontSize: 16,}}>Force Majeure</h3>
                <p style={{fontSize:14,}}>
                    Xpsolv shall have no liability to the user for the matters where it has no control or which are beyond its control and fall in this category.
                </p>
            </div>

            <div>
                <h3 style={{padding:' 15px 0',fontSize: 16,}}>Entire Agreement</h3>
                <p style={{fontSize:14,}}>
                    These Terms of Service constitute the entire agreement between the parties with respect to the subject matter thereof and supersedes and replaces all prior or contemporaneous understandings or agreements, written or oral, regarding such subject.
                </p>
            </div>

            <div>
                <h3 style={{padding:' 15px 0',fontSize: 16,}}>Jurisdiction</h3>
                <p style={{fontSize:14,}}>
                    These Terms of Service will be governed by and construed in accordance with the laws of India and any dispute arising out of the use of the site shall be subject to the exclusive jurisdiction of courts at Uttar Pradesh, India. If for any reason a court of competent jurisdiction finds any provision or portion of the Terms of Service to be unenforceable, the remainder of the Terms of Service will continue in full force and effect. The user shall not assign these Terms of Service or assign any rights or delegate any obligations hereunder, in whole or in part, whether voluntarily or by operation of law, without our prior written consent, any such purported assignment or delegation will be null and void and of no force or effect.
                </p>
            </div>

            <div>
                <h3 style={{padding:' 15px 0',fontSize: 16,}}>User Privacy Policy</h3>
                <p style={{fontSize:14,}}>
                    The user consents that by subscribing/using the Company’ products, programs or any other services, it expressly allows the Company to predictive learning and to store, use, analyse, distribute, or deal with in any other manner, as the Company deems appropriate, from the information/data captured by the Company through its unique practice and test and from the Customer’s usage of Company’ services as the Company, its associates or any third party as the Company may authorize
                </p>
                <p style={{fontSize:14,marginBottom:10}}>
                    The user's privacy is critically important to SELTCORP. SELTCORP has few fundamental principles applied through Xpsolv:
                </p>
                <ul>
                    <li style={{fontSize:14,marginBottom:10,marginLeft:25,}}>Xpsolv asks its users to supply various personal data when placing an enrolling for service, such as name, address, phone number, gender, school details payment related information, and valid E-mail id.</li>
                    <li style={{fontSize:14,marginBottom:10,marginLeft:25,}}>Xpsolv uses this data only for the purpose of offering services and communicating with the user</li>
                    <li style={{fontSize:14,marginBottom:10,marginLeft:25,}}>Xpsolv treats this customer information as private and confidential, and it shall not disclose this information to other individuals or organizations except the educational institutions unless required by law.</li>
                </ul>
                <p style={{fontSize:14,}}>
                    If the user feels that Xpsolv has violated this Statement on Privacy in any way, please contact SELTCORP at mail@seltcorp.com so that we may address this issue.
                </p>
            </div>

            <div>
              {/*   <h3 style={{padding:' 15px 0',fontSize: 16,}}>Refund, Cancellation & Expiration Policy</h3>
                <p style={{fontSize:14,marginBottom:10}}>
                    No refund or cancellation is permitted once the user has completed a transaction at xpsolv.com for subscription of plan etc (Valid only for purchases made through xpsolv.com).
                </p>

                <ul>
                    <li style={{fontSize:14,marginBottom:10,marginLeft:25,}}>User needs to contact customer support through e-mail (<a href="mailto:connect@xpsolv.com">connect@xpsolv.com</a> ) to enable effective provision of Services. Request from alternate details will not be entertained.</li>
                    <li style={{fontSize:14,marginBottom:10,marginLeft:25,}}>It is Your sole responsibility to ensure that the account information provided by You is accurate, complete, and latest.</li>
                    <li style={{fontSize:14,marginBottom:10,marginLeft:25,}}>You acknowledge that Your ability to use Your account is dependent upon external factors such as internet service providers and internet network availability and the Company cannot always guarantee accessibility to the Application. In addition to the disclaimers set forth in the Terms, the Company shall not be liable to You for any damages arising from Your inability to log into Your account and access the services of the Application at any time.</li>
                    <li style={{fontSize:14,marginBottom:10,marginLeft:25,}}>Cancellation can happen when query is raised only with <a href="mailto:connect@xpsolv.com">connect@xpsolv.com</a>.</li>
                    <li style={{fontSize:14,marginBottom:10,marginLeft:25,}}>Your Subscribed package will continue until terminated. Unless you cancel your subscription before your payment, you authorize us to charge the next transaction from your available balance.</li>
                    <li style={{fontSize:14,marginBottom:10,marginLeft:25,}}>The tenure of the subscription availed by the User, shall begin from the date of activation of the subscription and shall continue till the expiry of subscription period. It is expressly disclaimed that the subscription tenure is not being offered as per the tenure of academic session/year and the subscription shall stand terminated on the expiry of tenure of subscription, notwithstanding the fact that the academic session/year of the User has not been completed. It is for the User to subscribe for the subscriptions after conducting their own diligence and verifying its suitability as per their academic session/year.</li>
                </ul>

                <p style={{fontSize:14,marginBottom:10}}>
                    At Xpsolv, the package expires as per the validity suggested at the time they are purchased. The subscription price will be made clear to you on our subscription plan pages and may vary from time to time. You agree to pay at the rates notified to you at the time of your subscription.
                </p> */}
                <p style={{fontSize:14}}> &nbsp; </p>

            </div>

        </div>
    );
}