 import performanceIcon from '@iconify/icons-eva/bar-chart-2-fill';
// import performanceIcon from '@iconify/icons-ic/outline-bar-chart';

import histroyIcon from '@iconify/icons-ic/baseline-history';
import testReceivedIcon from '@iconify/icons-ic/outline-question-answer';
import reviewAnswerIcon from '@iconify/icons-ic/outline-reviews';
import support from '@iconify/icons-ic/outline-support-agent';
import profileIcon from '@iconify/icons-ic/person-outline';
import accountSummaryIcon from '@iconify/icons-ic/round-currency-rupee';
import homeWork from '@iconify/icons-ic/round-work-outline';
import { Icon } from '@iconify/react';

// import checkmarkcircle2fill from '@iconify/icons-eva/checkmark-circle-2-fill';
// import fileTextFill from '@iconify/icons-eva/file-text-fill';
// import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
// import profileIcon from '@iconify/icons-ic/round-account-circle';
// import questionmarkfill from '@iconify/icons-eva/question-mark-circle-fill';
// import CurrencyRupeeIcon from '@iconify/icons-ic/round-currency-rupee';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Profile',
    path: '/dashboard/app',
    icon: getIcon(profileIcon),
    type:'common'
  },{
    title: 'Homework',
    path: '/dashboard/homework-teacher',
    icon: getIcon(homeWork),
    type:'teacher'
  },
  {
    title: 'Homework ',
    path: '/dashboard/homework-parent',
    icon: getIcon(homeWork),
    type:'student'
  },  
  {
    title: 'School Test',
    path: '/dashboard/testReceived',
    icon: getIcon(testReceivedIcon),
    type:'student'
  },
  {
    title: 'Check Answers',
    path: '/dashboard/reviewAnswers',
    icon: getIcon(reviewAnswerIcon),
    type:'common'
  },  
  {
    title: 'History',
    path: '/dashboard/test-history',
    icon: getIcon(histroyIcon),
    type:'common'
  },
  {
    title: 'Performance Report',
    path: '/dashboard/performanceReport',
    icon: getIcon(performanceIcon),
    type:'student'
  },
  {
    title: 'Account  Summary',
    path: '/dashboard/account-summary',
    icon: getIcon(accountSummaryIcon),
    type:'student'
  },
  {
    title: 'Support & Help',
    path: '/dashboard/support',
    icon: getIcon(support),
    type:'common'
  },
 /*  {
    title: 'Formik Tutorial',
    path: '/dashboard/test-formik',
    icon: getIcon(fileTextFill)
  }, */
/*   {
    title: 'user',
    path: '/dashboard/user',
    icon: getIcon(peopleFill)
  }, */
/*   {
    title: 'Plan Test',
    path: '/dashboard/plan-test',
    icon: getIcon(fileAddFill)
  }, */
  
  /* {
    title: 'Generate Test',
    path: '/dashboard/generateTest',
    icon: getIcon(fileAddFill),
    type:'teacher'
  },
   {
    title: 'Add New Question',
    path: '/dashboard/add-question',
    icon: getIcon(questionMarkCircleOutline)
  } */
];

export default sidebarConfig;
