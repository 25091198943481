import { Form, FormikProvider, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
// material 
import {
    Stack,
    TextField
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { toast } from 'react-toastify';
 
// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';

// ----------------------------------------------------------------------
import { AuthApi } from '../../service/index';


toast.configure();
export default function OtpForm({ userInfo }) {
    const navigate = useNavigate();

    const OtpSchema = Yup.object().shape({
        otp: Yup.string()
            .min(4, 'Too Short!')
            .max(6, 'Too Long!')
            .required('OTP is required')
    });

    const formik = useFormik({
        initialValues: {
            otp: ''
        },
        validationSchema: OtpSchema,
        onSubmit: () => {
            register();
        }
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    const register = () => {
        try {
            const reqBody = {
                otp: formik.values.otp,
                ...userInfo
            }
            console.log("userInfo", userInfo);

            AuthApi.register(reqBody).then(
                (resp) => {
                    if (resp.status === 200) {
                        console.log("Register", resp);
                        formik.resetForm();
                        if (resp.data.data) {
                            toast.success('Success');
                            localStorage.setItem("token", resp.data.data.jwtToken);
                            localStorage.setItem("userInfo", JSON.stringify(resp.data.data.studentSchool));
                            navigate('/dashboard/app', { replace: true });
                        }
                        else{
                            toast.warning(resp.data.message);
                        }
                    }
                },
                (error) => { formik.setSubmitting(false); toast.warning('Error in Register'); }
            );
        }
        catch (error) {
            console.log("Register Catch", error);
        }
    }
    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <TextField
                        fullWidth
                        type="text"
                        label="OTP"
                        {...getFieldProps('otp')}
                        error={Boolean(touched.otp && errors.otp)}
                        helperText={touched.otp && errors.otp}
                    />
                    <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                    >
                        Verify OTP
                    </LoadingButton>
                </Stack>
            </Form>
        </FormikProvider>
    );
}
