import { Form, FormikProvider, useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import useRazorpay from "react-razorpay";
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';


// material
import { Box, Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';

// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';
import '../components/css/style.css';


// components
import Page from '../components/Page';
import { paymentAPI } from '../service/index';

toast.configure();

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 345,
  },
  paper: {
    padding: theme.spacing(2)
  },
  textcolor: {
    color: '#007c7c',
  },
  summaryaccout: {
    borderBottom: '1px solid #efefef',
    padding: 10,
    paddingTop: 0,
    paddingLeft: 0,
    marginBottom: 12,
    width: 'auto',
    fontSize: 16,
  },

  amount: {
    fontSize: 14,
    color: "#000",
    textAlign: "right",
    fontWeight: 500,
  },
  amount2: {
    fontSize: 14,
    color: "#000",
    fontWeight: 500,
  },
  margin000: {
    margin: "0 30px",
  },
  infoicons: {
    float: "left",
    width: "15px",
    marginTop: "2px",
    marginRight: "5px"
  },
  buttoncomon: {
    height: '43px',
    margin: '10px 0',
    padding: '0 30px',
  },
  buttoncomon33: {
    height: '58px',
    marginBottom: '23px',
    padding: '0 30px',
  },
  buttoncomon22: {
    height: '43px',
    margin: '10px 0',
    padding: '0 30px',
    background:'#999',
  },
  paperComponent:{
    width:'90%',
    height:'20%',
    margin:'auto',
    boxShadow: "none",
    border:" 1px solid #dfdfdf",
    padding: "39px 0",
  },
  marginalign:{
    padding: '0 15px',
  },
  bordertotal:{
    borderTop: "1px solid #dfdfdf",
    marginTop:" 4px",
    padding: "10px 0",
    borderBottom: "1px solid #dfdfdf",

  }
}));

export default function Checkout(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [userMobile, setUserMobile] = useState('')
  const [userDetails, setUserDetails] = useState([]);
  const [isTeacher, setIsTeacher] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const [isShow2, setisShow2] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0.00);
  const [gst, setGST] = useState(0.00);
  const [baseAomunt, setBaseAmount] = useState(0.00);
  const [paymentDetail, setPaymentDetail] = useState();
  const [orderId, setOrderId] = useState('');
  const [paymentSuccessData, setPaymentSuccessData] = useState();
  const [showAmountValidation, setShowAmountValidation] = useState(false);
  const [showAmountValidationText, setShowAmountValidationText] = useState("");
  
  useEffect(() => {
    getUserDetails();
  }, []);

  const validationInfo = Yup.object().shape({
    amounts: Yup.string().required('*Amount is required')
  });


	const formik = useFormik({
		initialValues: {
			amounts: ''
		},
		validationSchema: validationInfo,
		onSubmit: (data) => {
			console.log(data);
		},
	});
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const getUserDetails = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log("userInfo", userInfo);
    setUserDetails(userInfo);
    setUserMobile(userInfo[0]?.Mobile);
  }
  const amountBreakdown = () => {
    if(formik.values.amounts > 500){
      setShowAmountValidation(true);
      setShowAmountValidationText('*Can not add amount more then 500');
      return false;
    } 
    if(formik.values.amounts < 20){
      setShowAmountValidation(true);
      setShowAmountValidationText('*Can not add amount less then 20');
      return false;
    } 
    if (formik.values.amounts !== '') {
      setShowAmountValidation(false);
      setShowAmountValidationText('');
      setIsPayment(true);
      setGST(((18/100)*formik.values.amounts).toFixed(2));
      const n = Number(formik.values.amounts);
      setBaseAmount(n.toFixed(2));
      setTotalAmount((((18/100)*formik.values.amounts)+n).toFixed(2));
    }
  }

  const cancelPayment = () => {
    navigate('/dashboard/account-summary');
  }

  const amountChange = (event) => {
    if (formik.values.amounts > 20 && formik.values.amounts < 500) {
     setShowAmountValidation(false);
     setShowAmountValidationText("");
    }
    if (formik.values.amounts !== '') {
      setIsPayment(false);
      setGST(((18/100)*formik.values.amounts).toFixed(2));
      const n = Number(formik.values.amounts);
      setBaseAmount(n.toFixed(2));
      setTotalAmount((((18/100)*formik.values.amounts)+n).toFixed(2));
    }
  }

  const pay = (totalAmount, baseAmt) => {
    try {
      if(formik.values.amounts > 500){
        setShowAmountValidation(true);
        setShowAmountValidationText('*Can not add amount more then 500');
        return false;
      } 
      if(formik.values.amounts < 20){
        setShowAmountValidation(true);
        setShowAmountValidationText('*Can not add amount less then 20');
        return false;
      } 
       
      setShowAmountValidation(false);
      setShowAmountValidationText('');

      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      const idStudentSchool = userInfo[0]?.StudentInfo.map(x => x.idStudentSchool).join(",");
      const reqsBody = {
        "amount": Number(totalAmount),
        "idStudentSchoolsc": idStudentSchool,
      };
      paymentAPI.getOrderId(reqsBody).then(
         (resp) => {
          if (resp.status === 200) {
            if (resp.data && resp.data.status === 200) {
              setPaymentDetail(resp.data.data);
              
              const buff = Buffer.from(resp.data.data, "base64");
              const str = buff.toString("utf8");
              setOrderId(str);
              handlePayment(totalAmount,str,baseAmt);            
            }
          }
        },
        (error) => {
          alert(error.response.data.error);
        }
      );
    }
    catch (error) {
      console.log("Payment error", error);
    }
  }

  const Razorpay = useRazorpay();

  const handlePayment = useCallback( (totalAmount, orderId, baseAmt) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const fName = `${userInfo[0].StudentInfo[0].FirstName} ${userInfo[0].StudentInfo[0].LastName}`
    const mobNumber= userInfo[0].Mobile;
    let emailAddress = "";

    userInfo[0]?.StudentInfo.forEach(element => {
      if (element.Email) {
        emailAddress = element.Email;
      }
    });
    if(emailAddress === ''){
      emailAddress= `${mobNumber}@domain.com`
    }

    const options = {
      key: "rzp_test_S1z9Gbgeb1djkL", // QA
    //  key: "rzp_live_PgOEHSP5o0gynM", // production
      amount: totalAmount*100,
      currency: "INR",
      name: "XPSOLV",
      description: "Subscription amount",
      image: "/static/logo.svg",
      order_id: orderId,
      handler: (res) => {
        console.log(res);
        if(res){
          validatePayments(res, orderId, baseAmt);
        }
      },
      prefill: {
        name: fName,
        email: emailAddress,
        contact: mobNumber,
      },
      config: {
        display: {
          blocks: {
            banks: {
              name: 'All payment methods',
              instruments: [
                {
                  method: 'upi'
                },
                {
                    method: 'wallet'
                }
              ],
            },
          },
          sequence: ['block.banks'],
          preferences: {
            show_default_blocks: false,
          },
        },
      },  
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    const rzpay = new Razorpay(options);

    rzpay.on("payment.failed",  (response) => {
      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
    });

    rzpay.open();
  }, [Razorpay]);

  const validatePayments = (data, orderId, baseAmt) => {
    try {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      const idStudentSchool = userInfo[0]?.StudentInfo.map(x => x.idStudentSchool).join(",");
      const reqsBody = {
        "mobilenum": userInfo[0]?.Mobile,
        "idStudentSchoolsc": idStudentSchool,
        "amount": baseAmt,
        "orderId": orderId,
        "pgPaymentId": data.razorpay_payment_id,
        "PaySign": data.razorpay_signature
      };
      paymentAPI.validatePayment(reqsBody).then(
         (resp) => {
          if (resp.status === 200) {
            if (resp.data && resp.data.status === 200) {
              setPaymentSuccessData(resp.data);
              toast.success(`Payment Successful`,{
                position: toast.POSITION.TOP_CENTER
                }); 
              navigate('/dashboard/account-summary');       
            }
          }
        },
        (error) => {
          alert(error.response.data.error);
        }
      );
    }
    catch (error) {
      console.log("Payment error", error);
    }
  }

  return (
    <Page title="Checkout | XPSOLV">
      				<FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
      {!isTeacher && 
        <Paper elevation={10} className={classes.paperComponent}>
        <div className={classes.margin000}>
            <Grid container item spacing={2} >
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom className={classes.summaryaccout}>
                  Subs<Box component="span" display="inline" className={classes.textcolor}>cription</Box>
                </Typography>
              </Grid>
              <Grid item md={4} xs={6}>
                <TextField 
                id="outlined-basic" 
                label="Amount(INR)" 
                variant="outlined"
                type="number"
                onChange={(event) => amountChange(event)}
                className={classes.inputid}
                {...getFieldProps('amounts')}
                error={(Boolean(touched.amounts && errors.amounts)) || showAmountValidation}
                helperText={(touched.amounts && errors.amounts)|| showAmountValidationText}
              />
              </Grid>
              <Grid item md={2} xs={6}>
                <Button 
                type="submit"
                variant="contained"                 
                loading={isSubmitting.toString()} 
                className={classes.buttoncomon33}
                onClick={() => amountBreakdown()}
                >    
                  Proceed
                </Button></Grid>
              {isPayment && 
              <Grid container className={classes.marginalign}>
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom className={classes.summaryaccout}>
                  Summ<Box component="span" display="inline" className={classes.textcolor}>ary</Box>
                </Typography>
              </Grid>
            <Grid container item spacing={2} >

              <Grid item md={3} xs={6} >
                <Typography variant="h6" gutterBottom className={classes.amount2}>
                Subscription Amount
                </Typography>
              </Grid>
              <Grid item md={3} xs={6}>
                <Typography variant="h4" gutterBottom className={classes.amount}>
                {Number(formik.values.amounts).toFixed(2)}
                </Typography>
              </Grid>
              </Grid>
            <Grid container item spacing={2}>

              <Grid item md={3} xs={6}>
                <Typography variant="h6" gutterBottom className={classes.amount2}>
                GST (18%)
                </Typography>
              </Grid>
              <Grid item md={3} xs={6}>
                <Typography variant="h4" gutterBottom className={classes.amount}>
                {((18/100)*formik.values.amounts).toFixed(2)}
                </Typography>
              </Grid>
              </Grid>
            <Grid container item spacing={0} className={classes.bordertotal}>

              <Grid item md={3} xs={6}>
                <Typography variant="h6" gutterBottom className={classes.amount2} style={{fontWeight:700,}}>
                Total Amount (INR) 
                </Typography>
              </Grid>
              <Grid item md={3} xs={6}>
                <Typography variant="h4" gutterBottom className={classes.amount} style={{fontWeight:700,}}>
                {(((18/100)*formik.values.amounts)+Number(formik.values.amounts)).toFixed(2)}
                </Typography>
              </Grid>
              </Grid>
              <Grid container item spacing={2}>
            
            <Grid item md={2} xs={6}><Button variant="contained" className={classes.buttoncomon22} onClick={() => cancelPayment()}>Cancel</Button></Grid>
            <Grid item md={2} xs={6}><Button variant="contained" className={classes.buttoncomon} onClick={() => pay((((18/100)*formik.values.amounts)+Number(formik.values.amounts)).toFixed(2), formik.values.amounts)}>Pay</Button></Grid> 
            </Grid>
         
            </Grid>}
            </Grid>    
        </div>
      </Paper>}
      </Form>
      </FormikProvider>
    </Page>
  );
}




