import { Icon } from '@iconify/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


// material
import {
  Box, Button, Chip, Container, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl, FormControlLabel, FormLabel, Grid, Link, MenuItem, Radio, RadioGroup, Select, Stack, Tooltip, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

// components
import Loader from '../components/Loader';
import Page from '../components/Page';
import { homeWorkApi, TestApi } from '../service/index';

const getIcon = (name) => <Icon color="#999" icon={name} width={22} height={22} />;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  paper: {
    padding: theme.spacing(2)
  },
  headingpart: {
    color: '#007c7c',

  },
  bordertable: {
    border: '1px solid #e4e4e4',
    marginBottom: '20px',
    borderRadius: '10px',
  },
  headtable: {
    background: '#f5f6fa',
  },
  afterbeforeshape2: {
    background: '#007c7c',
    margin: '20px 0',
    width: 'max-content',
    padding: '8px 20px',
    color: '#fff',
    borderRadius: 8,
    position: 'relative',
    fontSize: 13,
  },
  afterbeforeshape: {
    position: 'absolute',
    heihgt: 0,
    width: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderTop: '13px solid #007c7c',
    top: '115%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  dotspace: {
    marginTop: '16px',
  },
  headingtenw: {
    marginTop: '16px',
  },
  buttoncomon: {
    marginTop: 18,
    padding: '15px 30px',
    fontWeight: 500,
    background: '#007c7c',
  },
  borderbotmten: {
    borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
    paddingBottom: 20,
    marginBottom: 15,

  },
  headingtenw2: {
    marginTop: 11,
    fontSize: 16,
  },
  datepiker: {
    width: '100%',
  },
  width100: {
    width: '30%',
  },
  widthaccordian: {
    width: '15%',
  },
  truncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '260px',
  },
  maxWidth: {
    width: '100%',
    textAlign: 'left',
    margin: 24,
  },
  maxWidth21: {
    width: '100%',
    textAlign: 'left',
    margin: 10,
  },
  btnchapter: {
    fontSize: "12px",
    fontWeight: "600",
    padding: "5px 10px"
  },
  btnchapter2: {
    fontSize: "12px",
    fontWeight: "600",
    padding: "5px 10px",
    minWidth: '31px'
  },
  maxWidth2: {
    width: '100%',
    textAlign: 'left !important',
  },
  truncate2: {
    background: 'transparent',
    padding: 0,
  },
  truncate3: {
    padding: 0,
  },
  ternten: {
    verticalAlign: 'text-top',
  },
  hide: {
    display: 'none',
  },
  seprateline: {
    borderRight: '1px solid #d9d9d9',
    paddingRight: '35px',
    fontSize: 14,
  },
  sepratelineStudent: {
    paddingRight: '15px',
    fontSize: 14,
  },
  borderseprate: {
    borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
    paddingBottom: "12px",
  },
  specing: {
    fontSize: 13,
  },
  lefttext: {
    textAlign: 'left',
    whiteSpace: 'nowrap',
  },
  iconexpand: {
    width: '47px',
    float: 'left',
    marginRight: '-4px',
    marginTop: '-12px'
  },
  iconcolor: {
    color: '#fff',
    padding: 0,
    marginRight: 10,
    [theme.breakpoints.up('sm')]: {
      color: '#007c7c',
    },
  },
  disablediconcolor: {
    color: '#fff',
    [theme.breakpoints.up('sm')]: {
      color: '#000',
    },
    pointerEvents: 'none',
    cursor: 'not-allowed'
  },
  hidedesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  hidemobile: {
    display: 'block',

    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  cardheader: {
    padding: '0px',
  },
  margingap: {
    marginTop: '20px',
    background: '#ffffff',
    padding: '16px 16px 0 16px',
    borderRadius: '14px',
    marginBottom: '14px',
    border: '1px solid #dce0e4',
  },
  centericons: {
    textAlign: 'center',
  },
  cardbox: {
    padding: '24px 25px 0',
  },
  maincolor: {
    background: '#007c7c',
  },
  labelhead: {
    fontSize: '13px',
    fontWeight: 600,
    color: '#007c7c',
  },
  contentans: {
    marginBottom: '10px',
  },
  radio: {
    '&$checked': {
      color: '#000'
    }
  },
  ulmainpoint: {
    listStyle: 'none',
  },
  ullicontent: {
    marginBottom: 8,
  },
  headingchapter: {
    borderBottom: "1px solid #e5e5e5",
    marginBottom: "11px",
    position: 'relative',
    color: '#007c7c',
  },

  paddzero: {
    padding: "9px",
  },
  closebtn: {
    width: "17px",
    position: "absolute",
    right: '0',
    top: "3px",
    paddingLeft: '47px',
  },
  imgcross: {
    width: 10,

  },
  chaptersdiv: {
    overflow: 'hidden',
  },
  imgplan2: {
    width: 15,
  },
  redalert: {
    color: '#007c7c',
    fontSize: "11px",
    fontWeight: 'normal',
    marginTop: "12px",
    textAlign: 'left',
  },
  borderchangetext: {
    fontSize: 14,
  },
  fontsizeheading: {
    fontSize: 16,
  },
  stepsmoredetails: {
    marginBottom: "14px",
    borderRadius: "14px",
    padding: "35px 0px 11px 23px",
    marginLeft: "35px",
    position: 'relative',
    background: '#fff',
    border: '2px solid #e3e3e3',
    "&:hover": {

      backgroundSize: 'cover',
      background: 'linear-gradient(to right, #d4ede1 15%, #d4ffe9 94%)',
      borderColor: '#fff',
    },
    '&::before': {
      content: '""',
      position: "absolute",
      left: "-39px",
      border: "3px solid #e3e3e3",
      height: "20px",
      width: "20px",
      borderRadius: "41px",
      top: "13px"
    },
    '&::after': {
      left: "-30px",
      width: "2px",
      height: "100%",
      content: "\"\"",
      position: "absolute",
      background: "#007c7c45",
      top: "32px"
    }
  },
  headingtenw4: {
    fontSize: 14,
  },
  marginalign: {
    paddingTop: 30,
  },
  padtop0: {
    paddingTop: '0 !important',
  },
  datesteps: {
    position: "absolute",
    left: "0",
    background: "#007c7c",
    color: "#fff",
    padding: "2px 8px",
    fontSize: "12px",
    '&::after': {
      top: "0",
      left: "100%",
      width: "0",
      height: "0",
      content: "\"\"",
      position: "absolute",
      borderColor: "rgb(0 124 124 / 50%) transparent rgb(127 189 189) rgb(0 124 124)",
      borderStyle: "solid",
      borderWidth: "11px"
    }

  },
  marginpadd: {
    marginTop: "34px",
    marginBottom: "16px",
    textAlign: "left",
    [theme.breakpoints.up('sm')]: {
      textAlign: "left",
      marginTop: "34px",


    },
  },
  headingtenw5: {
    background: "#f7f7f7",
    padding: "4px 13px",
    color: "#000",
    borderRadius: "5px",
    fontSize: "13px"
  },
  padtop02: {
    paddingTop: "8px !important",
    marginTop: "15px",
    paddingLeft: "52px !important",

  },
  padtop03: {
    paddingTop: "8px !important",
    marginTop: "15px",
    textAlign: "right",
    paddingRight: "22px"
  },
  fontmain: {
    cursor: 'pointer',
    fontSize: "14px",
    background: "#007c7c",
    padding: "5px 12px",
    borderRadius: "6px",
    color: "#fff",
  },
  imgplan: {
    width: '20px',
    marginRight: "5px"
  },
  chipsmain: {
    background: "#007c7c14",
    marginRight: "5px",
    padding: "3px 8px",
    borderRadius: "27px",
    color: "#000",
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "30px",
    float: 'left',
    marginBottom: 7,
  },
  themeheading: {
    color: "#007c7c",
    fontSize: "11px"
  },
  labelradio: {
    fontSize: '14px',
  },
  paddmain: {
    paddingBottom: 5,
    paddingTop: '0 !important',
  },
  imgplan2323: {
    marginTop: 30,
  },
  aligncentertext: {
    display: "flex",
    background: "#5c4033ab",
    width: "62px",
    height: "62px",
    borderRadius: "62px",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    color: "#fff",
    position: "absolute",
    top: "80px",
    left: "53px",
    fontSize: " 12px",

  },

  aligncentertextScience: {
    display: "flex",
    background: "#000080",
    width: "62px",
    height: "62px",
    borderRadius: "62px",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    color: "#fff",
    position: "absolute",
    top: "80px",
    left: "53px",
    fontSize: " 12px",
    opacity: '0.5',
  },

  aligncentertextMath: {
    display: "flex",
    background: "#ffff00bf",
    width: "83px",
    height: "83px",
    borderRadius: "62px",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    color: "#000",
    position: "absolute",
    top: "60px",
    left: "43px",
    fontSize: " 12px",
    fontWeight: 600,
  },
  mathLabel: {    
    /* color: "#626567", */
    color: "#D4AC0D",
    fontSize: " 16px",
    fontWeight: 600,
  },
  scienceLabel: {    
    color: "#8E44AD",
    fontSize: " 16px",
    fontWeight: 600,
  },
  socialScienceLabel: {    
    color: "#AF601A",
    fontSize: " 16px",
    fontWeight: 600,
  }
}));

export default function HomeWorkTeacher() {
  const navigate = useNavigate();
  const [showLoder, setShowLoader] = useState(false);
  const [studentName, setStudentName] = useState('');
  const [selected, setSelected] = useState([]);
  const [homeworkData, setHomeworkData] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [initialize, setInitialize] = useState({ publishBy: [], standardSubjects: [] });
  const [open, setOpen] = React.useState(false);
  const [chapters, setChapters] = React.useState('');
  const [isFilter, setIsFilter] = React.useState(true);
  const [filterSubject, setFilterSubject] = React.useState('');
  const [filterClass, setFilterClass] = React.useState('');
  const [idStudentSchool, setIdStudentSchool] = useState(0);
  const [isTeacher, setIsTeacher] = useState(false);
  const [checkDueToday, setCheckDueToday] = useState(false);
  const [checkReceivedToday, setCheckReceivedToday] = useState(false);
  const [checkAll, setCheckAll] = useState(true);
  const [homework, setHomework] = useState([]);
  const [classess, setClassess] = useState([]);
  const [hW, setHW] = useState([]);
  const [finalHW, setFinalHW] = useState([]);
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    setUserDetails(userInfo[0]?.StudentInfo);
    setStudentName(userInfo[0].StudentInfo[0]?.FirstName);
    teacherClasses(userInfo[0]?.StudentInfo[0].idStudentSchool);
    fetchHomeWork(userInfo[0].StudentInfo[0]?.idStudentSchool);
    if (userInfo[0].StudentInfo[0]?.role === 'Teacher') {
      setIsTeacher(true);
    }
  }, []);

  const teacherClasses = (idStudentSchool) => {
    try {
      const reqsBody = {
        "idStudentSchool": idStudentSchool,
        "testHWCall": 2, // added to filter class, subjects, and chapters with no data 
      };
      TestApi.teacherClasses(reqsBody).then(
        (resp) => {
          if (resp.status === 200) {
            if (resp.data.status === 200) {
              const response = resp.data?.data[0]?.ClassInfo;
              setClassess(response);
            }
            else {
              alert(resp.data.message);
            }
          }
          else {
            alert(resp.message);
          }
        },
        (error) => { alert("Error in teacherClasses"); }
      );
    }
    catch (error) {
      console.log("teacherClasses Catch", error);
    }
  }

  const clearFilterVal = () => {
    setCheckAll(true);
    setCheckDueToday(false);
    setFilterSubject('');
    setFilterClass('');
    setInitialize({ publishBy: [], standardSubjects: [] });
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    fetchHomeWork(idStudentSchool);
  }

  const setStuName = (event, name) => {
    if (event) {
      setFilterSubject('');
      setFilterClass('');
      setInitialize({ publishBy: [], standardSubjects: [] });
      setStudentName(name);
      getIdStudentSchool(name);
    }
  };

  const showDetails = (id) => {
    navigate('/dashboard/hWDetails', { replace: true, state: id });
  };

  const dueDates = (event, name) => {
    const HWArr = [];
    if (name === "All") {
      setFinalHW(hW);
      setCheckDueToday(false);
      setCheckReceivedToday(false);
      setCheckAll(true);
    } else if (name === "Due Today") {
      const currentDate = new Date();
      hW.forEach((element, index) => {
        const submitDate = new Date(element.SubmissionDate);
        if (submitDate.getDate() === currentDate.getDate() && (submitDate.getMonth() + 1) === (currentDate.getMonth() + 1)
          && submitDate.getFullYear() === currentDate.getFullYear()) {
          HWArr.push(element);
        }
        setFinalHW(HWArr);
      });
      setCheckDueToday(true);
      setCheckReceivedToday(false);
      setCheckAll(false);
    }
    else if (name === "Recieve Today") {
      const current = new Date();
      hW.forEach((element, index) => {
        const publishDates = new Date(element.PublishDate);
        if (publishDates.getDate() === current.getDate() && (publishDates.getMonth() + 1) === (current.getMonth() + 1)
          && publishDates.getFullYear() === current.getFullYear()) {
          HWArr.push(element);
        }
        setFinalHW(HWArr);
      });
      setCheckDueToday(false);
      setCheckReceivedToday(true);
      setCheckAll(false);
    }
  };

  const getIdStudentSchool = (name) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let idStudentSchool = "";
    userInfo[0]?.StudentInfo.forEach((element, index) => {
      if (element.FirstName.trim() === name) {
        idStudentSchool = element.idStudentSchool;
      }
    });
    if (idStudentSchool) {
      fetchHomeWork(idStudentSchool);
    }
  }

  const fetchHomeWork = (idStudentSchool) => {
    setShowLoader(true);
    try {
      const reqsBody = {
        "idStudentSchool": idStudentSchool,
        "isStudent": 0
      };
      setIdStudentSchool(idStudentSchool);
      homeWorkApi.getHomeworkDetail(reqsBody).then(
        (resp) => {
          if (resp.status === 200) {
            if (resp.data && resp.data.status === 200) {
              if (resp.data.data !== "NA") {
                const filtersObject = additionalFilter(resp.data.data);
                const dataArray = resp.data.data;
                dataArray.forEach((detailData, index) => {
                  filtersObject?.standardSubjects.forEach((element, index) => {
                    if (element?.name?.trim() === detailData?.SubjectName?.trim()) {
                      detailData.SubjectId = element.id;
                    }
                  });

                  // eslint-disable-next-line array-callback-return
                  filtersObject?.publishBy.forEach((element, index) => {
                    if (element.name.trim() === detailData.FirstName.trim()) {
                      detailData.FirstName = element.name;
                    }
                  });
                });
                resp.data.data = dataArray;
                setHomework(resp.data.data);
                setHomeworkData(resp.data.data);
                setHW(resp.data.data);
                setFinalHW(resp.data.data);
              }
              else {
                setHomework([]);
                setHomeworkData([]);
                setHW([]);
                setFinalHW([]);
              }
              setShowLoader(false);
            }
            else {
              alert(resp.data.message);
              setShowLoader(false);
            }

          }
        },
        (error) => {
          alert(error.response.data.error);
          setShowLoader(false);
        }
      );
    }
    catch (error) {
      console.log("Homework Catch", error);
    }
  }

  const additionalFilter = (data) => {
    if (data) {
      setFilterSubject('');
      setFilterClass('');
      const initializeData = { publishBy: [], standardSubjects: [] };
      initializeData.standardSubjects = filterDataKeyName(data, "SubjectName");
      initializeData.publishBy = filterDataKeyName(data, "FirstName");
      setInitialize(initializeData);
      setIsFilter(false);
      return initializeData;
    }
  }
  const filterDataKeyName = (data, keyName) => {
    const tempArray = [];
    data.forEach((element, index) => {
      if (tempArray && tempArray.length > 0) {
        const found = tempArray.some(el => el.name === element[keyName]);
        if (!found) tempArray.push({ id: index + 1, name: element[keyName]?.trim() });
      }
      else {
        tempArray.push({ id: index + 1, name: element[keyName]?.trim() });
      }
    });
    return tempArray;
  }

  const createNewHomework = () => {
    navigate('/dashboard/addHomework');
    return false;
  };

  const applyFilters = (name, value) => {
    const HWArr = [];
    if (name === "subject") {
      if (filterClass !== '' && value !== '') {
        homework.forEach((element, index) => {
          if (filterClass === element.ClassNumeric && value === element.SubjectName) {
            HWArr.push(element);
          }
          setHW(HWArr);
          setFinalHW(HWArr);
        });
      } else if (filterClass !== '') {
        homework.forEach((element, index) => {
          if (filterClass === element.ClassNumeric) {
            HWArr.push(element);
          }
          setHW(HWArr);
          setFinalHW(HWArr);
        });
      } else if (value !== '') {
        homework.forEach((element, index) => {
          if (value === element.SubjectName) {
            HWArr.push(element);
          }
          setHW(HWArr);
          setFinalHW(HWArr);
        });
      }
    } else if (name === "class") {
      if (value !== '' && filterSubject !== '') {
        homework.forEach((element, index) => {
          if (value === element.ClassNumeric && filterSubject === element.SubjectName) {
            HWArr.push(element);
          }
          setHW(HWArr);
          setFinalHW(HWArr);
        });
      } else if (value !== '') {
        homework.forEach((element, index) => {
          if (value === element.ClassNumeric) {
            HWArr.push(element);
          }
          setHW(HWArr);
          setFinalHW(HWArr);
        });
      } else if (filterSubject !== '') {
        homework.forEach((element, index) => {
          if (filterSubject === element.SubjectName) {
            HWArr.push(element);
          }
          setHW(HWArr);
          setFinalHW(HWArr);
        });
      }
    }
  }

  const handleChange = (event) => {
    if (event.target.name === "subject") {
      setFilterSubject(event.target.value);
    }
    else if (event.target.name === "class") {
      setFilterClass(event.target.value);
    }
    console.log(event.target.value);
    console.log(event.target.name);
    applyFilters(event.target.name, event.target.value);
    setCheckAll(true);
    setCheckDueToday(false);
  };
  return (
    <Page title="Homework | XPSOLV">

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} className={classes.borderbotmten} >
          <Grid container>
            <Grid item lg={6} xs={6} md={3} sm={6} className={classes.dotspace}>
              <Box component="h2" display="inline" className={classes.fontsizeheading}>Home<Box component="span" display="inline" className={classes.headingpart}>work</Box></Box>
            </Grid>
            <Grid item lg={6} xs={6} md={3} sm={6} className={classes.dotspace}>
              <Button variant="contained" style={{ fontWeight: '500', float: 'right', }} onClick={createNewHomework} >Create New Homework </Button>

            </Grid>
          </Grid>

        </Stack>
        <Stack mb={5} className={classes.borderbotmten}>
          <Typography variant="h6" className={classes.headingtenw2}>Additional <Box component="span" display="inline" className={classes.headingpart}>Filter</Box></Typography>
          <Grid container spacing={3} >

            <Grid item lg={3} xs={6} md={3} sm={6} className={classes.dotspace}>
              <FormLabel component="legend" className={classes.specing}>Class</FormLabel>
              <FormControl fullWidth>
                <Select
                  className={classes.borderchangetext}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={handleChange}
                  name="class"
                  value={filterClass}
                  defaultValue=""
                >
                  <MenuItem key='Class' value="">
                    <em className={classes.borderchangetext} value="">Select Class</em>
                  </MenuItem>
                  {classess?.map((option, index) => (
                    <MenuItem key={index} value={option.ClassNumeric} className={classes.borderchangetext}>
                      {option.ClassName}
                    </MenuItem>
                  ))}

                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={3} xs={6} md={3} sm={6} className={classes.dotspace}>
              <FormLabel component="legend" className={classes.specing}>Subject</FormLabel>
              <FormControl fullWidth>
                <Select
                  className={classes.borderchangetext}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={handleChange}
                  name="subject"
                  value={filterSubject}
                  defaultValue=""
                >
                  <MenuItem value="" key='Subject'>
                    <em className={classes.borderchangetext} value="">Select Subject</em>
                  </MenuItem>
                  {initialize?.standardSubjects?.map((option, index) => (
                    <MenuItem key={index} value={option.name} className={classes.borderchangetext}>
                      {option.name}
                    </MenuItem>
                  ))}

                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={3} xs={6} md={3} sm={6} className={classes.dotspace}>
              <Button variant="contained" className={classes.buttoncomon} onClick={() => clearFilterVal()}>Clear All</Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={12} xs={12} md={3} sm={6} className={classes.dotspace}>

              <Typography variant="h6" className={classes.headingtenw2}>Search
                <Box component="span" display="inline" className={classes.headingpart}>Results</Box></Typography>
            </Grid>
            <Grid item lg={2} xs={6} md={3} sm={6} className={classes.dotspace}>

              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  <FormControlLabel className={classes.labelradio} value="female" control={<Radio />} label="Due Today" checked={checkDueToday} onClick={(event) => dueDates(event, "Due Today")} />
                </RadioGroup>
              </FormControl>
            </Grid>
            {!isTeacher && <Grid item lg={2} xs={6} md={3} sm={6} className={classes.dotspace}>
              <FormControl>

                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  <FormControlLabel className={classes.labelradio} value="female" control={<Radio />} label="Recieve Today" checked={checkReceivedToday} onClick={(event) => dueDates(event, "Recieve Today")} />

                </RadioGroup>
              </FormControl>


            </Grid>}
            <Grid item lg={2} xs={6} md={3} sm={6} className={classes.dotspace}>
              <FormControl>

                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  <FormControlLabel className={classes.labelradio} value="female" control={<Radio />} label="All" checked={checkAll} onClick={(event) => dueDates(event, "All")} />

                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          {finalHW && finalHW?.map((item, index) =>
            <Grid key={index} container spacing={3} className={classes.marginalign}>
              <Grid item lg={5} xs={12} md={6} sm={7} className={classes.padtop0}>
                <div className={classes.stepsmoredetails}>
                  <Grid container spacing={1}>
                    <Grid item lg={4} xs={5} md={5} sm={6} /* className={classes.paddmain} style={{position:'relative'}} */>
                      <Stack justifyContent='center' marginTop={3} width={1}>
                        {/* <Box mt={3} border={1}> */}
                        {item.SubjectName === 'Mathematics' &&
                          <Typography variant="p" className={classes.mathLabel}>
                            {item.SubjectName}
                          </Typography>
                        }
                        {item.SubjectName === 'Science' &&
                          <Typography variant="p" className={classes.scienceLabel}>
                            {item.SubjectName}
                          </Typography>
                        }
                        {item.SubjectName === 'Social Science' &&
                          <Typography variant="p" className={classes.socialScienceLabel}>
                            {item.SubjectName}
                          </Typography>
                        }                        
                      </Stack>
                      <Stack justifyContent='center' width={1}>                        
                        <Typography variant="p">
                          <img src="/static/mock-images/homework/homework.png" alt="imgtest" /* className={classes.imgplan2323} */ />
                        </Typography>                        
                      </Stack>
                   
                    </Grid>
                    <Grid item lg={8} xs={7} md={7} sm={6} className={classes.paddmain}>

                      <Grid item /* lg={4} xs={6} md={3} sm={6} */ className={classes.paddmain}>
                        <Typography variant="p" className={classes.datesteps}>
                          {item.PublishDate}
                        </Typography>
                      </Grid>
                      
                      <Grid item /* lg={6} xs={6} md={6} sm={6} */ className={classes.padtop0}>
                          &nbsp;
                        </Grid>

                        <Grid item /* lg={6} xs={6} md={6} sm={6} */ className={classes.padtop0}>
                          <Typography variant="p" className={classes.themeheading}>
                            Class
                          </Typography>
                        </Grid>
                        <Grid item /* lg={6} xs={6} md={6} sm={6} */ className={classes.padtop0}>
                          <Typography variant="p" className={classes.headingtenw4}>
                            {item.ClassNumeric}th
                          </Typography>
                        </Grid>

                        <Grid item /* lg={6} xs={6} md={6} sm={6} */ className={classes.padtop0}>
                          <Typography variant="p" className={classes.themeheading}>
                            Section
                          </Typography>
                        </Grid>
                        <Grid item /* lg={6} xs={6} md={6} sm={6} */ className={classes.padtop0}>
                          <Typography variant="p" className={classes.headingtenw4}>
                            {item.SectionList.replace(",", ", ")}
                          </Typography>
                        </Grid>

                        <Grid item /* lg={12} xs={12} md={3} sm={6} */ className={classes.padtop0}>
                          <Typography variant="p" className={classes.themeheading}>
                            Submission Date
                          </Typography>
                        </Grid>
                        <Grid item /* lg={12} xs={12} md={3} sm={6} */ className={classes.padtop0}>
                          <Typography variant="p" className={classes.headingtenw4}>
                            {item.SubmissionDate}
                          </Typography>
                        </Grid>                   

                    </Grid>

                    <Grid item lg={12} xs={12} md={12} sm={12}>
                      <Divider />
                    </Grid>

                    <Grid item lg={12} xs={12} md={12} sm={12} className={classes.padtop0}>
                      <Typography variant="p" className={classes.themeheading}>
                        Chapters
                      </Typography>
                    </Grid>
                    <Grid item lg={12} xs={12} md={12} sm={12} className={classes.padtop0}>
                      {item.Chapter && item.Chapter.split(",")?.map((chpt, index) =>
                        <Box key={index} component="p" display="inline" className={classes.chipsmain}>{chpt.replace("|", "-")}</Box>
                      )}
                    </Grid>
                    
                    <Grid item lg={12} xs={12} md={12} sm={12} marginBottom={2}>
                      <Divider />
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item lg={6} xs={4} md={6} sm={6} className={classes.padtop02}>
                        <Tooltip title="Unread Comment">
                          <Typography variant="p" className={classes.headingtenw4} style={{ display: '-webkit-inline-box', }}>
                            <img src="/static/mock-images/avatars/question.png" alt="imgtest" className={classes.imgplan} />
                            <Box component="span" display="inline" className={classes.headingpart}>   ({item.UnreadComment})</Box>
                          </Typography>
                        </Tooltip>
                      </Grid>
                      <Grid item lg={6} xs={8} md={6} sm={6} className={classes.padtop03}>

                        <Link underline="none" className={classes.fontmain} onClick={() => showDetails(item.idHW)}>
                          Details
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          )}
        </Stack>
        <>
          <Dialog
            className={classes.chaptersdiv}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div className={classes.headingchapter}>
              <DialogTitle id="alert-dialog-title" className={classes.paddzero}>
                Chapters
              </DialogTitle>
              <DialogActions className={classes.closebtn}>
                <Button onClick={handleClose}><img src="/static/mock-images/avatars/preview.png" alt="imgtest" className={classes.imgcross} /></Button>
              </DialogActions>
            </div>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <ul className={classes.ulmainpoint} >

                  {chapters && chapters?.map((item, index) =>
                    <li key={index} className={classes.ullicontent}><Chip label={item.ChapterName} variant="outlined" />
                    </li>)}

                </ul>
              </DialogContentText>
            </DialogContent>

          </Dialog>
        </>
      </Container>
      <Loader isLoader={showLoder} showHide={setShowLoader} />
    </Page>
  );
}
