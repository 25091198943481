import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material
import {
  Box, Button, Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia, Container, Grid, Stack, Tooltip, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
// components
import Page from '../components/Page';
import { TestApi } from '../service/index';
import HSeparator from '../theme/Separator';



const getIcon = (name) => <Icon color="#007c7c" icon={name} width={22} height={22} />;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  fontsizeheading: {
    fontSize: 16,
  },
  paper: {
    padding: theme.spacing(2)
  },
  cardcontent: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
    paddingBottom: '0px !important',
  },
  peraclass2: {
    //  width: "202px",
    //  width:'auto',
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginBottom: '5px',
    //  whiteSpace: "nowrap"
  },
  peraclass: {
    fontSize: 13,
    paddingBottom: 10,
    fontWeight: 600,
    color: '#00007e',
  },
  emailphone: {
    fontSize: 13,
    fontWeight: 500,
    color: '#000000',
    fontFamily: 'Poppins',
    marginTop: -5,
    lineBreak: 'anywhere',
    marginLeft: 10,
    [theme.breakpoints.up('md')]: {
      lineBreak: 'auto',
    },
  },
  headingfont: {
    fontSize: 16,
    fontWeight: 600,
  },
  btntext: {
    fontSize: 14,
    fontWeight: 500,
    padding: '0 9px',
    background: '#007c7c',
    color: '#ffffff',
    marginTop: '12px',
    marginBottom: '5px',
    "&:hover": {
      background: '#007c7c',
    }
  },
  textcenter: {
    textAlign: 'left',
    lineHeight: 0,
  },
  headingfive: {
    display: 'flex',
    marginBottom: 26,
  },
  perafive: {
    color: '#929292',
    fontWeight: 500,
  },
  cardpadding: {
    paddingLeft: 0,
    paddingBottom: 10,
  },
  cardborder: {
    border: '1px solid #c8c8c8',
    "&:hover": {

      backgroundSize: 'cover',
      background: 'linear-gradient(to right, #edfbf4 15%, #d4ffe9 94%)',
      borderColor: '#fff',
    }
  },
  centertext: {
    textAlign: 'center',
    fontSize: 24,
  },
  centertextside: {
    textAlign: 'left',
    fontSize: 24,
  },
  btnmain: {
    fontWeight: 500,
    marginTop: 18,
  },
  closebtn: {
    position: 'absolute',
    right: 0,
    top: -12,
    fontWeight: 700,
  },
  textcolor: {
    color: '#007c7c',
  },
  textcolor2: {
    color: '#999',
    fontWeight: 500,
  },

  plusimg: {
    width: 29,
    marginTop: '-4px',
  },
  textmainid: {
    fontSize: 14,
  },
  textright: {
    textAlign: 'right',
  },
  mainbtn: {
    background: "#effff7",
    borderTop: "1px solid #d3ffe9"
  },
  h4heaidng: {
    textAlign: 'left',
    marginBottom: "5px",
    fontSize: "15px",
  },
  h4heaidng2: {
    textAlign: 'left',
    marginBottom: "5px",
    marginTop: "20px",
    fontSize: "15px",
  },
  colorreport: {
    color: '#007c7c',
  },
  schoolheading: {
    borderBottom: '1px solid #ececec',
    paddingBottom: 15,
    color: '#00007e',
    fontSize: 13,
    fontWeight: '600',
  },
  mtop: {
    marginTop: 33,
    borderTop: '1px solid #ececec',
  },
  imgplan333: {
    width: 15,
    float: 'left',
    marginTop: 5,
    marginRight: 11,
    [theme.breakpoints.up('sm')]: {
      width: 15,
    },
  },
  accountsummary: {
    borderBottom: '1px solid #c9c9c9',
    paddingTop: 18,
    paddingLeft: 0,
    marginBottom: 22,
  },
  summaryaccout: {
    borderBottom: '1px solid #c9c9c9',
    padding: 10,
    paddingTop: 0,
    paddingLeft: 0,
    marginBottom: 0,
    width: 'auto',
    fontSize: 16,
    [theme.breakpoints.up('sm')]: {
      width: '978px',
    },
  },

  amount: {
    fontSize: 16,
    color: "#000",
    textAlign: "right"
  },
  btnmargin: {
    marginBottom: "21px",
    textAlign: "center",
    paddingTop: "7px !important",

  },
  amount2: {
    fontSize: 14,
    color: "#000",
    fontWeight: 500,
  },
  btnamount: {
    background: "#000",
    marginTop: "-5px",

  },
  colorwhite: {
    color: "#",
    fontWeight: 600,
  },
  margin000: {
    margin: "0 30px",
  },
  bordertable: {
    border: "1px solid #e8e8e8",
    marginBottom: 24,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  colorwhite2: {
    color: "#000",
  },
  colorwhite3: {
    color: "#000",
    fontSize: 16,
  },

  showtoggle: {
    background: "#fff",
    padding: "4px 16px",
    fontWeight: 500,
    color: "#fff",
    border: 0,
    borderRadius: 8,
    float: "right",
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  imgplan2: {
    width: 15,
    [theme.breakpoints.up('sm')]: {
      width: 15,
    },
  },
  addamountbtn: {
    background: '#007c7c',
    padding: "6px",
    fontSize: "14px",
    color: "#fff",
    borderRadius: "6px",
    textDecoration: "none",

  },
  ruppeicon: {
    position: "relative",
    top: "7px",
    width: "17px"
  },
  cardtable: {
    margin: '0 30px',
  },
  cardmain: {
    padding: "13px",
    margin: '10px 0 15px 15px',
    marginBottom: 10,
  },
  cardmain2: {
    margin: '0px 0px',
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  }

}));

export default function DashboardApp(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [userMobile, setUserMobile] = useState('')
  const [userDetails, setUserDetails] = useState([]);
  const [sumAddedInfo, setSumAddedInfo] = useState([]);
  const [sumConsumedInfo, setSumConsumedInfo] = useState([]);
  const [open, setOpen] = useState(false);
  const [openTest, setOpenTest] = useState(false);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [fullWidth, setFullWidth] = useState(true);
  const [selectedUserDetails, setSelectedUserDetails] = useState();
  const [isTeacher, setIsTeacher] = useState(false);
  const [isShow, setisShow] = useState(false);
  const [isShow2, setisShow2] = useState(false);
//  const [readMore,setReadMore]=useState(false);

  useEffect(() => {
    getProfileInfo();
    getUserDetails();
  }, []);

  const getProfileInfo = () => {
    try {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      const idStudentSchool = userInfo[0]?.StudentInfo.map(x => x.idStudentSchool).join(",");
      const reqsBody = {
        "mobilenum": userInfo[0].Mobile,
        "idStudentSchoolsc": idStudentSchool,
      };
      TestApi.getProfileinfo(reqsBody).then(
        (resp) => {
          if (resp.status === 200) {
            if (resp.data.status === 200) {
              localStorage.setItem("token", resp.data.JWTToken);
              localStorage.setItem("userInfo", JSON.stringify(resp.data.data));
            }
            else {
              alert(resp.data.message);
            }
          }
        }
      );
    }
    catch (error) {
      //   setShowLoader(false);
    }
  }

  const getUserDetails = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log("userInfo", userInfo);
    setUserDetails(userInfo);
    setUserMobile(userInfo[0]?.Mobile);
    if (userInfo[0]?.SumAddedInfo) {
      let isBlankExist = false;
      if (userInfo[0]?.SumAddedInfo.length === 1) {
        if ((userInfo[0]?.SumAddedInfo[0]?.EntryDT) === "" && (userInfo[0]?.SumAddedInfo[0]?.TranID) === "" && (userInfo[0]?.SumAddedInfo[0]?.TransactionType) === "" && (userInfo[0]?.SumAddedInfo[0]?.Amount) === "") {
          isBlankExist = true;
        }
      }
      if (!isBlankExist)
        setSumAddedInfo(userInfo[0]?.SumAddedInfo);
    }
    if (userInfo[0]?.SumConsumedInfo) {
      let isBlank = false;
      if (userInfo[0]?.SumConsumedInfo.length === 1) {
        if ((userInfo[0]?.SumConsumedInfo[0]?.EntryDT) === "" && (userInfo[0]?.SumConsumedInfo[0]?.GUQPID) === "" && (userInfo[0]?.SumConsumedInfo[0]?.Amount) === "") {
          isBlank = true;
        }
      }
      if (!isBlank)
        setSumConsumedInfo(userInfo[0]?.SumConsumedInfo);
    }
    if (userInfo[0]?.StudentInfo && userInfo[0].StudentInfo.length === 1 && userInfo[0].StudentInfo[0]?.role === 'Teacher') {
      setIsTeacher(true);
    }
  }
  const handelOrderNewTest = (item) => {
    if (item && item?.idStudent && item?.ClassNumeric) {
      navigate('/dashboard/plan-test', { replace: true, state: item });
      setSelectedUserDetails(item);
      setOpenTest(true);
    }
    else {
      navigate('/dashboard/plan-test', { replace: true, state: item });
      setSelectedUserDetails(item);
      setOpenTest(true);
    }
  };

  const handleUpdateProfile = (item) => {
    setSelectedUserDetails(item);
    setOpen(true);
  };


  const showhide = () => {
    setisShow(!isShow);


    // setOpen(false);

  };
  const showhide2 = () => {
    setisShow2(!isShow2);
    // setOpen(false);

  };

  const handelUserGuide = () =>{   
      navigate('/dashboard/helpvideos', { replace: true });       
  }
  const handelUserGuideForTeacher = () =>{   
      navigate('/dashboard/helpvideosforteacher', { replace: true }); 
  }
  const handleClose = () => {
    setOpen(false);
    setOpenTest(false);
    getUserDetails();
  };
  return (
    <Page title="Dashboard | XPSOLV">

      {isTeacher && userDetails[0]?.StudentInfo?.map((item, index) => (<Container key={`${index}_Cont`}>
        <Typography variant="h4" component="h4" className={classes.h4heaidng} >
          Welcome <Box component="span" className={classes.colorreport} sx={{ display: 'inline' }}> {item?.Gender === 'M' ? 'Mr.' : 'Ms'} {item?.FirstName}&nbsp;{item?.LastName}</Box>
        </Typography>
        <Typography variant="p" component="p" className={classes.schoolheading} >
          {item?.school?.schoolName}
        </Typography>
        <Typography variant="h4" component="h4" className={classes.h4heaidng2} >
          Contact <Box component="span" className={classes.colorreport} sx={{ display: 'inline' }}>Info</Box>
        </Typography>
        <Grid container item spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="span" component="span" className={classes.schoolheading2} > <img src="/static/mock-images/avatars/call.png" alt="imgtest" className={classes.imgplan333} />
              +91 {userDetails[0]?.Mobile}
            </Typography>
          </Grid>      
          <Grid item xs={8} sm={12} md={12}>
            <Typography variant="p" component="p" className={classes.schoolheading2} >
              <img src="/static/mock-images/avatars/email.png" alt="imgtest" className={classes.imgplan333} />
              {item?.Email}
            </Typography>
          </Grid>

        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={12} className={classes.mtop}>
            <Button variant="contained" sx={{ fontWeight: 'medium' }} key={index} onClick={(e) => { handelOrderNewTest(item) }} >Plan New Test</Button>
          </Grid>
        </Grid>
        <div>&nbsp;</div>
          <HSeparator sx={{ marginTop: 5, marginBottom: 5 }} />
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12} className={classes.textcenter}>
              <Button variant="outlined" color='primary' sx={{ fontWeight: 'medium' }} onClick={(e) => { handelUserGuideForTeacher() }}>How to use Xpsolv?</Button>
            </Grid>                   
          </Grid>
        
      </Container>
      ))}
      {!isTeacher &&
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0}>
            <Typography variant="h4" gutterBottom className={classes.fontsizeheading}>
              STUDENTS <Box component="span" display="inline" className={classes.textcolor}>LIST</Box>
            </Typography>
          </Stack>
          {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0}>
          <Typography variant="h6" gutterBottom className={classes.textmainid}>
          <Box component="span" display="inline" className={classes.textcolor2}>User Profile</Box> @ {userMobile}
          </Typography>
          </Stack>        */}


          <HSeparator />

          <Grid container item spacing={3}>

            {userDetails[0]?.StudentInfo?.map((item, index) => (
              <Grid key={index} item lg={4} md={4} sm={6} xs={12}>
                <Card variant="outlined" sx={{ boxShadow: 0 }} key={index} className={classes.cardborder}>

                  <CardActionArea >
                    <Grid container item>
                      <Grid item lg={3} md={3} sm={3} xs={3}>
                        {item?.Gender ?
                          <CardMedia key={index} image={`/static/mock-images/avatars/${item?.Gender}.png`}
                            style={{ width: '82px', height: '192px', margin: 10, }}
                          />
                          :
                          <CardMedia key={index} image="/static/mock-images/avatars/demo.png"
                            style={{ width: '82px', height: '192px', margin: 10, marginLeft: 40 }}
                          />
                        }

                      </Grid>
                      <Grid item lg={9} md={3} sm={9} xs={9}>
                        <CardContent className={classes.cardcontent}>
                          <Typography variant="h4" className={classes.headingfont}>{item?.FirstName}&nbsp;{item?.LastName}&nbsp;
                            {item?.Gender ?
                              `(${item?.Gender})`
                              :
                              ""
                            }
                          </Typography>
                          <Tooltip title={item?.SchoolName} placement="top"><p className={classes.peraclass2}>{item?.SchoolName}</p></Tooltip>
                          <p className={classes.peraclass}>
                            {item?.ClassName ?
                              <>
                                {item?.ClassName}
                                <sup>{item.ClassNumeric === 1 ? 'st' : ""} {item.ClassNumeric === 2 ? 'nd' : ""} {item.ClassNumeric === 3 ? 'rd' : ""} {item.ClassNumeric > 3 ? 'th' : ""} </sup>
                              </>
                              :
                              ""
                            }</p>
                          <CardActions container="true" className={classes.cardpadding}>
                            <Grid container item>
                              <Grid item sm={1}>
                                <img src="/static/mock-images/avatars/email.png" alt="imgtest" className={classes.imgplan2} />
                              </Grid>
                              <Grid item sm={11}>
                                <Typography variant="subtitle1" className={classes.emailphone} >{item?.Email}</Typography>
                              </Grid>
                            </Grid>
                          </CardActions>

                          <CardActions container="true" className={classes.cardpadding}>
                            <Grid container item>
                              <Grid item sm={1} >
                                <img src="/static/mock-images/avatars/call.png" alt="imgtest" className={classes.imgplan2} />
                              </Grid>
                              <Grid item sm={11}>
                                <Typography variant="subtitle1" className={classes.emailphone}>{userDetails[0]?.Mobile}</Typography>
                              </Grid>
                            </Grid>
                          </CardActions>
                          <Grid container item>
                            <Grid item lg={12} md={6} sm={12} xs={12} className={classes.textcenter}>
                              <Button variant="button" sx={{ fontWeight: 'medium' }} className={classes.btntext} onClick={(e) => { handelOrderNewTest(item) }}>Plan New Test</Button>
                            </Grid>                   
                          </Grid>
                        </CardContent>
                      </Grid>
                    </Grid>
                  </CardActionArea>

                </Card>
              </Grid>

            )
            )}

          </Grid>
          <div>&nbsp;</div>
          <HSeparator sx={{ marginTop: 5, marginBottom: 5 }} />
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12} className={classes.textcenter}>
              <Button variant="outlined" color='primary' sx={{ fontWeight: 'medium' }} onClick={(e) => { handelUserGuide() }}>How to use Xpsolv?</Button>
            </Grid>                   
          </Grid>
        </Container>
      }    
    </Page>
  );
}




