import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useState } from 'react';
import HSeparator from '../theme/Separator';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: 345,
    },
    textcolor: {
        color: '#007c7c',
    },
    textcolor2: {
        color: '#999',
        fontWeight: 500,
    },
    accountsummary: {
        borderBottom: '1px solid #c9c9c9',
        paddingTop: 18,
        paddingLeft: 0,
        marginBottom: 22,
    },
    summaryaccout: {
        borderBottom: '1px solid #c9c9c9',
        padding: 10,
        paddingTop: 0,
        paddingLeft: 0,
        marginBottom: 0,
        width: 'auto',
        fontSize: 16,
        [theme.breakpoints.up('sm')]: {
            width: '978px',
        },
    },

}));

export default function HelpVideos() {

    const classes = useStyles();
    const [readMore,setReadMore]=useState(false);  

    return (
        <div onContextMenu={(e)=> e.preventDefault()} style={{ marginTop: 1, padding: ' 0 50px', }}>
            {/* <Link to="/login" component={RouterLink} style={{ display: "flex", width: "75px", padding: "9px 7px 0 6px", lineHeight: "24px", background: "#089293", border: "none", color: "#fff", borderRadius: 6, marginBottom: "14px" }}> <img src="/static/mock-images/avatars/angle-left-free-icon-font.png" alt="imgtest" style={{ width: 15, marginTop: "3px", marginRight: "6px", marginBottom: "11px" }} />back</Link> */}
            {/* <h2>Support & Help</h2> */}
            <Typography variant="h4" gutterBottom className={classes.summaryaccout}>
                Help <Box component="span" display="inline" className={classes.textcolor}>Videos</Box>
            </Typography>

            {/*  <h3 style={{ padding: ' 15px 0', fontSize: 16, color: '#797D7F'}}>Last updated on Dec 14, 2022</h3> */}
            
            <HSeparator/>
            
            <div>                
                <Typography variant='subtitle2' sx={{ mb:2 }} color="text.secondary">
                    Videos to know - How to use Xpsolv?
                </Typography>
            </div>

            <Grid container spacing={2}>          
                <Grid item md={9} lg={9}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', border: '0.5px solid #469293', borderRadius: 1.5, padding: 0.2 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>
                            <Typography variant='button'>Create Test Paper</Typography>
                        </Box>
                        <Box>
                            <video width="100%" /* max-width="320" height="240" */ controls muted controlsList="nodownload">{/* autoPlay */}
                                <source src="/static/media/student/CreateTestPaperV1.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </Box>
                    </Box>
                </Grid>

                <Grid item md={9} lg={9}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', border: '0.5px solid #469293', borderRadius: 1.5, padding: 0.2 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>
                            <Typography variant='button'>Check answers for a test paper</Typography>
                        </Box>
                        <Box>
                            <video width="100%" /* max-width="320" height="240" */ controls muted controlsList="nodownload">
                                <source src="/static/media/student/ReviewTestPaperFromCheckAnswersV1.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </Box>
                    </Box>
                    <Box>&nbsp;</Box>
                </Grid>

                <Grid item>
                    {/* Show more OR less videos */}
                    {readMore && <div>
                        <Grid container spacing={2}>
                            <Grid item md={9} lg={9}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', border: '0.5px solid #469293', borderRadius: 1.5, padding: 0.2 }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>
                                        <Typography variant='button'>Test History & Check Answers</Typography>
                                    </Box>
                                    <Box>
                                        <video width="100%" /* max-width="320" height="240" */ controls muted controlsList="nodownload">
                                            <source src="/static/media/student/ReviewTestPaperFromHistoryV1.mp4" type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item md={9} lg={9}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', border: '0.5px solid #469293', borderRadius: 1.5, padding: 0.2 }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>
                                        <Typography variant='button'>Review Performance</Typography>
                                    </Box>
                                    <Box>
                                        <video width="100%" /* max-width="320" height="240" */ controls muted controlsList="nodownload">
                                            <source src="/static/media/student/PerfromanceReportV1.mp4" type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item md={9} lg={9}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', border: '0.5px solid #469293', borderRadius: 1.5, padding: 0.2 }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>
                                        <Typography variant='button'>Test Paper shared by Teacher</Typography>
                                    </Box>
                                    <Box>
                                        <video width="100%" /* max-width="320" height="240" */ controls muted controlsList="nodownload">
                                            <source src="/static/media/student/TestSharedByTeacherV1.mp4" type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item md={9} lg={9}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', border: '0.5px solid #469293', borderRadius: 1.5, padding: 0.2 }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>
                                        <Typography variant='button'>View Homework</Typography>
                                    </Box>
                                    <Box>
                                        <video width="100%" /* max-width="320" height="240" */ controls muted controlsList="nodownload">
                                            <source src="/static/media/student/ViewHomeWorkV1.mp4" type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item md={9} lg={9}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', border: '0.5px solid #469293', borderRadius: 1.5, padding: 0.2 }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>
                                        <Typography variant='button'>Xpsolv Support & Help</Typography>
                                    </Box>
                                    <Box>
                                        <video width="100%" /* max-width="320" height="240" */ controls muted controlsList="nodownload">
                                            <source src="/static/media/student/XpsolvSupportV1.mp4" type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </div>} {/* closing conditional rendering of support videos */}
                </Grid>

                <Grid item sm={12} xs={12} md={12} lg={12}>
                    <div>
                        <button onClick={() => { setReadMore(!readMore) }}>{readMore ? 'Show Less << ' : 'Show More >> '}</button>
                    </div>
                </Grid>

            </Grid>

            <div>
                &nbsp;{/* <p style={{ fontSize: 14, }}>&nbsp;</p> */}
            </div>

        </div>
    );
}