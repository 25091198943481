import { Grid, Link, Paper, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';


export default function AboutUs() {

    return (
        <div style={{ marginTop: 120, padding: ' 0 50px', }}>
            <Link to="/login" component={RouterLink} style={{ display: "flex", width: "75px", padding: "9px 7px 0 6px", lineHeight: "24px", background: "#089293", border: "none", color: "#fff", borderRadius: 6, marginBottom: "14px" }}> <img src="/static/mock-images/avatars/angle-left-free-icon-font.png" alt="imgtest" style={{ width: 15, marginTop: "3px", marginRight: "6px", marginBottom: "11px" }} />back</Link>
            <h2>About Us</h2>

            <h3 style={{ padding: ' 15px 0', fontSize: 14, color: '#797D7F'}}>Last updated on Dec 14, 2022</h3>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Paper elevation={2} sx={{ padding: 1 }}>                       
                       
                        <Typography sx={{ mb: 1.5, fontSize: 14, }} color="text.primary">
                            <Typography variant='button'>Xpsolv</Typography> is an innovative testing platform designed & built to help parents and teachers. The platform reduces efforts involved in creating practice test papers as it does have a large database of quality questions & their answers and uses the database to create test papers on demand.  Platform has intelligence to pick up different type of questions to build a test paper in patterns mimicking the practice followed by schools.  
                        </Typography>                        

                        <Typography sx={{ mb: 2, fontSize: 14 }} color="text.primary">
                            Parents & Teachers, who are authorized to use the platform, can provide minimum inputs like class, subject, format (subjective vs multiple choice questions) and create the test papers. Teachers are enabled to share the test papers with students from their respective class as a practice test of homework.  
                        </Typography>
                        
                        <Typography sx={{ mb: 1.5, fontSize: 14 }} color="text.primary">
                            Only authorized users are allowed to access the application. Xpsolv will register the users in collaboration with school and once registered, they can start using the application. Application is available as web portal and a mobile app on Google Play Store. Users can access the web portal through browsers and install mobile app from Google Play Store.
                        </Typography>

                    </Paper>
                </Grid>             
         
            </Grid>
           
            <div>
                &nbsp;{/* <p style={{ fontSize: 14, }}>&nbsp;</p> */}
            </div>

        </div>
    );
}