import { Box, Card, CardContent, Grid, Link, Paper, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';


export default function Pricing() {

    return (
        <div style={{ marginTop: 120, padding: ' 0 50px', }}>
            <Link to="/login" component={RouterLink} style={{ display: "flex", width: "75px", padding: "9px 7px 0 6px", lineHeight: "24px", background: "#089293", border: "none", color: "#fff", borderRadius: 6, marginBottom: "14px" }}> <img src="/static/mock-images/avatars/angle-left-free-icon-font.png" alt="imgtest" style={{ width: 15, marginTop: "3px", marginRight: "6px", marginBottom: "11px" }} />back</Link>
            <h2>Pricing</h2>

            <h3 style={{ padding: ' 15px 0', fontSize: 12, color: '#797D7F' }}>Last updated on Dec 15, 2022</h3>
           
            <Paper elevation={2} sx={{ fontSize: 14, padding: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={0} sm={0} md={1.5} lg={1.5}>&nbsp;</Grid>
                    <Grid item xs={12} sm={4} md={3} lg={3}>
                        <Card variant='outlined'>
                            <CardContent>
                                <Box sx={{ width: '100%', height: '250px' }}>
                                    
                                    <Box sx={{display: 'flex', alignItems:'center', justifyContent: 'center', mb:1.5}}>
                                    <Typography variant="h6" color="text.secondary" gutterBottom>
                                        Create Test Paper
                                    </Typography>
                                    </Box>

                                    <Box sx={{height:35, display: 'flex', alignItems:'center', justifyContent: 'center', border: '0.5px solid lightgrey',borderRadius:'5px', color:'#469293'}}>
                                        <Typography variant="subtitle2">
                                            3.99 INR
                                        </Typography>
                                    </Box>

                                    <Typography variant="body2">
                                        &nbsp;
                                    </Typography>

                                    <Box sx={{display: 'flex', alignItems:'center', justifyContent: 'center'}}>
                                    <Typography variant="body2" sx={{ fontSize: 12 }} color="text.secondary">
                                        Creating Test Paper through Xpsolv is chargeable. User will be charged 3.99 INR per paper.
                                    </Typography>
                                    </Box>
                                </Box>

                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={3}>
                        <Card variant='outlined'>
                            <CardContent>
                                <Box sx={{ width: '100%', height: '250px'}}>
                                    <Box sx={{display: 'flex', alignItems:'center', justifyContent: 'center', mb:1.5}}>
                                        <Typography variant="h6" color="text.secondary" gutterBottom>
                                            Home Work Access
                                        </Typography>
                                    </Box>

                                    <Box sx={{height:35, display: 'flex', alignItems:'center', justifyContent: 'center', border: '0.5px solid lightgrey',borderRadius:'5px', color:'#469293'}}>
                                        <Typography variant="subtitle2">
                                            1.99 INR
                                        </Typography>
                                    </Box>
                                    <Typography variant="body2">
                                        &nbsp;
                                    </Typography>
                                    
                                    <Box sx={{display: 'flex', alignItems:'center', justifyContent: 'center'}}>
                                        <Typography variant="body2" sx={{ fontSize: 12 }} color="text.secondary">
                                            Accessing Home Work shared by teacher through Xpsolv is chargeable. User will be charged 1.99 INR per Home Work.
                                        </Typography>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={4} md={3} lg={3}>
                        <Card variant='outlined'>
                            <CardContent>
                                <Box sx={{ width: '100%', height: '250px' }}>
                                    <Box sx={{display: 'flex', alignItems:'center', justifyContent: 'center', mb:1.5}}>
                                        <Typography variant="h6" color="text.secondary" gutterBottom>
                                            Access Shared Test
                                        </Typography>
                                    </Box>

                                    <Box sx={{height:35, display: 'flex', alignItems:'center', justifyContent: 'center', border: '0.5px solid lightgrey',borderRadius:'5px', color:'#469293'}}>
                                        <Typography variant="subtitle2">
                                            2.99 INR
                                        </Typography>
                                    </Box>
                                    <Typography variant="body2">
                                        &nbsp;
                                    </Typography>

                                    <Box sx={{display: 'flex', alignItems:'center', justifyContent: 'center'}}>
                                        <Typography variant="body2" sx={{ fontSize: 12 }} color="text.secondary">
                                            Accessing Test Paper shared by teacher through Xpsolv is chargeable. User will be charged 2.99 INR per Test Paper.
                                        </Typography>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid item xs={0} sm={0} md={1.5} lg={1.5}>&nbsp;</Grid>
            </Paper>
        </div>
    );
}