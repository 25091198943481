import { Form, FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
// material
import { Stack, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';

import { toast } from 'react-toastify';
 
// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';


// ----------------------------------------------------------------------
import { AuthApi } from '../../../service/index';
import { OtpForm } from '../../forms/index';

toast.configure();

export default function RegisterForm() {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState();
  const [isSubmit, setIsSumbit] = useState(false);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    mobile: Yup.string()
      .min(10, 'Too Short!')
      .max(12, 'Too Long!')
      .required('Mobile is required')
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      mobile: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: (data) => {
      setUserInfo(data);
      generateOTP();
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const generateOTP = () => {
    const data = {
      "mobile": formik.values.mobile
    };
    AuthApi.generateOTP(data).then(resp => {
      console.log("generateOTP", resp);
      if (resp.data.status === 200) {
        setIsSumbit(true);
        console.log("OTP generated");
        toast.success('OTP generated');
      }
      else {
        toast.warning('OTP not generated');
        console.log("OTP not generated");
      }
    });
  }


  return (
    <div>
      {(!isSubmit &&
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  label="First name"
                  {...getFieldProps('firstName')}
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />

                <TextField
                  fullWidth
                  label="Last name"
                  {...getFieldProps('lastName')}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Stack>

              <TextField
                fullWidth
                type="email"
                label="Email address"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />

              <TextField
                inputProps={{
                  maxLength: 11,
                }}
                fullWidth
                type="text"
                label="Mobile"
                {...getFieldProps('mobile')}
                error={Boolean(touched.mobile && errors.mobile)}
                helperText={touched.mobile && errors.mobile}
              />
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Register
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      )}
      {/* Otp Form */}
      {(isSubmit && <OtpForm userInfo={userInfo} />)}

    </div>

  );
}
