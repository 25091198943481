import * as Yup from 'yup';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';

// material
import {
  Card,
  Paper,
  Stack,
  Button,
  Container,
  Typography,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Chip,
  OutlinedInput,
  Box,
  Grid,
  Stepper,
  Step,
  StepLabel,
  FormLabel,
  FormGroup,
  Checkbox,
  ButtonGroup
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import { LoadingButton } from '@material-ui/lab';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import { TestApi, utilApi } from '../service/index';
import HSeparator from '../theme/Separator';

const steps = [
  'Primary Information',
  'Summary & Breakup',
  'Review & Fin',
];


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  centerwidth: {
    margin: 'auto',
  },
  steppershaodw: {
    boxShadow: '1px 1px 10px #000',
  },
  textcolor: {
    color: '#ffffff',
  },
  textcolor2: {
    color: '#00AB55',
  },
  textcneter: {
    textAlign: 'left',
    marginBottom: 18,
  },
  tenalign: {
    fontWeight: 500,
    padding: '16px 72px',
    borderRadius: 4,
    marginTop: '23px',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, chapters, theme) {
  return {
    fontWeight:
      chapters.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function PlanTest() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { state } = useLocation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState('');
  const [showUser, setShowUser] = useState({
    firstName: '', lastName: '', className: '', board: ''
  })
  const [chapterList, setChapterList] = useState([]);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [initialize, setInitialize] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [downloadId, setDownloadID] = useState('');
  const [chapters, setChapters] = useState([]);
  const [chaptersIds, setChaptersIds] = useState([]);

  useEffect(() => {
    if (state) {
      setSelectedUser(state);
      initializeTest(state.student.id);
    }
  }, []);



  const handleClose = () => {
    setOpen(false);
  };

  const TestSchema = Yup.object().shape({
    testSubject: Yup.string().required('Subject is required'),
    //   chaptersIds: Yup.string().required('Chapter is required'),
    format: Yup.string().required('Format is required'),
    level: Yup.string().required('Level is required')
  });

  const formik = useFormik({
    initialValues: {
      testSubject: "",
      //   chaptersIds: [],
      format: "",
      level: ""
    },
    validationSchema: TestSchema,
    onSubmit: (data) => {
      createTest(data);
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const initializeTest = (data) => {
    try {
      TestApi.initializeTest(data).then(
        (resp) => {
          if (resp.status === 200) {
            if (resp.data.status === 200) {
              setInitialize(resp.data.data);
            }
            else {
              alert(resp.data.message);
            }
          }
          else {
            alert(resp.message);
          }
        },
        (error) => { alert("Error in initializeTest"); }
      );
    }
    catch (error) {
      console.log("initializeTest Catch", error);
    }
  }

  const createTest = (data) => {

    try {
      // setOpen(true);
      const reqsBody = {
        "pFormatId": data.format.toString(),
        "idClassSubject": data.testSubject.toString(),
        "chapterIds": chaptersIds.toString(),
        "levelId": data.level.toString(),
        "idStudentSchool": selectedUser.id.toString(),
        "nonRepeatDuration": "-15"
      };
      console.log("reqsBody", reqsBody);

      TestApi.orderTest(reqsBody).then(
        (resp) => {
          if (resp.status === 200) {
            if (resp.data.status === 200) {
              downloadPDF(resp.data.data);
              setDownloadID(resp.data);
            }
            else {
              alert(resp.data.message);
            }
          }
          else {
            alert(resp.message);
          }
        },
        (error) => { alert("error in create test"); }
      );

      formik.resetForm();
    }
    catch (error) {
      console.log("create test Catch", error);
    }
  }
  const downloadPDF = (testId) => {
    try {
      if (testId) {
        TestApi.downloadTest(testId).then(
          (resp) => {
            if (resp.status === 200) {
              // Generate PDF
              utilApi.base64ToPDF(resp.data.data, "TestPaper.pdf");
              navigate('/dashboard/app', { replace: true });
            }
            else {
              alert(resp.message);
            }
          },
          (error) => { alert("Error in download pdf"); }
        );
      }
    }
    catch (error) {
      console.log("download pdf Catch", error);
    }
  }
  const handleChange = (event) => {
    if (initialize) {

      // eslint-disable-next-line array-callback-return
      initialize?.standardSubjects?.map((item) => {
        if (item.subject.id === event.target.value) {
          setChapterList(item.chapters);
          formik.setFieldValue("testSubject", event.target.value);
        }
      });
    }
  };
  const storeChapterIds = chaptersIds;
  const handleChangeChapter = (event, ids) => {
    const {
      target: { value },
    } = event;
    const name = event.target.innerText;
    // setChapters(
    //   // On autofill we get a the stringified value.
    //   typeof name === 'string' ? name.split(',') : name,
    // );
    setChaptersIds(
      // On autofill we get a the stringified value.

      typeof value === 'string' ? value.split(',') : value,
      // storeChapterIds.forEach(id => value.push(id)),

    );
  };


  return (
    <Page title="Plan Test | XPSOLV">

      <Grid container>
        <Grid item lg={12} className={classes.bgprofileinner}>
          <Typography variant="h4" gutterBottom className={classes.textcneter}>
            Terms of <Box component="span" display="inline" className={classes.textcolor2}>Services</Box>
          </Typography>

        </Grid>
         </Grid>
  <div >
  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.

  </div>


      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Assess Test</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Test generated successfully| Please proceed ...
          </DialogContentText>
          <div className={classes.root}>
            {/* <Button variant="contained" onClick={downloadPDF}>Download PDF</Button> */}
            <LoadingButton
              variant="contained"
              loading={isSubmitting}
              onClick={downloadPDF}
            >
              Download PDF
            </LoadingButton>
            <Button variant="contained" color="primary">
              Send Email
            </Button>
            <Button variant="contained" color="secondary">
              Start Online
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}
