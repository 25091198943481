import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { LoadCanvasTemplate, loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/styles';
import "../../css/style.css";
  
// material
import { Box, Button, Checkbox, Grid, Link, Stack, TextField, Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { AuthApi } from '../../../service/index';

// ----------------------------------------------------------------------
toast.configure()

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  // const [showOTP, setShowOTP] = useState('');
  const [showLoder, setShowLoader] = useState(false);
  const [showValidation, setshowValidation] = useState(false);
  const [showValidationText, setShowValidationText] = useState("");
  const [showPassValidation, setshowPassValidation] = useState(false);
  const [showPassValidationText, setShowPassValidationText] = useState("");
  const [showCaptchaValidation, setShowCaptchaValidation] = useState(false);
  const [showCaptchValidationText, setShowCaptchValidationText] = useState("");
  const [count, setCount] = useState(30);
  const [timer, setTimer] = useState(30);
  const [intervalId, setIntervalId] = useState(0);
  const [termandCondition, setTermAnsCondition] = useState(true);


  useEffect(() => {
    loadCaptchaEnginge(4,'white','black','numbers'); 
  }, []);


  const handleClick = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(0);
      setCount(29);

    }

    const newIntervalId = setInterval(() => {
      setCount(prevCount => prevCount - 1);
    }, 1000);
    setIntervalId(newIntervalId);
  };

  const LoginSchema = Yup.object().shape({
    mobilenum: Yup.string().required('Mobile is required'),
    otp: Yup.string().required('OTP is required')
  });





  const formik = useFormik({
    initialValues: {
      mobilenum: '',
      otp: '',
      captcha: ''
    },
    validationSchema: LoginSchema,
    onSubmit: (data) => {
      signIn(data);

    }
  });

  const useStyles = makeStyles((theme) => ({
    borderchangetext: {
      "& label.Mui-focused": {
        color: "#007c7c"
      },
      // focused color for input with variant='outlined'
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "#007c7c"
        }
      }
    },
    textrighte: {
      textAlign: 'right',
    },
    bggreen: {
      background: '#007c7c',
    },
    getotpbg: {
      color: '#007c7c',
      padding: "28px !important", position: "absolute", right: "0px", borderRadius: "0px 8px 8px 0px", fontWeight: "600"
    },
    footer: {
      position: "relative",
      textAlign: "center",
      textDecoration: "none",
      width: "100%",
      padding: "9px",
      borderRadius: "0",
      borderTop: '1px solid #dbdbdb',
      marginTop: 120,

    },
    copyright: {
      fontSize: 12,
    }



  }));
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const generateOTP = () => {
    setshowValidation(false);
    setShowValidationText("");
    setshowPassValidation(false);
    setShowPassValidationText("");
    setShowCaptchaValidation(false);
    setShowCaptchValidationText("");
    if (formik.values.mobilenum === "") {
      setshowValidation(true);
      setShowValidationText('Mobile is required');
    }
    else if (formik.values.mobilenum && formik.values.mobilenum.length < 9) {
      setshowValidation(true);
      setShowValidationText('Mobile No. should be atleast 10 digits.');
    }
    else {
      const data = {
        "mobilenum": formik.values.mobilenum
      };
      setShowLoader(true);
      AuthApi.generateOTP(data).then(resp => {
        setShowLoader(false);
        // eslint-disable-next-line no-debugger
        debugger;
        console.log("generateOTP", resp);
        if (resp.data) {
          if (resp.data.status === 200) {
            formik.setFieldValue('otp', resp.data.data)
            setCount(29);
            handleClick();
            formik.setFieldValue('captcha', "");
          }
          else {
            setshowValidation(true);
            setShowValidationText(resp.data.message);
          }
        }
        else {
          setshowValidation(true);
          setShowValidationText("student not found");
        }
      });
    }
  }

  const doSubmit = () => {
    if(formik.values.mobilenum === ""){
      setshowValidation(true);
      setShowValidationText('Mobile is required');
    }
    if(formik.values.captcha === ""){
      setShowCaptchaValidation(true);
      setShowCaptchValidationText('Captcha is required');
      return false;
    }
    if (validateCaptcha(formik.values.captcha) === true) {
      generateOTP();
      loadCaptchaEnginge(4,'white','black','numbers');
   //   document.getElementById('user_captcha_input').value = '';
    } else {
      setShowCaptchaValidation(true);
      setShowCaptchValidationText('Captcha Does Not Match');

    //  document.getElementById('user_captcha_input').value = '';
    }
}

  const signIn = (data) => {
    setShowLoader(true);
    setshowPassValidation(false);
    setShowPassValidationText("");
    try {
      AuthApi.signIn(data).then(
        (resp) => {
          setShowLoader(false);
          if (resp.status === 200) {
            console.log("signIn", resp);
            if (resp.data.status === 200) {
              formik.resetForm(); 
               if(resp.data.data[0].StudentInfo[0].role){
                localStorage.setItem("token", resp.data.JWTToken);
                localStorage.setItem("userInfo", JSON.stringify(resp.data.data));          
                navigate('/dashboard/app', { replace: true });
               }else{
                toast.info(`Role not found`, {
									position: toast.POSITION.TOP_RIGHT
								  });               }
              
            }
            else {
              formik.setSubmitting(false);
              // toast.warning('Invalid OTP')
              setshowPassValidation(true);
              setShowPassValidationText(resp.data.message);
            }

          }
          else {
            formik.setSubmitting(false);
            // toast.warning('Invalid OTP')
            setshowPassValidation(true);
            setShowPassValidationText("Invalid OTP");
          }
        },
        (error) => { setShowLoader(false); formik.setSubmitting(false); toast.warning('Invalid OTP') }
      );
    }
    catch (error) {
      setShowLoader(false);
      console.log("Catch")
    }
  }
  const classes = useStyles();
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>

          <TextField className={classes.borderchangetext}
            fullWidth
            autoComplete="username"
            type="text"
            label="Mobile Number"
            {...getFieldProps('mobilenum')}
            error={(Boolean(touched.mobilenum && errors.mobilenum)) || showValidation}
            helperText={(touched.mobilenum && errors.mobilenum) || showValidationText}
          /*     InputProps={{
                endAdornment:(
                  <InputAdornment position="end">
                    <Button onClick={generateOTP} size="large" variant="text">Get OTP</Button>
                  </InputAdornment>
                )
              }} 
        
         <Link href="#" onClick={generateOTP} className={classes.textrighte}>
            Send OTP
          </Link>
        */

          />
    <Grid container>
    <Grid item xs={4} md={3}>
    <Typography style={{alignItems:'center',height:54,fontSize: "20px",textAlign:'center'}}><LoadCanvasTemplate   reloadColor="#007c7c" reloadText="Refresh" /> </Typography>
   </Grid>
  <Grid item xs={8} md={9} style={{position:'relative'}}>
    <TextField id="outlined-basic" label="Enter Captcha" 
    variant="outlined" style={{borderRadius:'8px 0 0 8px',width: "100%"}}
    {...getFieldProps('captcha')} 
    error={(Boolean(touched.captcha && errors.captcha))|| showCaptchaValidation}
    helperText={(touched.captcha && errors.captcha)|| showCaptchValidationText} />
    
    {count >= timer &&
           <Button variant="contained" style={{padding: "16px 16px",position: "absolute", right: "0", borderRadius: "0 8px 8px 0", fontWeight: "600"}} onClick={doSubmit} >Get OTP</Button>
       }
       <>
    {count < 0 &&
           <Button className={classes.getotpbg} size="large" variant="text" onClick={doSubmit}>Resend OTP</Button>
       }
       </>
        <>
    {count >= 0 && count < timer &&
           <Button className={classes.getotpbg} size="large" variant="text">{count} Sec.</Button>
       }
         </>
  </Grid>

</Grid>



          <TextField className={classes.borderchangetext}
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'text'}
            label="Enter OTP"
            {...getFieldProps('otp')}
            // InputProps={{
            //   endAdornment: (
            //     <InputAdornment position="end">
            //       <IconButton onClick={handleShowPassword} edge="end">
            //         <Icon icon={showPassword ? eyeFill : eyeOffFill} />
            //       </IconButton>
            //     </InputAdornment>
            //   ),
            //   maxLength: 6
            // }}
            error={(Boolean(touched.otp && errors.otp)) || showPassValidation}
            helperText={(touched.otp && errors.otp) || showPassValidationText}
          />
        </Stack>
        <br />
        <Typography variant="span" component="span" className={classes.schoolheading2} style={{fontSize:11,}}>     
        <Checkbox defaultChecked style={{width:13,marginRight:4,}} onChange={e => {
          setTermAnsCondition(e.target.checked);
        }}/> I agree with 
        <Link to="/termsandconditions" component={RouterLink}
          style={{ marginTop: "0", textAlign: "left", fontSize: 11, marginLeft:4, marginRight:4}}
        >
          T&C 
        </Link>
        and
        <Link to="/privacypolicy" component={RouterLink}
          style={{ marginTop: "0", textAlign: "left", fontSize: 11, marginLeft:4,}}
        >
          Privacy Policy
        </Link>
        </Typography>
        <LoadingButton
          spacing={3}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          className={classes.bggreen}
          disabled={!termandCondition}
        >
          Login
        </LoadingButton>
      
        <div className={classes.footer}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item><Box className={classes.copyright} >©2022 XPSOLV</Box></Grid>
            <Grid item><Box style={{ margin: 'auto' }} className={classes.copyright}><Link to="/aboutus" component={RouterLink}>About Us</Link></Box></Grid> 
            <Grid item><Box style={{ margin: 'auto' }} className={classes.copyright}> <Link to="/contactus" component={RouterLink}>Contact Us</Link></Box></Grid>
            <Grid item><Box style={{ margin: 'auto' }} className={classes.copyright}><Link to="/shippinganddelivery" component={RouterLink}>Shipping & Delivery</Link></Box></Grid> 
            <Grid item><Box style={{ margin: 'auto' }} className={classes.copyright}><Link to="/pricing" component={RouterLink}>Pricing</Link></Box></Grid> 
            <Grid item><Box style={{ margin: 'auto' }} className={classes.copyright}><Link to="/refund" component={RouterLink}>Cancellation & Refund</Link></Box></Grid> 
           
          </Grid>
        {/*   <Box className={classes.copyright} >©2022 XPSOLV</Box>
          <Box style={{ margin: 'auto' }} className={classes.copyright} >Contact Us</Box>
          <Box style={{ margin: 'auto' }} className={classes.copyright} >S&D</Box> */}
        
       </div>
      </Form>


      {/* <Loader isLoader={showLoder} showHide={setShowLoader} /> */}
    </FormikProvider>
  );
}
