import { Icon } from '@iconify/react';
import OutputIcon from '@mui/icons-material/Output';
import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

// material
import {
  Badge,
  Box, Button, Card, Container, Dialog,
  DialogContent,
  DialogTitle, Grid, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
// components
import { PlanTestForm, UpdateProfileForm } from '../components/forms/index';
import Page from '../components/Page';
import { TestApi } from '../service/index';
import { fDate } from '../utils/formatTime';

const getIcon = (name) => <Icon color="#007c7c" icon={name} width={22} height={22} />;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  fontsizeheading: {
    fontSize: 16,
  },
  paper: {
    padding: theme.spacing(2)
  },
  cardcontent: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
    paddingBottom: '0px !important',
  },
  peraclass2: {
    width: "202px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  peraclass: {
    fontSize: 13,
    paddingBottom: 10,
    fontWeight: 600,
    color: '#00007e',
  },
  emailphone: {
    fontSize: 13,
    fontWeight: 500,
    color: '#000000',
    fontFamily: 'Poppins',
    marginTop: -5,
    lineBreak: 'anywhere',
    marginLeft: 10
  },
  headingfont: {
    fontSize: 16,
    fontWeight: 600,
  },
  btntext: {
    fontSize: 12,
    fontWeight: 500,
    padding: '0 9px',
    background: '#007c7c',
    color: '#ffffff',
    marginTop: '12px',
    "&:hover": {
      background: '#007c7c',
    }
  },
  textcenter: {
    textAlign: 'center',
    lineHeight: 0,
  },
  headingfive: {
    display: 'flex',
    marginBottom: 26,
  },
  perafive: {
    color: '#929292',
    fontWeight: 500,
  },
  cardpadding: {
    paddingLeft: 0,
    paddingBottom: 10,
  },
  cardborder: {
    border: '1px solid #c8c8c8',
    "&:hover": {

      backgroundSize: 'cover',
      background: 'linear-gradient(to right, #edfbf4 15%, #d4ffe9 94%)',
      borderColor: '#fff',
    }
  },
  centertext: {
    textAlign: 'center',
    fontSize: 24,
  },
  centertextside: {
    textAlign: 'left',
    fontSize: 24,
  },
  btnmain: {
    fontWeight: 500,
    marginTop: 18,
  },
  closebtn: {
    position: 'absolute',
    right: 0,
    top: -12,
    fontWeight: 700,
  },
  textcolor: {
    color: '#007c7c',
  },
  textcolor2: {
    color: '#999',
    fontWeight: 500,
  },

  plusimg: {
    width: 29,
    marginTop: '-4px',
  },
  textmainid: {
    fontSize: 14,
  },
  textright: {
    textAlign: 'right',
  },
  mainbtn: {
    background: "#effff7",
    borderTop: "1px solid #d3ffe9"
  },
  h4heaidng: {
    textAlign: 'left',
    marginBottom: "5px",
    fontSize: "15px",
  },
  h4heaidng2: {
    textAlign: 'left',
    marginBottom: "5px",
    marginTop: "20px",
    fontSize: "15px",
  },
  colorreport: {
    color: '#007c7c',
  },
  schoolheading: {
    borderBottom: '1px solid #ececec',
    paddingBottom: 15,
    color: '#00007e',
    fontSize: 13,
    fontWeight: '600',
  },
  mtop: {
    marginTop: 33,
    borderTop: '1px solid #ececec',
  },
  imgplan333: {
    width: 15,
    float: 'left',
    marginTop: 5,
    marginRight: 11,
    [theme.breakpoints.up('sm')]: {
      width: 15,
    },
  },
  accountsummary: {
    borderBottom: '1px solid #c9c9c9',
    paddingTop: 18,
    paddingLeft: 0,
    marginBottom: 22,
  },
  summaryaccout: {
    borderBottom: '1px solid #c9c9c9',
    padding: 10,
    paddingTop: 0,
    paddingLeft: 0,
    marginBottom: 0,
    width: 'auto',
    fontSize: 16,
    [theme.breakpoints.up('sm')]: {
      width: '978px',
    },
  },

  amount: {
    fontSize: 16,
    color: "#000",
    textAlign: "right"
  },
  btnmargin: {
    marginBottom: "21px",
    textAlign: "center",
    paddingTop: "7px !important",

  },
  amount2: {
    fontSize: 14,
    color: "#000",
    fontWeight: 500,
  },
  btnamount: {
    background: "#000",
    marginTop: "-5px",

  },
  colorwhite: {
    color: "#",
    fontWeight: 600,
  },
  margin000: {
    margin: "0 30px",
  },
  bordertable: {
    border: "1px solid #e8e8e8",
    marginBottom: 24,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  colorwhite2: {
    color: "#000",
    fontSize: 16,
  },
  colorwhite3: {
    color: "#000",
    fontSize: 16,
  },

  showtoggle: {
    background: "#fff",
    padding: "4px 16px",
    fontWeight: 500,
    color: "#fff",
    border: 0,
    borderRadius: 8,
    float: "right",
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  imgplan2: {
    width: 15,
    [theme.breakpoints.up('sm')]: {
      width: 15,
    },
  },
  addamountbtn: {
    background: '#007c7c',
    padding: "6px",
    fontSize: "14px",
    color: "#fff",
    borderRadius: "6px",
    textDecoration: "none",

  },
  ruppeicon: {
    position: "relative",
    top: "7px",
    width: "17px"
  },
  cardtable: {
    margin: '0 30px',
  },
  cardmain: {
    padding: "13px",
    margin: '10px 0 15px 15px',
    marginBottom: 10,
  },
  cardmain2: {
    margin: '0px 0px',
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  infotext: {
    fontSize: "12px",
    marginTop: "9px",
    marginBottom: "0",
    fontWeight: "400",
    marginLeft: 17,
  },
  boldfont: {
    fontWeight: 600,
  },
  alertalignment: {
    marginTop: 15,
  },
  infoicons: {
    float: "left",
    width: "15px",
    marginTop: "2px",
    marginRight: "5px"
  },
  logoutbtn: {
    fontSize: 15,
    background: "#089293",
    width: "124px",
    borderRadius: "6px",
    marginTop: "14px",
    color: '#fff',
  },
  buttoncomon2: {
    height: '35px',
    margin: '10px',
    padding: '0 15px',
    marginTop: "-9px",
    width: "100%",
  },
  inputid: {
    margin: '12px'

  }

}));

export default function DashboardApp(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [userMobile, setUserMobile] = useState('')
  const [userDetails, setUserDetails] = useState([]);
  const [acctSummaryArr, setAcctSummaryArr] = useState([]);
  const [sumAddedInfo, setSumAddedInfo] = useState([]);
  const [sumConsumedInfo, setSumConsumedInfo] = useState([]);
  const [open, setOpen] = useState(false);
  const [openTest, setOpenTest] = useState(false);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [fullWidth, setFullWidth] = useState(true);
  const [selectedUserDetails, setSelectedUserDetails] = useState();
  const [isTeacher, setIsTeacher] = useState(false);
  const [isShow, setisShow] = useState(false);
  const [isShow2, setisShow2] = useState(false);
  const anchorRef = useRef(null);

  useEffect(() => {
    getUserDetails();
  }, []);

  const acctSummaryInfo = () => {
    try {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      const idStudentSchool = userInfo[0]?.StudentInfo.map(x => x.idStudentSchool).join(",");
      const reqsBody = {
        "mobilenum": userInfo[0].Mobile,
        "idStudentSchoolsc": idStudentSchool,
      };
      TestApi.acctSummary(reqsBody).then(
        (resp) => {
          if (resp.status === 200) {
            if (resp.data.status === 200) {
              setAcctSummaryArr(resp.data.data);
              acctSummaryData(resp.data.data);
            }
            else {
              alert(resp.data.message);
            }
          }
        }
      );
    }
    catch (error) {
      //   setShowLoader(false);
    }
  }

  const getUserDetails = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log("userInfo", userInfo);
    setUserDetails(userInfo);
    setUserMobile(userInfo[0]?.Mobile);
    acctSummaryInfo();
    console.log(userDetails);
  }

  const acctSummaryData = (acctSummary) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (acctSummary[0]?.SumAddedInfo) {
      let isBlankExist = false;
      if (acctSummary[0]?.SumAddedInfo.length === 1) {
        if ((acctSummary[0]?.SumAddedInfo[0]?.EntryDT) === "" && (acctSummary[0]?.SumAddedInfo[0]?.TranID) === "" && (acctSummary[0]?.SumAddedInfo[0]?.TransactionType) === "" && (acctSummary[0]?.SumAddedInfo[0]?.Amount) === "") {
          isBlankExist = true;
        }
      }
      if (!isBlankExist)
        setSumAddedInfo(acctSummary[0]?.SumAddedInfo);
    }
    if (acctSummary[0]?.SumConsumedInfo) {
      let isBlank = false;
      if (acctSummary[0]?.SumConsumedInfo.length === 1) {
        if ((acctSummary[0]?.SumConsumedInfo[0]?.EntryDT) === "" && (acctSummary[0]?.SumConsumedInfo[0]?.GUQPID) === "" && (acctSummary[0]?.SumConsumedInfo[0]?.Amount) === "") {
          isBlank = true;
        }
      }
      if (!isBlank)
        setSumConsumedInfo(acctSummary[0]?.SumConsumedInfo);
    }
    if (userInfo[0]?.StudentInfo && userInfo[0].StudentInfo.length === 1 && userInfo[0].StudentInfo[0]?.role === 'Teacher') {
      setIsTeacher(true);
    }
  };

  const handelOrderNewTest = (item) => {
    if (item && item?.idStudent && item?.ClassNumeric) {
      navigate('/dashboard/plan-test', { replace: true, state: item });
      setSelectedUserDetails(item);
      setOpenTest(true);
    }
    else {
      navigate('/dashboard/plan-test', { replace: true, state: item });
      setSelectedUserDetails(item);
      setOpenTest(true);
    }
  };

  const handleUpdateProfile = (item) => {
    setSelectedUserDetails(item);
    setOpen(true);
  };


  const showhide = () => {
    setisShow(!isShow);


    // setOpen(false);

  };
  const showhide2 = () => {
    setisShow2(!isShow2);
    // setOpen(false);

  };

  const handleClose = () => {
    setOpen(false);
    setOpenTest(false);
    getUserDetails();
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    navigate('/login', { replace: true });
  }

  const payment = () => {
    navigate('/dashboard/checkout');
  }


  return (
    <Page title="Dashboard | XPSOLV">


      {!isTeacher && <>
        <div className={classes.margin000}>


          <Container className={classes.accountsummary}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom className={classes.summaryaccout}>
                  Account <Box component="span" display="inline" className={classes.textcolor}>Summary</Box>
                </Typography>
              </Grid>

              <Grid item md={6} xs={12}>
                <Stack spacing={2} direction="row" className={classes.btnmargin}>
                  <Typography variant="h6" gutterBottom className={classes.amount2}>
                    Available Balance
                  </Typography>             

                  <Typography variant="h4" gutterBottom className={classes.amount}>
                    {acctSummaryArr?.map((item, index) =>
                    (
                      <>
                        {item?.BalanceInfo && item?.BalanceInfo.length > 0 &&
                          <>
                            {item?.BalanceInfo[0]?.Balance}
                          </>
                        }
                        {!item.BalanceInfo &&
                          <>
                            0.00
                          </>
                        }                    </>
                    ))}
                  </Typography>
                  <Button variant="contained" size='small' onClick={() => payment()}>Recharge</Button>
                </Stack>
              </Grid>
            </Grid>
          </Container>
          {sumAddedInfo.length > 0 && <>
            <Typography variant="h6" gutterBottom className={classes.colorwhite2}>
              {/* Last 5 Transaction(Add) */}
              Last 5 Recharge
            </Typography>
            <div>
              <TableContainer className={classes.bordertable}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow key="add">
                      <TableCell align="right" className={classes.textcenter}>Txn ID</TableCell>
                      <TableCell align="right" className={classes.textcenter}>Date</TableCell>
                      <TableCell align="right" className={classes.textcenter}>Txn Type</TableCell>
                      <TableCell align="right" className={classes.textcenter}>Amount (INR)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sumAddedInfo.slice(0, 5).map((item, index) => (
                      <TableRow
                        key={`add_${index}`}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="right" className={classes.textcenter}>{item?.TranID}</TableCell>
                        <TableCell align="right" className={classes.textcenter}>{item?.EntryDT ? fDate(item?.EntryDT) : "-"}</TableCell>
                        <TableCell align="right" className={classes.textcenter}>{item?.TransactionType}</TableCell>
                        <TableCell align="right" className={classes.textcenter}>{item?.Amount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <Grid container item spacing={2}>
              <Grid item lg={12} xs={12} className={classes.cardmain2} >
                {sumAddedInfo.slice(0, 5).map((item, index) => (
                  <Card className={classes.cardmain}>
                    <Grid container item spacing={2}>
                      <Grid item lg={6} xs={6} >
                        <Typography variant="h6" gutterBottom className={classes.amount2}>
                          Date
                        </Typography>
                      </Grid>
                      <Grid item lg={6} xs={6}>
                        <Typography variant="h6" gutterBottom className={classes.amount2}>
                          {item?.EntryDT ? fDate(item?.EntryDT) : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item spacing={2}>
                      <Grid item lg={6} xs={6} >
                        <Typography variant="h6" gutterBottom className={classes.amount2}>
                          Txn ID
                        </Typography>
                      </Grid>
                      <Grid item lg={6} xs={6}>
                        <Typography variant="h6" gutterBottom className={classes.amount2}>
                          {item?.TranID}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item spacing={2}>
                      <Grid item lg={6} xs={6} >
                        <Typography variant="h6" gutterBottom className={classes.amount2}>
                          Txn Type
                        </Typography>
                      </Grid>
                      <Grid item lg={6} xs={6}>
                        <Typography variant="h6" gutterBottom className={classes.amount2}>
                          {item?.TransactionType}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item spacing={2}>
                      <Grid item lg={6} xs={6} >
                        <Typography variant="h6" gutterBottom className={classes.amount2}>
                          Amount
                        </Typography>
                      </Grid>
                      <Grid item lg={6} xs={6}>
                        <Typography variant="h6" gutterBottom className={classes.amount2}>
                          {item?.Amount}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                ))}
              </Grid>
            </Grid>
          </>
          }

          {sumConsumedInfo.length > 0 && <>
            <Typography variant="h6" gutterBottom className={classes.colorwhite3}>
              {/* Last 5 Transaction(Used) */} Last 5 Usage
            </Typography>
            <div>
              <TableContainer className={classes.bordertable}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow key="last">
                      <TableCell align="right" className={classes.textcenter}>ID</TableCell>
                      <TableCell align="right" className={classes.textcenter}>Date</TableCell>
                      <TableCell align="right" className={classes.textcenter}>Type</TableCell>
                      <TableCell align="right" className={classes.textcenter}>Amount (INR)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sumConsumedInfo.slice(0, 5).map((item, index) => (
                      <TableRow
                        key={`last_${index}`}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="right" className={classes.textcenter}>{item?.GUQPID}</TableCell>
                        <TableCell align="right" className={classes.textcenter}>{item?.EntryDT ? fDate(item?.EntryDT) : "-"}</TableCell>
                        <TableCell align="right" className={classes.textcenter}>{item?.TransactionType}</TableCell>
                        <TableCell align="right" className={classes.textcenter}>{item?.Amount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <Grid container item spacing={2}>
              <Grid item lg={12} xs={12} className={classes.cardmain2} >
                {sumConsumedInfo.slice(0, 5).map((item, index) => (
                  <Card className={classes.cardmain}>
                    <Grid container item spacing={2}>
                      <Grid item lg={12} xs={12}>
                        <Stack spacing={2} direction="row">
                          <Badge badgeContent={index + 1} color="warning" overlap="rectangular"
                            anchorOrigin={{
                              vertical: 'top', horizontal: 'left'
                            }}>
                            <OutputIcon />
                          </Badge>
                        </Stack>
                      </Grid>
                      {/*     <Grid item lg={6} xs={6} >
                        <Typography variant="h6" gutterBottom className={classes.amount2}>
                          Sr No.
                        </Typography>
                      </Grid>
                      <Grid item lg={6} xs={6}>
                        <Typography variant="h6" gutterBottom className={classes.amount2}>
                          {index + 1}
                        </Typography>
                      </Grid> */}
                    </Grid>
                    <Grid container item spacing={2}>
                      <Grid item lg={6} xs={6} >
                        <Typography variant="h6" gutterBottom className={classes.amount2}>
                          Date
                        </Typography>
                      </Grid>
                      <Grid item lg={6} xs={6}>
                        <Typography variant="h6" gutterBottom className={classes.amount2}>
                          {item?.EntryDT ? fDate(item?.EntryDT) : "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item spacing={2}>
                      <Grid item lg={6} xs={6} >
                        <Typography variant="h6" gutterBottom className={classes.amount2}>
                          Paper ID
                        </Typography>
                      </Grid>
                      <Grid item lg={6} xs={6}>
                        <Typography variant="h6" gutterBottom className={classes.amount2}>
                          {item?.GUQPID}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item spacing={2}>
                      <Grid item lg={6} xs={6} >
                        <Typography variant="h6" gutterBottom className={classes.amount2}>
                          Amount (INR)
                        </Typography>
                      </Grid>
                      <Grid item lg={6} xs={6}>
                        <Typography variant="h6" gutterBottom className={classes.amount2}>
                          {item?.Amount}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                ))}
              </Grid>
            </Grid>

          </>
          }
        </div>
        {/*       <Container>
      <Tooltip title="Signout"  arrow>
      <IconButton
      className={classes.logoutbtn}
        ref={anchorRef}
        onClick={handleLogout}
        sx={{
          paddingRight: -1,
          width: 44,
          height: 44,
          
        }}
      >
        
          <Avatar  src={account.photoURL} alt="photoURL" />
          Signout
      </IconButton>
      </Tooltip>
      </Container> */}
      </>}

      <Dialog open={open} onClose={handleClose} fullWidth={fullWidth} maxWidth={maxWidth} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className={classes.centertext}>Update Contact<IconButton onClick={handleClose} style={{ float: 'right', fontSize: 30, color: '#000', marginTop: -17, }}>⨯</IconButton></DialogTitle>
        <DialogContent>
          <UpdateProfileForm allUserInfo={acctSummaryArr} userInfo={selectedUserDetails} onChildClick={handleClose} />
        </DialogContent>
      </Dialog>
      <Dialog open={openTest} onClose={handleClose} fullWidth={fullWidth} maxWidth={maxWidth} aria-labelledby="form-dialog-title">
        <Grid container item >
          <Grid item lg={9} >
            <DialogTitle id="form-dialog-title" className={classes.centertextside}>Plan Test <IconButton onClick={handleClose} style={{ float: 'right' }} className={classes.closebtn}>x</IconButton></DialogTitle>
          </Grid>
          <Grid item lg={3} >
            <Button
              variant="contained"
              component={RouterLink}
              to="/dashboard/test-history"
              className={classes.btnmain}
            >
              Test History
            </Button>
          </Grid>
        </Grid>
        <DialogContent>
          <PlanTestForm allUserInfo={acctSummaryArr} userInfo={selectedUserDetails} onChildClick={handleClose} />
        </DialogContent>
      </Dialog>
    </Page>
  );
}




