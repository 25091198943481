import axios from "axios";

// axios instance for making requests 
const xpSolvService = axios.create({
// <<<<<<< Updated upstream
//    baseURL: "http://139.144.1.89:8080/router/",
      
    baseURL: "https://qa.xpsolv.com/remote/router/",
// =======
//     baseURL: "https://xpsolv.com/remote/router/",
// >>>>>>> Stashed changes
    headers: {
        "Content-type": "application/json"
    }
});

// request interceptor for adding token
xpSolvService.interceptors.request.use((config) => {
    // add token to request headers
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return config;
});

export default xpSolvService;