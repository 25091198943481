import xpSolvService from "../axios/axios";

class HistoryTestService {

    historyTest(data) {
        return Promise.resolve(xpSolvService.post('testhistown', data));
    }
    
    testReceived(data) {
        return Promise.resolve(xpSolvService.post('testreceived', data));
    }

    genhtml(data) {
        return Promise.resolve(xpSolvService.post('genhtml', data));
    }

    getStudentSubject(data) {
        return Promise.resolve(xpSolvService.post(`getsubject`,data));
    }

    generateReport(data) {
        return Promise.resolve(xpSolvService.post('perfreport', data));
    }  
}

export default HistoryTestService;