import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { Base64 } from 'js-base64';
import 'react-toastify/dist/ReactToastify.css';
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';

// material
import {
  CardMedia,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Chip,
  Box,
  Grid,
  ButtonGroup,
  Link,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';

// components
import Page from '../components/Page';
import { TestApi } from '../service/index';

const steps = [
  'Primary Information',
  'Summary & Breakup',
  'Review & Fin',
];


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  centerwidth: {
    margin: 'auto',
  },
  floatRight: {
    background: 'transparent !important',
    height: '38px',
    marginLeft: 10,
    color: '#999999 !important',
  },
  floatRight2: {
    background: 'transparent !important',
    height: '38px',
    marginLeft: 10,
    color: '#999999 !important',
    marginTop: '20px',
    float: 'right',
    [theme.breakpoints.up('sm')]: {
      marginTop: '0px',
    },
  },
  themargin: {
    marginTop: "7px",
    borderTop: "1px solid #b1b1b1",
    paddingTop: "0px",
    [theme.breakpoints.up('sm')]: {
      marginTop: "26px",
      paddingTop: "22px"
    },
  },
  themarginTeacher: {
    marginTop: "7px",
    paddingTop: "0px",
    [theme.breakpoints.up('sm')]: {
      marginTop: "26px",
      paddingTop: "22px"
    },
  },
  colorgray: {
    background: 'transparent !important',
    color: '#808080 !important',
    whiteSpace: 'nowrap',
    marginLeft: '0',
    fontSize: "12px",
    padding: "5px",
    boxShadow: 'none',
    border: '1px solid #999',
    [theme.breakpoints.up('sm')]: {
      padding: "6px 15px",
      fontSize: "13px",
      margin: "auto",
      display: "block"
    },
  },
  colorgray2: {
    border: 'none',
    color: '#808080 !important',
  },
  colorgrayactive: {
    background: '#808080 !important',
    color: '#fff !important',
    whiteSpace: 'nowrap',
    marginLeft: '0',
    fontSize: "12px",
    padding: "5px",
    [theme.breakpoints.up('sm')]: {
      padding: "6px 15px",
      fontSize: "13px",
      margin: "auto",
      display: "block",
    },
  },
  steppershaodw: {
    boxShadow: '1px 1px 10px #000',
  },
  textcolor: {
    color: '#000000',
    lineHeight: 3.5,
    fontSize: 13,
    [theme.breakpoints.up('sm')]: {
      lineHeight: 3.5,
    },
  },
  fontmain: {
    fontSize: 10,
  },
  textcolor2: {
    color: '#007c7c',
    marginBottom: '20px',
    fontSize: 14,
  },
  textcolor55: {
    color: '#007c7c',
    marginBottom: '20px',
  },
  textcneter: {
    textAlign: 'left',
    marginBottom: 18,
  },
  textcneter2: {
    textAlign: 'center',
    marginBottom: 0,
  },
  textcneter3: {
    textAlign: 'left',
    marginBottom: 0,
    fontWeight: 500,
    marginTop: '13px',
    fontSize: '13px',
    color: '#007c7c',

  },
  thefont: {
    borderBottom: '1px solid #999',
  },
  centerwidth2: {
    margin: 'auto',
    border: '1px solid #d9d9d9',
    borderRadius: 10,
    marginTop: 40,
  },
  textright: {
    textAlign: 'left',
    borderBottom: '1px solid #d9d9d9',
    padding: '10px 30px',
    fontWeight: 600,
  },
  paddingten: {
    padding: 30,
  },
  paddingten2: {
    padding: 30,
    borderBottom: '1px solid #d9d9d9',
  },
  tenalign: {
    fontWeight: 500,
    padding: '12px 72px',
    borderRadius: 4,
    marginLeft: 'auto',
    display: 'block',
  },
  summarytest: {
    fontWeight: 600,
  },
  bgcheckbox: {
    margin: 30,
    padding: '34px',
    background: '#e7fff1',
    maxWidth: '-webkit-fill-available',
    borderRadius: 10,
  },
  colrotxt: {
    color: '#007c7c',
    fontWeight: 600,

  },
  imgplan2: {
    width: '44%',
    margin: 'auto',

  },
  colrotxt2: {
    margin: '9px 0',
    fontWeight: 600,
    display: 'inline-flex',
    fontSize: 16,
    width: '72px',
  },
  butnspacing: {
    padding: '0 33px 29px',
  },
  imgplan4: {
    width: '75px',
    margin: 'auto',
  },
  textclear: {
    textAlign: 'center',
    fontWeight: 500,
  },

  boxicon: {
    border: '1px solid #d7d7d7',
    margin: 10,
    padding: 15,
    borderRadius: 6,
  },
  textclear1: {
    color: '#007c7c',
    textAlign: 'center',
    fontWeight: 500,
  },
  bgsummary: {
    background: '#ececec',
    padding: '15px',
    borderRadius: 5,
    marginBottom: 30,
    margin: 10,
  },
  summarytext: {
    margin: 0,
    fontWeight: 600,
  },
  summarytext2: {
    margin: 0,
    fontWeight: 600,
    textAlign: 'right',
  },
  bordermain: {
    borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
  },
  bgprofile: {
    paddingLeft: '0',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '31px',
    },
  },
  bgprofileinner: {
    background: "#fff", padding: "3px", marginTop: "18px", borderRadius: "32px",
    backgroundSize: 'contain',
    [theme.breakpoints.up('lg')]: {
      borderRadius: "32px 0px 0px 32px ",
    },
  },
  bgprofileinner2: {
    background: "#fff", padding: "8px", marginTop: "18px", borderRadius: "10px", fontSize: "19px", fontWeight: "500", border: '1px solid #e9e9e9', backgroundSize: "contain",
    [theme.breakpoints.up('lg')]: {
      borderRadius: "32px",
      padding: "33px",
    },
  },
  tableborder: {
    borderRight: '1px solid #999',
  },
  tablemargin: {
    paddingLeft: 15,
    borderBottom: '1px solid #999',
  },
  questions: {
    marginBottom: '20px',
    fontSize: '14px',

  },
  questiondetails: {
    marginBottom: '20px',
    borderBottom: "1px solid #b7b7b7", paddingBottom: "22px"
  },
  buttngroup: {
    padding: "14px 31px", fontWeight: "500",
    [theme.breakpoints.up('lg')]: {
      padding: "14px 42px",
    },
  },
  textmaintext: {
    background: "#000", borderRadius: "4px", padding: "9px 32px", letterSpacing: "3px", color: "#fff", margin: "13px auto", display: "block", fontWeight: "300", fontSize: "13px",

  },
  textmaintext2: {
    color: '#fff',
    background: "#000", float: 'right', borderRadius: "4px", padding: "9px 32px", letterSpacing: "3px", margin: "13px", display: "block", fontWeight: "300", fontSize: "13px"
  },
  textmaintext44: {
    background: "#007c7c",
    borderRadius: "4px",
    padding: "7px 32px",
    margin: "1px 16px", display: "block",
    fontWeight: "300",
    fontSize: "13px",
    marginTop: 21,
    [theme.breakpoints.up('sm')]: {
      fontWeight: '600',
      marginTop: 0,
    },
  },
  textmaintext3: {
    background: "#007c7c", borderRadius: "4px", padding: "9px 15px", letterSpacing: "1px", margin: "13px auto", display: "block", fontWeight: "500", fontSize: "13px", position: "absolute", right: "0", top: "-124px",
    [theme.breakpoints.up('lg')]: {
      top: "-46px",
      padding: "9px 32px",
    },
  },
  textcneter6: {
    color: '#007c7c',
    textAlign: 'left',
  },
  buttonten: {
    position: 'relative',
  },
  class11: {
    color: '#fff',
    fontSize: '14px',
    fontWeight: '500',
  },
  imgerelative: {
    position: 'relative',

  },
  imgplan6: {
    position: 'absolute',
    bottom: '-255px',
  },
  paddingtenso: {
    padding: 15,
    [theme.breakpoints.up('lg')]: {
      padding: 0,
    },
  },

  bgimgprofile: {
    background: '#d4ffe9',
    borderRadius: '15px',
  },
  ullicontent: {
    float: "left",
    listStyle: "none",
    margin: "0 5px",
    padding: "4px",
    borderRadius: "5px",
    color: "#007c7c",
    fontSize: 14,
  },
  textcnetercahpter: {
    display: "flex",
    paddingBottom: "12px",
    color: "#007c7c",
    fontSize: "13px",
    marginTop: "13px",
    textAlign: "left",
    fontWeight: "500",
    marginBottom: "0",
  },
  buttonright: {
    margin: "0 13px",
    padding: "7px 12px",
    fontSize: "12px",
    background: "#007c7c",
    fontWeight: "600",
    borderRadius: "4px",
    letterSpacing: "1px",
    marginTop: '22px',
    whiteSpace: "nowrap",
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    },
  },
  buttoncorrect: {
    color: '#007c7c !important',
    background: 'transparent !important',
    border: "1px solid #007c7c !important",
    fontSize: "12px",
    padding: "5px",
    boxShadow: 'none',
    [theme.breakpoints.up('sm')]: {
      padding: '6px 15px',
      fontSize: "13px",
      margin: 'auto',
      display: 'block',
    },

  },
  redalert: {
    color: '#969696',
    fontSize: "12px",
    fontWeight: '500',
    marginTop: "12px",
    textAlign: 'center',
  },
  buttonpartially: {
    color: '#febf00 !important',
    background: 'transparent !important',
    border: "1px solid #febf00 !important",
    whiteSpace: 'nowrap',
    fontSize: "12px",
    padding: "5px",
    boxShadow: 'none',
    [theme.breakpoints.up('sm')]: {
      padding: '6px 15px',
      fontSize: "13px",
      margin: "auto",
      display: "block"
    },
  },
  incorrect: {
    color: '#fe0000 !important',
    background: 'transparent !important',
    border: "1px solid #fe0000 !important",
    fontSize: "12px",
    padding: "5px",
    boxShadow: 'none',
    [theme.breakpoints.up('sm')]: {
      padding: '6px 15px',
      fontSize: "13px",
      margin: "auto",
      display: "block"
    },
    "&:hover": {
      background: '#fe0000+ !important',
    }
  },
  buttoncorrectactive: {
    color: '#fff !important',
    background: '#007c7c !important',
    border: "none !important",
    fontSize: "12px",
    padding: "5px",
    [theme.breakpoints.up('sm')]: {
      padding: '6px 15px',
      fontSize: "13px",
      margin: "auto",
      display: "block",
    },
    "&:hover": {
      background: '#007c7c !important',
    }
  },
  buttonpartiallyactive: {
    color: '#fff !important',
    background: '#febf00 !important',
    border: "1px solid #febf00 !important",
    whiteSpace: 'nowrap',
    fontSize: "12px",
    padding: "5px",
    boxShadow: 'none',
    [theme.breakpoints.up('sm')]: {
      padding: '6px 15px',
      fontSize: "13px",
      margin: "auto",
      display: "block",
    },
    "&:hover": {
      background: '#febf00 !important',
    }
  },
  incorrectactives: {
    color: '#fff !important',
    background: '#fe0000 !important',
    border: "none !important",
    fontSize: "12px",
    padding: "5px",
    [theme.breakpoints.up('sm')]: {
      padding: "6px 15px",
      fontSize: "13px",
      margin: "auto",
      display: "block"
    },
  },
  colroblind: {
    color: '#00af50 !important',
    marginLeft: '20px',
  },
  mcqquestion: {
    marginTop: '20px',
    fontSize: 14,
  },
  mcqquestion2: {
    fontSize: 14,
  },
  buttonborder: {
    border: "none !important",
    boxShadow: 'none',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '70%',
      margin: "auto",
      display: "block"
    },
  },
  popupwidth: {
    width: 242,
    [theme.breakpoints.up('sm')]: {
      width: 221,
    },
  },
  textxenter: {
    textAlign: 'center',
    margin: "auto",
    marginBottom: "12px",
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',


      marginBottom: 12,
      margin: "0",
      maxWidth: "auto",
    },
  },
  centeralign: {
    textAlign: 'center',
  },
  centerbutton: {
    margin: "auto",
    borderTop: "1px solid #dedede",
    width: "201px",
    justifyContent: "center",
    display: "flex"
  },
  textmaintext66:{
    color: "#fff", 
    float: "right", 
    display: "block", 
    padding: "5px 14px", 
    fontSize: "13px", 
    background: "#000", 
    fontWeight: "300", 
    borderRadius: "4px", 
    letterSpacing: "0",
    cursor:'pointer',
  }

}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, chapters, theme) {
  return {
    fontWeight:
      chapters.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function ReviewAnswer() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { state } = useLocation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState('');
  const [showUser, setShowUser] = useState({
    firstName: '', lastName: '', className: '', board: ''
  })
  const [chapterList, setChapterList] = useState([]);
  const [nextButton, setNextButton] = useState(true);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [reviewAnswerData, setReviewAnswerData] = useState();
  const [reviewAnswerMainData, setReviewAnswerMainData] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [downloadId, setDownloadID] = useState('');
  const [chapters, setChapters] = useState([]);
  const [chaptersIds, setChaptersIds] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [userDetails, setUserDetails] = useState([]);
  const [answerStatuses, setAnswerStatuses] = useState([{ "id": 1, "answerStatus": "Not Marked" }, { "id": 2, "answerStatus": "Correct" }, { "id": 3, "answerStatus": "Incorrect" }, { "id": 4, "answerStatus": "Partially Correct" }, { "id": 5, "answerStatus": "Did Not Attempt" }]);
  const [reviewSection, setReviewSection] = useState(false);
  const [idStuSchool, setIdStuSchool] = useState(0);
  const [studentData, setStudentData] = useState({});
  const [StudentName, setStudentName] = useState(false);
  
  useEffect(() => {
    if (state) {
      console.log("Review Answer:>>>", state);
      setSelectedUser(state);
      if(state.pageType === 'REVIEW_ANSWER'){
        setReviewAnswerMainData(state);
        setReviewAnswerData(state.Questions);
        setSelectedUser(state?.HeaderInfo[0]);
        getIdStudentSchool(state?.HeaderInfo[0]?.FirstName);
      }else{
        getReviewAnswer(state?.idQuestionPaperDelivered, state?.idStudentSchool);
      }
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      setUserDetails(userInfo);
      if (userInfo[0]?.StudentInfo[0]?.role === 'Teacher') {
        setReviewSection(true);
      }
    }
  }, []);

  const getIdStudentSchool = (name) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    userInfo[0]?.StudentInfo.forEach((element, index) => {
      if (element.FirstName.trim() === name) {
        setIdStuSchool(element.idStudentSchool);
      }
    });

  }

  const getStudentData = (idStudentSchool) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    userInfo[0]?.StudentInfo.forEach((element, index) => {
      if (element.idStudentSchool === idStudentSchool) {
        setStudentData(element);
        setStudentName(true);
      }
    });

  }

  const getPrevious = () => {
    if(state.pageType === 'REVIEW_ANSWER'){
      navigate('/dashboard/reviewAnswers', { replace: true });
    }
    else{
      navigate('/dashboard/test-history', { replace: true });
    }
    };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const TestSchema = Yup.object().shape({
    testSubject: Yup.string().required('Subject is required'),
    //   chaptersIds: Yup.string().required('Chapter is required'),
    format: Yup.string().required('Format is required')
    });

  const formik = useFormik({
    initialValues: {
      testSubject: "",
      //   chaptersIds: [],
      format: ""
    },
    validationSchema: TestSchema,
    onSubmit: (data) => {
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const getReviewAnswer = (idTestPaper, idStudentSchool) => {
    try {
      const reqsBody = {
        "idStudentSchool": idStudentSchool,
        "idTestPaper": idTestPaper,
      };
      TestApi.reviewAnswer(reqsBody).then(
        (resp) => {
          if (resp.status === 200) {
            if (resp.data.status === 200) {
              setReviewAnswerMainData(resp.data.data[0]);
              setReviewAnswerData(resp.data.data[0].Questions);
              setSelectedUser(resp.data.data[0]?.HeaderInfo[0]);
              getIdStudentSchool(resp.data.data[0]?.HeaderInfo[0]?.FirstName);
            } 
            else if(resp.data.status === 201 && resp.data.message === "Cooling Period On!"){
              toast.info(`This paper will be available for review ${resp.data.data} minutes from now!`, {
                position: toast.POSITION.TOP_RIGHT
                });
                setIdStuSchool(state?.idStudentSchool);
                getStudentData(state?.idStudentSchool);
            } 
            else {
              alert(resp.data.message);
            }
          }
          else {
            alert(resp.message);
          }
        },
        (error) => { alert("Error in getReviewAnswer"); }
      );
    }
    catch (error) {
      console.log("getReviewAnswer Catch", error);
    }
  }

  const submitReviewAnswerStatus = () => {
    try {
      const answerStatusArray = [];
      // eslint-disable-next-line array-callback-return
      reviewAnswerData?.map((item, index) => {
        const answerStausObj = { "idQuestion": item.idQuestion, "anstatus": item.anstatus };
        answerStatusArray.push(answerStausObj);
      });

      const reqsBody = {
        "idStudentSchool": idStuSchool,
        "GUQPID": reviewAnswerMainData?.GUQPID,
        "AnswerStatus": answerStatusArray,
      };
      TestApi.updateReviewAnswerStatus(reqsBody).then(
        (resp) => {
          if (resp.status === 200) {
            if (resp.data.status === 200) {
              navigate('/dashboard/test-history', { replace: true });
            }
            else {
              alert(resp.data.message);
            }
          }
          else {
            alert(resp.message);
          }
        },
        (error) => { alert("Error in getReviewAnswer"); }
      );
    }
    catch (error) {
      console.log("getReviewAnswer Catch", error);
    }
  }

  const setAnswer = (data) => {
    // eslint-disable-next-line no-debugger
    if (reviewAnswerData && reviewAnswerData.length > 0) {
      const newArray = [...reviewAnswerData];
      newArray[selectedIndex].anstatus = data.id;
      setReviewAnswerData(newArray);
    }
  }
  const setNextQuestion = () => {
    const totalLength = reviewAnswerData.length - 1;
    if (reviewAnswerData && reviewAnswerData.length > 0 && totalLength === selectedIndex + 1) {
      setNextButton(false);
    }
    else {
      setNextButton(true);
    }
    setSelectedIndex((selectedIndex + 1))

  }
  const setPreviousQuestion = () => {
    // eslint-disable-next-line no-debugger

    const totalLength = reviewAnswerData.length - 1;
    if (reviewAnswerData && reviewAnswerData.length > 0 && totalLength === selectedIndex + 1) {
      setNextButton(false);
    }
    else {
      setNextButton(true);
    }
    setSelectedIndex((selectedIndex - 1))

  }

  const renderNextButton = () => {
    if (reviewAnswerData && reviewAnswerData.length > 0) {
      return (
        <Button className={classes.colorgray} onClick={() => setNextQuestion()}>Did't Attempt : Next</Button>
      )
    }
  }
  const renderMcq = (data) => {

    if (data.mcq) {
      return (
        data.mcqOptions.map((x) => (
          <>
            <div><strong>{x.optionLabel})&nbsp;&nbsp;</strong>{dbDollarChange(x.mcqOption.optionContent)}</div>
          </>
        ))
      )
    }
  };

  const dbDollarChange = (data) => {
    if (data) {

      return (
        data.split("$$").map((x, index) => (
          <>
            {index % 2 === 0 &&
              x.split("$").map((y, ind) => (
                <>
                  {ind % 2 === 0 &&

                    <> {y}</>
                  }
                  {ind % 2 !== 0 &&
                    <><InlineMath math={y} /> </>
                  }
                </>
              ))

            }
            {index % 2 !== 0 &&
              <><BlockMath math={x} /> </>
            }
          </>
        ))
      )



    }
  };

  return (
    <Page title="Plan Test | XPSOLV">

      <Grid container className={classes.paddingtenso}>
      <Typography variant="h4" gutterBottom className={classes.textcneter}>
            <Link underline="none" variant="contained" className={classes.textmaintext66} onClick={() => getPrevious()}>
              Back
            </Link>
          </Typography>
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.bgprofileinner}>
          <Grid item container>
            <Grid item lg={2} md={4} sm={12} xs={12} className={classes.bgimgprofile}>
              {!StudentName && <div>
                <div>{selectedUser?.Gender ?
                  <CardMedia image={`/static/mock-images/avatars/${selectedUser?.Gender}.png`}
                    style={{ width: '112px', height: '252px', margin: '10px auto', }}
                  />

                  :
                  <CardMedia image="/static/mock-images/avatars/demo.png"
                    style={{ width: '82px', height: '192px', margin: 10, marginLeft: 40 }}
                  />
                }</div>
              </div>}
              {StudentName && <div>
                <div>{studentData?.Gender ?
                  <CardMedia image={`/static/mock-images/avatars/${studentData?.Gender}.png`}
                    style={{ width: '112px', height: '252px', margin: '10px auto', }}
                  />

                  :
                  <CardMedia image="/static/mock-images/avatars/demo.png"
                    style={{ width: '82px', height: '192px', margin: 10, marginLeft: 40 }}
                  />
                }</div>
              </div>}
            </Grid>
            <Grid item lg={10} md={12} sm={12} xs={12}>
              <Box className={classes.bgprofile}>

                {StudentName && <Typography variant="h5" gutterBottom className={classes.textcneter6}>
                  {studentData?.FirstName}&nbsp;{studentData?.LastName},
                </Typography>
                }
                {!StudentName && <Typography variant="h5" gutterBottom className={classes.textcneter6}>
                  {selectedUser?.FirstName}&nbsp;{selectedUser?.LastName},
                </Typography>
                }

                {/* <Typography variant="h5" gutterBottom className={classes.textcneter2}>
              <Box component="span" display="inline" className={classes.class11}>Class IX</Box>
            </Typography> */}
                <Grid container className={classes.thefont}>
                  <Grid item lg={2} md={2}>
                    <Typography variant="h6" gutterBottom className={classes.textcneter3}>Subject :
                    </Typography>
                  </Grid>
                  <Grid item lg={10} md={10}>
                    <Box component="span" display="inline" className={classes.textcolor}>{selectedUser?.SubjectName}</Box>
                  </Grid>
                </Grid>
                <Grid container className={classes.thefont}>
                  <Grid item lg={2} md={2} xs={3}>
                    <Typography variant="h6" gutterBottom className={classes.textcnetercahpter}>Chapter :
                    </Typography>
                  </Grid>
                  <Grid item lg={10} md={10} xs={9}>
                    <ul  >

                      {selectedUser?.ChapterInfo?.map((item, index) =>
                        <li key={index} className={classes.ullicontent}><Chip label={item.ChapterName} variant="outlined" />
                        </li>)}

                    </ul>
                  </Grid>
                </Grid>
                <Grid container className={classes.thefont}>
                  <Grid item lg={2} md={2}>
                    <Typography variant="h6" gutterBottom className={classes.textcneter3}>Test Format :
                    </Typography>
                  </Grid>

                  <Grid item lg={10} md={10}>

                    <Box component="span" display="inline" className={classes.textcolor}>{selectedUser?.PFormat}</Box>
                  </Grid>
                </Grid>
                <Grid container className={classes.thefont}>
                  <Grid item lg={2} md={2}>
                    <Typography variant="h6" gutterBottom className={classes.textcneter3}>Test Date : <p className={classes.fontmain}>(YYYY-MM-DD) </p>
                    </Typography>
                  </Grid>
                  <Grid item lg={10} md={10}>
                    <Box component="span" display="inline" className={classes.textcolor}>{selectedUser?.DateDeliveredOn}</Box>
                  </Grid>
                </Grid>

                {/* <Button variant="outlined" className={classes.textmaintext}>
              Start Review
            </Button> */}

              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.bgprofileinner2}>
          <Typography variant="h4" gutterBottom className={classes.textcneter}>
            Review <Box component="span" display="inline" className={classes.textcolor55}>answer</Box>
            <Link underline="none" variant="contained" className={classes.textmaintext2} component={RouterLink} to="/dashboard/test-history">
              Exit
            </Link>
          </Typography>
          <Box className={classes.questiondetails}>

            <Typography variant="p" gutterBottom className={classes.questions}>
              {/* <Box component="span" display="inline" className={classes.textcolor2}>Question.{selectedIndex + 1}{" "}
              </Box> */}
              {/* <p className={classes.mcqquestion2}>{dbDollarChange(reviewAnswerData ? Base64.decode(reviewAnswerData[selectedIndex]?.QARev) : null)}</p> */}
              <div key={selectedIndex + 1} dangerouslySetInnerHTML={{ __html: reviewAnswerData ? Base64.decode(reviewAnswerData[selectedIndex]?.QARev) : null }} />
              {/* <p className={classes.mcqquestion}>
                {reviewAnswerData ? renderMcq(reviewAnswerData[selectedIndex]) : null} 
              </p> */}
              {/* var InlineMath = ReactKaTeX.InlineMath;
 
               ReactDOM.render(<InlineMath math="\\int_0^\\infty x^2 dx"/>,
               document.getElementById('math')); */}

            </Typography>
            {/* <Box className={classes.buttonten}>
              <Button variant="contained" className={classes.textmaintext3} onClick={() => setPreviousQuestion()}>
                Previous
              </Button>
              <Button variant="contained" className={classes.textmaintext3} onClick={() => setNextQuestion()}>
                Did't Attempt : Next
              </Button>
            </Box> */}
          </Box>
          {/* <Box className={classes.questiondetails}><Box component="p" display="inline" className={classes.textcolor2}>Answer. </Box>
            <div>
              <Typography variant="p" gutterBottom className={classes.questions}>
                {dbDollarChange(reviewAnswerData ? reviewAnswerData[selectedIndex]?.answer : null)}

              </Typography>
            </div>
          </Box>	*/}
          {// <ButtonGroup variant="contained" aria-label="outlined primary button group">
            // {reviewAnswerData ? reviewAnswerData[selectedIndex]?.answerStatuses?.map((item, index) => (

            // <Button className={item.answerStatus} onClick={() => setAnswer(item)}>{item.answerStatus}</Button>

            // )
            // ) : null}
            // </ButtonGroup> 
          }
          {!reviewSection && <ButtonGroup variant="contained" aria-label="outlined primary button group" className={classes.buttonborder}>
            {reviewAnswerData ? reviewAnswerData[selectedIndex]?.anstatus : null}
            <Grid container>
              {reviewAnswerData ? answerStatuses?.map((item, index) => (
                <>
                  {item.answerStatus !== 'Not Marked' &&
                    <Grid key={index} item lg={3} md={3} sm={6} xs={6} className={classes.textxenter} >
                      {item.answerStatus === 'Correct' && <div><Button variant="contained" disabled={reviewAnswerMainData?.Marked === 1} className={parseInt(reviewAnswerData[selectedIndex]?.anstatus, 10) === item.id ? classes.buttoncorrectactive : classes.buttoncorrect} onClick={() => setAnswer(item)}>{item.answerStatus}</Button> </div>}
                      {item.answerStatus === 'Partially Correct' && <div><Button variant="contained" disabled={reviewAnswerMainData?.Marked === 1} className={parseInt(reviewAnswerData[selectedIndex]?.anstatus, 10) === item.id ? classes.buttonpartiallyactive : classes.buttonpartially} onClick={() => setAnswer(item)}>{item.answerStatus}</Button> </div>}
                      {item.answerStatus === 'Incorrect' && <div><Button variant="contained" disabled={reviewAnswerMainData?.Marked === 1} className={parseInt(reviewAnswerData[selectedIndex]?.anstatus, 10) === item.id ? classes.incorrectactives : classes.incorrect} onClick={() => setAnswer(item)}>{item.answerStatus}</Button> </div>}

                      {item.answerStatus === 'Did Not Attempt' && <div><Button variant="contained" disabled={reviewAnswerMainData?.Marked === 1} className={parseInt(reviewAnswerData[selectedIndex]?.anstatus, 10) === item.id ? classes.colorgrayactive : classes.colorgray} onClick={() => setAnswer(item)}>{item.answerStatus}</Button> </div>}
                    </Grid>
                  }
                </>

              )
              ) : null}
            </Grid>

          </ButtonGroup>
          }
          {/*
          <Button variant="contained" className={classes.buttoncorrect}>
              Correct
            </Button>
            <Button variant="contained" className={classes.buttonpartially}>
            Partially <span className={classes.colroblind}> Correct</span>
            </Button>
            <Button variant="contained" className={classes.incorrect}>
            Incorrect
            </Button>
		*/}
          <Grid container className={!reviewSection ? classes.themargin : classes.themarginTeacher} >
            <Grid item lg={6} md={6} sm={6} xs={6}>
              {selectedIndex >= 1 ?
                <Button variant="contained" aria-label="outlined primary button group" size="small" align="end" className={classes.floatRight2}>
                  <Button className={classes.colorgray2} onClick={() => setPreviousQuestion()}>Previous</Button>
                </Button> : null}

            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={6}>
              {nextButton && reviewAnswerData &&
                <>
                  {!reviewSection &&
                    <Button variant="contained" className={classes.textmaintext44} disabled={Boolean(parseInt(reviewAnswerData[selectedIndex]?.anstatus, 10) === 1 ? "disabled" : "")} onClick={() => setNextQuestion()}>Next
                    </Button>
                  }
                  {reviewSection &&
                    <Button variant="contained" className={classes.textmaintext44} onClick={() => setNextQuestion()}>Next
                    </Button>
                  }
                </>
              }
              {!nextButton &&
                <>
                  {!reviewSection &&
                    <Button variant="contained" className={classes.buttonright} disabled={Boolean(parseInt(reviewAnswerData[selectedIndex]?.anstatus, 10) === 1 ? "disabled" : "")} onClick={() => { handleClickOpen() }}>
                      Submit Answers
                    </Button>
                  }
                  {reviewSection &&
                    <Button variant="contained" className={classes.buttonright} disabled={Boolean(reviewAnswerMainData?.Marked)} component={RouterLink} to="/dashboard/app"
                    >
                      Finish Review
                    </Button>
                  }
                </>
              }
            </Grid>
            {!reviewSection && reviewAnswerData && (parseInt(reviewAnswerData[selectedIndex]?.anstatus, 10) === 1) &&
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <p className={classes.redalert}>Select Correct | Incorrect | Partially Correct | Did Not Attempt to enable Next button</p>
              </Grid>
            }
          </Grid>
          <Dialog

            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >

            <DialogContent className={classes.popupwidth}>
              <DialogContentText className={classes.centeralign}>

                Are you sure?
              </DialogContentText>
            </DialogContent>
            <DialogActions >
              <div className={classes.centerbutton}>
                <Button autoFocus onClick={handleClose}>
                  Cancel
                </Button>
                <Button onClick={() => submitReviewAnswerStatus()} autoFocus>
                  Submit
                </Button>
              </div>
            </DialogActions>
          </Dialog>



        </Grid>

      </Grid>
    </Page >
  );
}
