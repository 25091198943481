import { Icon } from '@iconify/react';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import { filter } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material
import {
  Box, Button, Card, CardHeader, CardMedia, Chip, Container, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, MenuItem, Radio, RadioGroup, Select, Stack, Table,
  TableBody, TableCell, TableContainer,
  TablePagination, TableRow, Tooltip, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { UserListHead } from '../components/_dashboard/user';

// components
import Loader from '../components/Loader';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { historyApi } from '../service/index';

const TABLE_HEAD = [
  { id: 'SubjectName', label: 'Subject', alignRight: false },
  { id: 'idQuestionPaperDelivered', label: 'TestId', alignRight: false },
  { id: 'ChapterInfo', label: 'Chapters', alignRight: false },
  { id: 'DateDeliveredOn', label: 'Date', alignRight: false },
  { id: 'PFormat', label: 'Format', alignRight: false },
  { id: 'Section', label: 'Section', alignRight: false },
  { id: '', label: 'Actions' }
];

const getIcon = (name) => <Icon color="#999" icon={name} width={22} height={22} />;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  paper: {
    padding: theme.spacing(2)
  },
  headingpart: {
    color: '#007c7c',

  },
  bordertable: {
    border: '1px solid #e4e4e4',
    marginBottom: '20px',
    borderRadius: '10px',
  },
  headtable: {
    background: '#f5f6fa',
  },
  afterbeforeshape2: {
    background: '#007c7c',
    margin: '20px 0',
    width: 'max-content',
    padding: '8px 20px',
    color: '#fff',
    borderRadius: 8,
    position: 'relative',
    fontSize: 13,
  },
  afterbeforeshape: {
    position: 'absolute',
    heihgt: 0,
    width: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderTop: '13px solid #007c7c',
    top: '115%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  dotspace: {
    marginTop: '16px',
  },
  headingtenw: {
    marginTop: '16px',
  },
  buttoncomon: {
    marginTop: 18,
    padding: '15px 30px',
    fontWeight: 500,
    background: '#007c7c',
  },
  borderbotmten: {
    borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
    paddingBottom: 20,
    marginBottom: 15,

  },
  headingtenw2: {
    marginTop: 11,
    fontSize: 16,
  },
  datepiker: {
    width: '100%',
  },
  width100: {
    width: '30%',
  },
  widthaccordian: {
    width: '15%',
  },
  truncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '260px',
  },
  maxWidth: {
    width: '100%',
    textAlign: 'left',
    margin: 24,
  },
  maxWidth21: {
    width: '100%',
    textAlign: 'left',
    margin: 10,
  },
  btnchapter: {
    fontSize: "12px",
    fontWeight: "600",
    padding: "5px 10px"
  },
  btnchapter2: {
    fontSize: "12px",
    fontWeight: "600",
    padding: "5px 10px",
    minWidth: '31px'
  },
  maxWidth2: {
    width: '100%',
    textAlign: 'left !important',
  },
  truncate2: {
    background: 'transparent',
    padding: 0,
  },
  truncate3: {
    padding: 0,
  },
  ternten: {
    verticalAlign: 'text-top',
  },
  hide: {
    display: 'none',
  },
  seprateline: {
    borderRight: '1px solid #d9d9d9',
    paddingRight: '35px',
    fontSize: 14,
  },
  sepratelineStudent: {
    paddingRight: '15px',
    fontSize: 14,
  },
  borderseprate: {
    borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
    paddingBottom: "12px",
  },
  specing: {
    fontSize: 13,
  },
  lefttext: {
    textAlign: 'left',
    whiteSpace: 'nowrap',
  },
  iconexpand: {
    width: '47px',
    float: 'left',
    marginRight: '-4px',
    marginTop: '-12px'
  },
  iconcolor: {
    color: '#ffffff',
    padding: 0,
    marginRight: 10,
    fontSize: "14px",
    [theme.breakpoints.up('sm')]: {
      color: '#007c7c',
    },
  },
  iconcolor2: {
    fontSize: "13px",
    padding: "0",
    color: "#007c7c",
  },
  disablediconcolor: {
    color: "#ffffff",
    padding: 0,
    fontSize: "14px",
    [theme.breakpoints.up('sm')]: {
      color: '#000',
    },
    pointerEvents: 'none',
    cursor: 'not-allowed'
  },
  hidedesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  hidemobile: {
    display: 'block',
    marginBottom: 15,
    border: '1px solid #999',

    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  cardheader: {
    padding: '5px',
    fontWeight:'800',
  },
  margingap: {
    marginTop: '20px',
    background: '#ffffff',
    padding: '16px 16px 0 16px',
    borderRadius: '0px',
    marginBottom: '0',
    border: '1px solid #dce0e4',
  },
  centericons: {
    textAlign: 'center',
    background: "#007c7c",
    padding: " 8px",
    color: "#fff !important",
  },
  centericons1: {
    background: "#007c7c",
    padding: "8px",
    textAlign: "center",
  },
  centericons2: {
    background: "#fff",
    padding: "8px",
    textAlign: "center",
    border: '1px solid #007c7c',
  },
  cardbox: {
    padding: '0px',
    marginBottom:'2px'
  },
  labelhead: {
    fontSize: '16px',
    fontWeight: 600,
    color: '#7E8281',    
  },
  labelheadScience: {
    fontSize: '16px',
    fontWeight: 600,
    color: '#8E44AD',    
  },
  labelheadMath: {
    fontSize: '16px',
    fontWeight: 600,
    color: '#D4AC0D',    
  },
  labelheadSocialScience: {
    fontSize: '16px',
    fontWeight: 600,
    color: '#AF601A',    
  },
  contentans: {
    marginBottom: '10px',
    fontSize: '12px',
  },
  radio: {
    '&$checked': {
      color: '#000'
    }
  },
  ulmainpoint: {
    listStyle: 'none',
  },
  ullicontent: {
    marginBottom: 8,
  },
  headingchapter: {
    borderBottom: "1px solid #e5e5e5",
    marginBottom: "11px",
    position: 'relative',
    color: '#007c7c',
  },

  paddzero: {
    padding: "9px",
  },
  closebtn: {
    width: "17px",
    position: "absolute",
    right: '0',
    top: "3px",
    paddingLeft: '47px',
  },
  imgcross: {
    width: 10,

  },
  chaptersdiv: {
    overflow: 'hidden',
  },
  imgplan2: {
    width: 15,
  },
  redalert: {
    color: '#007c7c',
    fontSize: "11px",
    fontWeight: 'normal',
    marginTop: "12px",
    textAlign: 'left',
  },
  borderchangetext: {
    fontSize: 14,
  },
  fontsizeheading: {
    fontSize: 16,
  },
  aligncentertext: {
    display: "flex",
    /* background: "#5c4033ab", */
    background: "#AF601A",
    width: "52px",
    height: "52px",
    borderRadius: "62px",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    color: "#fff",
    position: "absolute",
    top: "30px",
    left: "23px",
    fontSize: " 20px",
    fontWeight: "800",
    fontFamily: "math",
    opacity: '0.8',
    margin: '1px'
  },
  aligncentertextScience: {
    display: "flex",
  /*   background: "#000080", */
    /* background: "#A569BD", #95A5A6 */
    background: "#8E44AD", 
    width: "52px",
    height: "52px",
    borderRadius: "62px",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    color: "#fff",
    position: "absolute",
    top: "30px",
    left: "23px",
    fontSize: " 20px",
    fontWeight: "800",
    fontFamily: "math",
    opacity: '0.8',
    margin: '1px'
  },
  aligncentertextMath: {
    display: "flex",
    /* background: "#ffff00bf", #F1C40F */
    /* background: "#5499C7", */
    background: "#D4AC0D",
    width: "52px",
    height: "52px",
    borderRadius: "62px",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    /* color: "#000000", */color: "#fff",
    position: "absolute",
    top: "30px",
    left: "23px",
    fontSize: " 20px",
    fontWeight: "800",
    fontFamily: "math",
    opacity: '0.8',
    margin: '1px'
  }
}));


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
export default function TestHistory() {
  const navigate = useNavigate();
  const [showLoder, setShowLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [studentName, setStudentName] = useState('');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('firstName');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [testHistory, setTestHistory] = useState([]);
  const [testHistoryData, setTestHistoryData] = useState([]);
  /*   const [testHistoryGrouped, setTestHistoryGrouped] = useState([]); */
  const [userDetails, setUserDetails] = useState([]);
  const [initialize, setInitialize] = useState({ testFormats: [], standardSubjects: [] });
  const [open, setOpen] = React.useState(false);
  const [chapters, setChapters] = React.useState('');
  const [isFilter, setIsFilter] = React.useState(true);
  const [age, setAge] = React.useState('');
  const [filterSubject, setFilterSubject] = React.useState('');
  const [filterFormat, setFilterFormat] = React.useState('');
  const [idStudentSchool, setIdStudentSchool] = useState(0);
  const [isTeacher, setIsTeacher] = useState(false);
  const handleClickOpen = (chapters) => {
    setOpen(true);
    setChapters(chapters);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    setUserDetails(userInfo[0]?.StudentInfo);
    setStudentName(userInfo[0].StudentInfo[0]?.FirstName);
    fetchTestHistory(userInfo[0].StudentInfo[0]?.idStudentSchool);
    if (userInfo[0].StudentInfo[0]?.role === 'Teacher') {
      setIsTeacher(true);
    }
  }, []);
  useEffect(() => {
    let paramsLength = 0;
    if (filterSubject) {
      paramsLength += 1;
    }
    if (filterFormat) {
      paramsLength += 1;
    }
    let filterdData = []
    if (paramsLength > 0) {
      testHistoryData.forEach((detailData, index) => {
        let filterExists = false;
        if (paramsLength === 3) {
          if (detailData.SubjectId === filterSubject && detailData.PFormatId === filterFormat) {
            filterExists = true;
          }
        }
        else if (paramsLength === 2) {
          if (detailData.SubjectId === filterSubject && detailData.PFormatId === filterFormat) {
            filterExists = true;
          }
          if (detailData.PFormatId === filterFormat) {
            filterExists = true;
          }
          if (detailData.SubjectId === filterSubject) {
            filterExists = true;
          }
        }
        else if (paramsLength === 1) {
          if (filterSubject) {
            if (detailData.SubjectId === filterSubject) {
              filterExists = true;
            }
          }
          else if (filterFormat) {
            if (detailData.PFormatId === filterFormat) {
              filterExists = true;
            }
          }
        }
        if (filterExists) {
          filterdData.push(detailData);
        }
      });
    }
    else {
      filterdData = testHistoryData;
    }
    setTestHistory(filterdData);
  }, [filterSubject, filterFormat]);

  const clearFilterVal = () => {
    setPage(0);
    setFilterSubject('');
    setFilterFormat('');
    setInitialize({ testFormats: [], standardSubjects: [] });
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    fetchTestHistory(idStudentSchool);
  }


  const setFilterVal = (data) => {
    setPage(0);
    let params = "";
    let paramsLength = 0;
    const subjectText = "subjectID=";
    const formatText = "formatID=";
    let sep = "";
    if (filterSubject) {
      params = sep + subjectText + filterSubject;
      paramsLength += 1;
      sep = "&";
    }

    if (filterFormat) {
      params += sep + formatText + filterFormat;
      paramsLength += 1;
      sep = "&";
    }
    // fetchTestHistory(params);
    let filterdData = []
    if (paramsLength > 0) {
      testHistoryData.forEach((detailData, index) => {
        let filterExists = false;
        if (paramsLength === 3) {
          if (detailData.SubjectId === filterSubject && detailData.PFormatId === filterFormat) {
            filterExists = true;
          }
        }
        else if (paramsLength === 2) {
          if (detailData.SubjectId === filterSubject && detailData.PFormatId === filterFormat) {
            filterExists = true;
          }
          if (detailData.PFormatId === filterFormat) {
            filterExists = true;
          }
          if (detailData.SubjectId === filterSubject) {
            filterExists = true;
          }
        }
        else if (paramsLength === 1) {
          if (filterSubject) {
            if (detailData.SubjectId === filterSubject) {
              filterExists = true;
            }
          }
          else if (filterFormat) {
            if (detailData.PFormatId === filterFormat) {
              filterExists = true;
            }
          }
        }
        if (filterExists) {
          filterdData.push(detailData);
        }
      });
    }
    else {
      filterdData = testHistoryData;
    }
    setTestHistory(filterdData);
  }
  const handleSelectAllClick = (event, papers) => {
    if (event.target.checked) {
      const newSelecteds = papers.map((n) => n.idQuestionPaperDelivered);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const setStuName = (event, name) => {
    if (event) {
      setPage('');
      setFilterSubject('');
      setFilterFormat('');
      setInitialize({ testFormats: [], standardSubjects: [] });
      setStudentName(name);
      getIdStudentSchool(name);
    }

  };
  const getIdStudentSchool = (name) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let idStudentSchool = "";
    userInfo[0]?.StudentInfo.forEach((element, index) => {
      if (element.FirstName.trim() === name) {
        idStudentSchool = element.idStudentSchool;
      }
    });
    if (idStudentSchool) {
      fetchTestHistory(idStudentSchool);
    }
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - testHistory.length) : 0;

  const filteredUsers = applySortFilter(testHistory, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;
  const classes = useStyles();
  const fetchTestHistory = (idStudentSchool) => {
    setShowLoader(true);
    try {
      const reqsBody = {
        "idStudentSchool": idStudentSchool,
      };
      setIdStudentSchool(idStudentSchool);
      historyApi.historyTest(reqsBody).then(
        (resp) => {
          setShowLoader(false);
          if (resp.status === 200) {
            if (resp.data && resp.data.status === 200) {
              if (resp.data.data !== "NA") {
                const filtersObject = additionalFilter(resp.data.data);
                const dataArray = resp.data.data;
                dataArray.forEach((detailData, index) => {
                  // eslint-disable-next-line array-callback-return
                  filtersObject?.standardSubjects.forEach((element, index) => {
                    if (element?.name?.trim() === detailData?.SubjectName?.trim()) {
                      detailData.SubjectId = element.id;
                    }
                  });

                  // eslint-disable-next-line array-callback-return
                  filtersObject?.testFormats.forEach((element, index) => {
                    if (element.name.trim() === detailData.PFormat.trim()) {
                      detailData.PFormatId = element.id;
                    }
                  });
                });
                resp.data.data = dataArray;
                setTestHistory(resp.data.data);
                setTestHistoryData(resp.data.data);
              }
              else {
                setTestHistory([]);
                setTestHistoryData([]);
              }
            }
            else {
              alert(resp.data.message);
              setShowLoader(false);
            }

          }
        },
        (error) => {
          alert(error.response.data.error);
          setShowLoader(false);
        }
      );
    }
    catch (error) {
      console.log("Test History Catch", error);
    }
  }

  const additionalFilter = (data) => {
    if (data) {
      setFilterSubject('');
      setFilterFormat('');
      const initializeData = { testFormats: [], standardSubjects: [] };
      initializeData.standardSubjects = filterDataKeyName(data, "SubjectName");
      initializeData.testFormats = filterDataKeyName(data, "PFormat");
      setInitialize(initializeData);
      setIsFilter(false);
      return initializeData;
    }
  }
  const filterDataKeyName = (data, keyName) => {
    const tempArray = [];
    data.forEach((element, index) => {
      if (tempArray && tempArray.length > 0) {
        const found = tempArray.some(el => el.name === element[keyName]);
        if (!found) tempArray.push({ id: index + 1, name: element[keyName]?.trim() });
      }
      else {
        tempArray.push({ id: index + 1, name: element[keyName]?.trim() });
      }
    });
    return tempArray;
  }
  const reviewAnswer = (data) => {
    data.idStudentSchool = idStudentSchool;
    navigate('/dashboard/review-answer', { replace: true, state: data });
  }
  const viewQuestion = (idQuestionPaperDelivered) => {
    const reqsBody = {
      "idTestPaper": idQuestionPaperDelivered,
      "idStudentSchool": idStudentSchool,
    };
    setShowLoader(true);
    historyApi.genhtml(reqsBody).then(
      (resp) => {
        if (resp.status === 200) {
          if (resp.data.status === 200) {
            setShowLoader(false);
            navigate('/dashboard/view-question', { replace: true, state: resp.data });
          }
        }
        else {
          setShowLoader(false);
          alert(resp.message);
        }
      },
      (error) => { setShowLoader(false); alert(error.response.data.error); }
    );
  }
  // console.log("testHistoryGrouped", JSON.stringify(testHistoryGrouped));
  const showMore = (data) => {
    alert(data);
  }
  const handleChange = (event) => {
    if (event.target.name === "subject") {
      setFilterSubject(event.target.value);
    }
    else if (event.target.name === "format") {
      setFilterFormat(event.target.value);
    }
    else {
      setAge(event.target.value)
    }
    console.log(event.target.value);
    console.log(event.target.name);

  };
  const publishToStudent = (idQuestionPaperDelivered, idClass) => {

    const reqsBody = {
      "idTestPaper": idQuestionPaperDelivered,
      "idStudentSchool": idStudentSchool,
    };
    setShowLoader(true);
    historyApi.genhtml(reqsBody).then(
      (resp) => {
        if (resp.status === 200) {
          if (resp.data.status === 200) {
            setShowLoader(false);
            resp.data.classId = idClass;
            resp.data.idStudentSchool = idStudentSchool;
            resp.data.pageType = 'TestHistory';
            if (isTeacher) {
              resp.data.role = 'Teacher';
            }
            navigate('/dashboard/view-question', { replace: true, state: resp.data });
          }
        }
        else {
          setShowLoader(false);
          alert(resp.message);
        }
      },
      (error) => { setShowLoader(false); alert(error.response.data.error); }
    );

  }
  return (
    <Page title="History Test | XPSOLV">

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} className={classes.borderbotmten} >
          <Box component="h2" display="inline" className={classes.fontsizeheading}>Test <Box component="span" display="inline" className={classes.headingpart}>History</Box></Box>
        </Stack>
        <Stack mb={5} className={classes.borderbotmten}>
          <Typography variant="h6" className={classes.headingtenw2}>Sea<Box component="span" display="inline" className={classes.headingpart}>rch</Box></Typography>
          <FormControl component="fieldset" className={classes.borderseprate}>

            <RadioGroup row aria-label="studentName" name="row-radio-buttons-group" defaultValue="" color="secondary"
              sx={{
                '&.Mui-checked': {
                  color: '#007c7c',
                },
              }}
            >
              {userDetails && userDetails?.map((item, index) => (
                <FormControlLabel key={index} className={classes.sepratelineStudent} spacing={5} onClick={(event) => setStuName(event, item?.FirstName, item?.LastName)} value={item?.FirstName} checked={item?.FirstName === studentName} control={<Radio />} label={item?.FirstName} />
              ))}
            </RadioGroup>

          </FormControl>
          <Typography variant="h6" className={classes.headingtenw2}>Additional <Box component="span" display="inline" className={classes.headingpart}>Filter</Box></Typography>
          <Grid container spacing={3} >
            <Grid item lg={3} xs={6} md={3} sm={6} className={classes.dotspace}>
              <FormLabel component="legend" className={classes.specing}>Subject</FormLabel>


              <FormControl fullWidth>
                <Select
                  className={classes.borderchangetext}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={handleChange}
                  name="subject"
                  value={filterSubject}
                  defaultValue=""
                >
                  <MenuItem value="" key='Subject'>
                    <em className={classes.borderchangetext} value="">Select Subject</em>
                  </MenuItem>
                  {initialize?.standardSubjects?.map((option, index) => (
                    <MenuItem key={index} value={option.id} className={classes.borderchangetext}>
                      {option.name}
                    </MenuItem>
                  ))}

                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={3} xs={6} md={3} sm={6} className={classes.dotspace}>
              <FormLabel component="legend" className={classes.specing}>Format</FormLabel>
              <FormControl fullWidth>
                <Select
                  className={classes.borderchangetext}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={handleChange}
                  name="format"
                  value={filterFormat}
                  defaultValue=""
                >
                  <MenuItem key='Format' value="">
                    <em className={classes.borderchangetext} value="">Select Format</em>
                  </MenuItem>
                  {initialize?.testFormats?.map((option, index) => (

                    <MenuItem key={index} value={option.id} className={classes.borderchangetext}>
                      {option.name}
                    </MenuItem>
                  ))}

                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={3} xs={6} md={3} sm={6} className={classes.dotspace}>
              <Button variant="contained" className={classes.buttoncomon} onClick={() => clearFilterVal()}>Clear All</Button>
            </Grid>
          </Grid>
        </Stack>
        <>          
          <Scrollbar className={classes.hidedesktop}>
            <TableContainer sx={{ minWidth: 800 }} className={classes.bordertable}>
              <Table>
                <UserListHead className={classes.headtable}
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={testHistory.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={(e) => handleSelectAllClick(e)} />
                <TableBody >

                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { idQuestionPaperDelivered, DateDeliveredOn, SubjectName, ChapterInfo, PFormat, Published, idClass, Section } = row;
                      const isItemSelected = selected.indexOf(idQuestionPaperDelivered) !== -1;
                      return (
                        <TableRow
                          className={classes.ternten}
                          hover
                          key={idQuestionPaperDelivered}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell className={classes.lefttext} component="th" scope="row" padding="none" align="center">

                            <Typography variant="subtitle2" align="center" className={classes.maxWidth}>
                              {SubjectName}
                            </Typography>

                          </TableCell>
                          <TableCell className={classes.lefttext} component="th" scope="row" padding="none" align="center">
                            <Typography variant="subtitle2" align="center" className={classes.maxWidth}>
                              {idQuestionPaperDelivered}
                            </Typography>

                          </TableCell>
                          <TableCell align="center" className={classes.widthaccordian}>
                            <Typography variant="subtitle2" align="center" className={classes.maxWidth21}>
                              <Tooltip title="Chapters" arrow><Button variant="contained" className={classes.btnchapter2} onClick={() => handleClickOpen(ChapterInfo)}  > <img src="/static/mock-images/avatars/list-check.png" alt="imgtest" className={classes.imgplan2} /></Button></Tooltip>

                            </Typography>


                          </TableCell>
                          <TableCell className={classes.lefttext} align="center">{DateDeliveredOn}</TableCell>
                          <TableCell className={classes.lefttext} align="center">{PFormat}</TableCell>
                          <TableCell className={classes.lefttext} align="center">{Section}</TableCell>
                          <TableCell className={classes.lefttext} align="right">

                            <IconButton className={classes.iconcolor} aria-label="View" color="primary" onClick={() => viewQuestion(idQuestionPaperDelivered)}> <Tooltip title="View" arrow><VisibilityIcon /></Tooltip></IconButton>
                            <IconButton className={classes.iconcolor} aria-label="Review" onClick={() => reviewAnswer(row)}><Tooltip title="Check Answers" arrow><FactCheckIcon /></Tooltip></IconButton>
                            {isTeacher && <> {Published === 0 ? <IconButton className={classes.iconcolor} aria-label="Email" color="secondary" onClick={() => publishToStudent(idQuestionPaperDelivered, idClass)}><Tooltip title="Publish" arrow><CardMedia image="/static/mock-images/avatars/sending2.png" style={{ width: '22px', height: '22px' }} /></Tooltip></IconButton> : <IconButton className={classes.disablediconcolor} aria-label="Review" disabled ><Tooltip title="Publish" arrow><CardMedia image="/static/mock-images/avatars/sending1.png" style={{ width: '22px', height: '22px' }} /></Tooltip></IconButton>} </>}

                          </TableCell>
                        </TableRow>


                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={testHistory.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {/* </>
          ))} */}
        </>

        {filteredUsers
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, index) => {
            const { idQuestionPaperDelivered, DateDeliveredOn, SubjectName, ChapterInfo, PFormat, Published, idClass, Section } = row;
            const isItemSelected = selected.indexOf(idQuestionPaperDelivered) !== -1;
            return (
              <Card key={index} className={classes.hidemobile}>
                <div className={classes.cardbox}>
                  <CardHeader className={classes.cardheader}
                    subheader=<p>{new Date(DateDeliveredOn.substr(0,20)).toLocaleDateString('en-us', { day:"numeric", year:"numeric", month:"short"})}, {new Date(DateDeliveredOn.substr(0,20)).toLocaleTimeString()}</p>
                  />
                  <Grid container className={classes.margingap}>
                    <Grid item xs={4} style={{ position: 'relative' }}>
                      <Typography variant="p" className={classes.headingtenw4} style={{ display: 'flex', }}>
                        <img src="/static/mock-images/avatars/test.png" alt="imgtest" className={classes.imgplan2323} />

                      </Typography>
                      {SubjectName === 'Science' && <Typography variant="p" className={classes.aligncentertextScience} style={{ display: 'flex', }}>
                        {idQuestionPaperDelivered}
                      </Typography>}
                      {SubjectName === 'Mathematics' && <Typography variant="p" className={classes.aligncentertextMath} style={{ display: 'flex', }}>
                        {idQuestionPaperDelivered}
                      </Typography>}
                      {SubjectName === 'Social Science' && <Typography variant="p" className={classes.aligncentertext} style={{ display: 'flex', }}>
                        {idQuestionPaperDelivered}
                      </Typography>}
                    </Grid>


                    <Grid item xs={8} >
                      <Grid container spacing={1}>
                        <Grid item xs={12} >
                         {/*  <Typography variant="p" component="div" className={classes.labelhead}>
                            {SubjectName}
                          </Typography> */}
                         
                      {SubjectName === 'Science' && <Typography variant="p" component="div" className={classes.labelheadScience}>
                        {SubjectName}
                      </Typography>}
                      {SubjectName === 'Mathematics' && <Typography variant="p" className={classes.labelheadMath}>
                        {SubjectName}
                      </Typography>}
                      {SubjectName === 'Social Science' && <Typography variant="p" className={classes.labelheadSocialScience}>
                        {SubjectName}
                      </Typography>} 

                        </Grid>
                        <Grid item xs={12} >
                          <Typography variant="p" component="div" className={classes.contentans}>
                            {PFormat}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} >
                          <Typography variant="p" component="div" className={classes.labelhead}>
                            <Typography variant="p" component="div" className={classes.contentans}>
                              <Typography className={classes.truncate} ><Button variant="outlined" className={classes.btnchapter} onClick={() => handleClickOpen(ChapterInfo)} >Chapter List</Button></Typography>
                            </Typography>
                          </Typography>
                        </Grid>                        
                      </Grid>

                    </Grid>
                  </Grid>
                </div>
                <Grid container className={classes.maincolor}>
                  <Grid item xs={isTeacher ? 4 : 6} className={classes.centericons1}>
                    <IconButton className={classes.iconcolor} title="View" aria-label="View" color="primary" onClick={() => viewQuestion(idQuestionPaperDelivered)}><VisibilityIcon />View</IconButton>
                  </Grid>
                  <Grid item xs={isTeacher ? 4 : 6} className={classes.centericons2}>
                    <IconButton className={classes.iconcolor2} title="Check Answers" aria-label="Review" onClick={() => reviewAnswer(row)}><FactCheckIcon />Check Answer</IconButton>
                  </Grid>
                  {isTeacher && <Grid item xs={isTeacher ? 4 : 6} className={classes.centericons1}>
                    {Published === 0 ? <IconButton className={classes.iconcolor} aria-label="Email" color="secondary" onClick={() => publishToStudent(idQuestionPaperDelivered, idClass)}><Tooltip title="Publish" arrow><CardMedia image="/static/mock-images/avatars/sending3.png" style={{ width: '22px', height: '22px' }} /></Tooltip>Publish</IconButton> : <IconButton className={classes.disablediconcolor} aria-label="Review" disabled ><Tooltip title="Publish" arrow><CardMedia image="/static/mock-images/avatars/sending1.png" style={{ width: '22px', height: '22px' }} /></Tooltip>Publish</IconButton>}
                  </Grid>}
                </Grid>
              </Card>
            );
          })}
        <>
          <Dialog
            className={classes.chaptersdiv}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div className={classes.headingchapter}>
              <DialogTitle id="alert-dialog-title" className={classes.paddzero}>
                Chapters
              </DialogTitle>
              <DialogActions className={classes.closebtn}>
                <Button onClick={handleClose}><img src="/static/mock-images/avatars/preview.png" alt="imgtest" className={classes.imgcross} /></Button>
              </DialogActions>
            </div>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <ul className={classes.ulmainpoint} >

                  {chapters && chapters?.map((item, index) =>
                    <li key={index} className={classes.ullicontent}><Chip label={item.ChapterName} variant="outlined" />
                    </li>)}

                </ul>
              </DialogContentText>
            </DialogContent>

          </Dialog>
        </>
      </Container>
      <Loader isLoader={showLoder} showHide={setShowLoader} />
    </Page>
  );
}
