import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

export default function ShippingAndDelivery() {

    return (
        <div style={{marginTop:120,padding:' 0 50px',}}>
            <Link to="/login" component={RouterLink} style={{display: "flex", width: "75px", padding: "9px 7px 0 6px", lineHeight: "24px", background: "#089293", border: "none", color: "#fff",borderRadius: 6,marginBottom: "14px"}}> <img src="/static/mock-images/avatars/angle-left-free-icon-font.png" alt="imgtest"  style={{width:15,marginTop: "3px", marginRight: "6px",marginBottom: "11px"}}/>back</Link>
            <h2>Shipping And Delivery Policy</h2>

            <h3 style={{padding:' 15px 0',fontSize: 16, color:'#797D7F'}}>Last updated on Dec 3rd 2022</h3>
                      
            <div>                
                <p style={{fontSize:14,marginBottom:15}}>Shipping is not applicable for business.</p>
            </div>            
                       
        </div>
    );
}