import { Grid, Link, Paper, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';


export default function Refund() {

    return (
        <div style={{ marginTop: 120, padding: ' 0 50px', }}>
            <Link to="/login" component={RouterLink} style={{ display: "flex", width: "75px", padding: "9px 7px 0 6px", lineHeight: "24px", background: "#089293", border: "none", color: "#fff", borderRadius: 6, marginBottom: "14px" }}> <img src="/static/mock-images/avatars/angle-left-free-icon-font.png" alt="imgtest" style={{ width: 15, marginTop: "3px", marginRight: "6px", marginBottom: "11px" }} />back</Link>
            <h2>Cancellation & Expiration, Refund Policy</h2>

            <h3 style={{ padding: ' 15px 0', fontSize: 14, color: '#797D7F' }}>Last updated on Dec 15, 2022</h3>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Paper elevation={2} sx={{ padding: 1 }}>

                        <Typography sx={{ mb: 1.5, fontSize: 14, }} color="text.primary">
                            <Typography variant='button'>No refund or cancellation</Typography> is permitted once the user has completed transaction(s) at Xpsolv equivalent to balance amount available in his/her account (Valid only for purchases made through Xpsolv).
                        </Typography>

                        <Typography sx={{ mb: 2, fontSize: 14 }} color="text.primary">
                            <ul>
                                <li style={{ fontSize: 14, marginBottom: 10, marginLeft: 25, }}>User needs to contact customer support through e-mail (<a href="mailto:connect@xpsolv.com">connect@xpsolv.com</a> ) to initiate the Cancellation & Refund Request. Request is allowed when it is raised only with <a href="mailto:connect@xpsolv.com">connect@xpsolv.com</a>. </li>
                                <li style={{ fontSize: 14, marginBottom: 10, marginLeft: 25, }}>It is user’s sole responsibility to ensure that the account information provided are accurate, complete, and latest in email.</li>
                                <li style={{ fontSize: 14, marginBottom: 10, marginLeft: 25, }}>Your account balance will be valid and available for use until a request for Account Termination/ Cancellation & Refund is not received through e-mail (connect@xpsolv.com ). Unless your request is not processed & settled by Xpsolv support team, you authorize us to charge the next transaction from your available balance.</li>
                                <li style={{ fontSize: 14, marginBottom: 10, marginLeft: 25, }}>The validity of account balance for use, shall begin from the transaction date to add amount to the active account by user and shall continue for 3 months. It is expressly disclaimed that the validity is not being offered as per the tenure of academic session/year and the account balance shall stand lapsed on the expiry of validity period, notwithstanding the fact that the academic session/year of the User has not been completed. It is for the User to manage the account balance after conducting their own diligence and verifying its suitability as per their academic session/year.</li>
                                <li style={{ fontSize: 14, marginBottom: 10, marginLeft: 25, }}>You acknowledge that Your ability to use Your account is dependent upon external factors such as internet service providers and internet network availability and the Company cannot always guarantee accessibility to the Application. In addition to the disclaimers set forth in the Terms, the Company shall not be liable to You for any damages arising from Your inability to log into Your account and access the services of the Application at any time.</li>
                            </ul>
                        </Typography>

                        <Typography sx={{ mb: 1.5, fontSize: 14 }} color="text.primary">
                            The pricing plan is made available to you on our pricing page (<Link href='https://xpsolv.com/pricing'>https://xpsolv.com/pricing</Link>) and may vary from time to time. You agree to pay at the rates notified to you at the time of your usage.
                        </Typography>

                    </Paper>
                </Grid>

            </Grid>

            <div>
                &nbsp;{/* <p style={{ fontSize: 14, }}>&nbsp;</p> */}
            </div>

        </div>
    );
}