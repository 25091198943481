import xpSolvService from "../axios/axios";

class AuthService {

    generateOTP(data) {
        return Promise.resolve(xpSolvService.post('otp/generateotp', data));
    }

    signIn(data) {
        return Promise.resolve(xpSolvService.post('signininfo', data));
    }

    register(data) {
        return Promise.resolve(xpSolvService.post('signup/register/students', data));
    }
}

export default AuthService;