import { filter } from 'lodash';
import * as React from 'react';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import VisibilityIcon from '@mui/icons-material/Visibility';

// material
import {
  Card,
  Stack,
  Button,
  Grid,
  Container,
  Typography,
  Box,
  TableContainer,
  TablePagination,
  Table,
  TableBody,
  TableRow,
  TableCell,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CardHeader,
  Avatar,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { UserListHead } from '../components/_dashboard/user';

// components
import Page from '../components/Page';
import Loader from '../components/Loader';
import Scrollbar from '../components/Scrollbar';
import { historyApi, TestApi, utilApi } from '../service/index'
import SearchNotFound from '../components/SearchNotFound';

const TABLE_HEAD = [
  { id: 'Teacher', label: 'Teacher', alignRight: false },
  { id: 'SubjectName', label: 'Subject', alignRight: false },
  { id: 'ChapterInfo', label: 'Chapters', alignRight: false },
  { id: 'DateDeliveredOn', label: 'Date', alignRight: false },
  { id: 'PFormat', label: 'Format', alignRight: false },
  { id: '', label: 'Actions' }
];



const getIcon = (name) => <Icon color="#999" icon={name} width={22} height={22} />;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  paper: {
    padding: theme.spacing(2)
  },
  headingpart: {
    color: '#007c7c',

  },
  bordertable: {
    border: '1px solid #e4e4e4',
    marginBottom: '20px',
    borderRadius: '10px',
  },
  headtable: {
    background: '#f5f6fa',
  },
  afterbeforeshape2: {
    background: '#007c7c',
    margin: '20px 0',
    width: 'max-content',
    padding: '8px 20px',
    color: '#fff',
    borderRadius: 8,
    position: 'relative',
    fontSize: 13,
  },
  afterbeforeshape: {
    position: 'absolute',
    heihgt: 0,
    width: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderTop: '13px solid #007c7c',
    top: '115%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  dotspace: {
    marginTop: '16px',
  },
  headingtenw: {
    marginTop: '16px',
  },
  buttoncomon: {
    marginTop: 23,
    padding: '15px 45px',
    fontWeight: 500,
    background:'#007c7c',
  },
  borderbotmten: {
    borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
    paddingBottom: 20,
    marginBottom: 15,

  },
  headingtenw2: {
    marginTop: 11,
    fontSize: 16,
  },
  datepiker: {
    width: '100%',
  },
  width100: {
    width: '30%',
  },
  widthaccordian: {
    width: '15%',
  },
  truncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '260px',
  },
  maxWidth: {
    width: '100%',
    textAlign: 'left',
    margin: 24,
  },
  maxWidth21: {
    width: '100%',
    textAlign: 'left',
    margin: 10,
  },
  btnchapter:{
    fontSize: "12px", 
    fontWeight: "600", 
    padding: "5px 10px"
  },
  btnchapter3:{
    fontSize: "12px", 
    fontWeight: "600", 
    padding: "5px 10px",
    marginLeft:7,
    height:31,

  },
  btnchapter2:{
    fontSize: "12px", 
    fontWeight: "600", 
    padding: "5px 10px",
    minWidth: '31px'
  },
  maxWidth2: {
    width: '100%',
    textAlign: 'left !important',
  },
  truncate2: {
    background: 'transparent',
    padding: 0,
  },
  truncate3: {
    padding: 0,
  },
  ternten: {
    verticalAlign: 'text-top',
  },
  hide: {
    display: 'none',
  },
  seprateline: {
    borderRight: '1px solid #d9d9d9',
    paddingRight: '35px',
    fontSize: 14,
  },
  borderseprate: {
    borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
    paddingBottom: "12px",
  },
  specing: {
    fontSize: 13,
  },
  lefttext: {
    textAlign: 'left',
    whiteSpace:'nowrap',
  },
  iconexpand: {
    width: '47px',
    float: 'left',
    marginRight: '-4px',
    marginTop: '-12px'
  },
  iconcolor: {
    color: '#fff',
    [theme.breakpoints.up('sm')]: {
      color: '#007c7c',
    },
  },
  hidedesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  hidemobile: {
    display: 'block',
    marginBottom:"14px",
  
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  cardheader: {
    padding: '0px',
  },
  margingap: {
    marginTop: '20px',
    background: '#ffffff',
    padding: '16px 16px 0 16px',
    borderRadius: '14px',
    marginBottom: '14px',
    border:'1px solid #dce0e4',
    paddingBottom:24,
  },
  centericons: {
    textAlign: 'center',
  },
  cardbox:{
    padding: '24px 25px 0',
  },
  maincolor:{
    background:'#007c7c',
  },
  labelhead:{
    fontSize: '13px',
    fontWeight: 600,
    color: '#007c7c',
  },
  contentans:{
    marginBottom:'10px',
  },
  radio: {
    '&$checked': {
      color: '#000'
    }
  },
  ulmainpoint:{
    listStyle:'none',
  },
  ullicontent:{
    marginBottom:8,
  },
  headingchapter:{
    borderBottom: "1px solid #e5e5e5", 
    marginBottom: "11px", 
  position:'relative',
    color:'#007c7c',
  },

  paddzero:{
    padding: "9px",
  },
  closebtn:{
    width: "17px", 
  position: "absolute", 
  right: '0',
  top: "3px",
  paddingLeft: '47px',
  },
  imgcross:{
width:10,

  },
  chaptersdiv:{
    overflow:'hidden',
  },
  imgplan2:{
    width:15,
  },
  borderchangetext:{
    fontSize:14,
  },
  fontsizeheading:{
    fontSize:16,
 },
}));


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
export default function TestHistory() {
  const navigate = useNavigate();
  const [showLoder, setShowLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [studentName, setStudentName] = useState('');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('firstName');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [testHistory, setTestHistory] = useState([]);
  const [testHistoryData, setTestHistoryData] = useState([]);
  const [testHistoryGrouped, setTestHistoryGrouped] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [initialize, setInitialize] = useState({ publishers: [], standardSubjects: [] });
  const [open, setOpen] = React.useState(false);
  const [chapters, setChapters] = React.useState('');
  const [isFilter, setIsFilter] = React.useState(true);
  const [age, setAge] = React.useState('');
  const [filterSubject, setFilterSubject] = React.useState('');
  const [filterPublisher, setPublisher] = React.useState('');
  const [idStudentSchool, setIdStudentSchool] = useState(0);


  const handleClickOpen = (chapters) => {
    setOpen(true);
    setChapters(chapters);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clearFilterVal = () => 
  {
    setPage(0);
    setFilterSubject('');
    setPublisher('');
    setInitialize({ publishers: [], standardSubjects: [] });
    getIdStudentSchool("");
  }
  
  const getIdStudentSchool = (firstName, lastName) => 
  {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    userInfo[0]?.StudentInfo.forEach((element, index) => 
    {
      if(element.FirstName.trim() === firstName && element.LastName.trim() === lastName)
      {
        fetchTestHistory(element.idStudentSchool);
      }
    }); 
  }

  const handleSelectAllClick = (event, papers) => {
    if (event.target.checked) {
      const newSelecteds = papers.map((n) => n.idQuestionPaperDelivered);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const setStuName = (event, name) => {
    setStudentName(name);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - testHistory.length) : 0;

  const filteredUsers = applySortFilter(testHistory, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;
  const classes = useStyles();
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    setUserDetails(userInfo[0]?.StudentInfo);
    setStudentName(userInfo[0].StudentInfo[0]?.FirstName); 
    fetchTestHistory(userInfo[0].StudentInfo[0]?.idStudentSchool);
  }, []);

  useEffect(() => 
  {  
    let paramsLength = 0;
    if(filterSubject)
    {
      paramsLength +=1;
    }
    if(filterPublisher)
    {
      paramsLength +=1;
    }
    let filterdData = []
    if(paramsLength > 0)
    {
      testHistoryData.forEach((detailData, index) => 
      {
        let filterExists = false;
        if(paramsLength === 2)
        {
          if(detailData.SubjectId === filterSubject && detailData.PublisherId === filterPublisher)
          {
            filterExists = true;
          }  
        }  
        else if(paramsLength === 1)
        {
          if(filterSubject)
          {
            if(detailData.SubjectId === filterSubject) 
            {
              filterExists = true;
            }
          }
          else if (filterPublisher)
          {
              if(detailData.PublisherId === filterPublisher)
              {
                filterExists = true;
              }
          }
        } 
        if(filterExists)
        {
          filterdData.push(detailData);
        }
      }); 
    }
    else
    {
      filterdData = testHistoryData;
    }
    setTestHistory(filterdData);
  }, [filterSubject,filterPublisher]);

const filterData = (data)=>
{
  const subjectNameKay = Object.keys(data);
  const subjectNameValue = Object.values(data);
  const entries = Object.entries(data);
  const tempArray = [];
  entries.forEach(element => {
    tempArray.push({id:element[0],name:element[1]});
    
  });
  return tempArray;

}


const setStudent = (event, firstName, lastName) => {
  if(event)
  {
      setPage('');
      setFilterSubject('');
      setInitialize({ testFormats: [], standardSubjects: [] });
      setStudentName(firstName);
      getIdStudentSchool(firstName , lastName);
  }
  
};

  const fetchTestHistory = (params) => {
    setShowLoader(true);
    try 
    {
      const reqsBody = {
				"idStudentSchool": params,
			};
      setIdStudentSchool(params);
      historyApi.testReceived(reqsBody).then(
        (resp) => {
          setShowLoader(false);
          if (resp.status === 200) {
            if (resp.data.status === 200) 
            { 
              if(resp.data.data !== "NA")
              {
                const filtersObject = additionalFilter(resp.data.data);
                const dataArray = resp.data.data;
                dataArray.forEach((detailData, index) => 
                {
                  // eslint-disable-next-line array-callback-return
                  filtersObject?.standardSubjects.forEach((element, index) => 
                  {
                    if(element?.name?.trim() === detailData?.SubjectName?.trim() ) 
                    {
                      detailData.SubjectId = element.id;
                    }
                  }); 

                  // eslint-disable-next-line array-callback-return
                  filtersObject?.publishers.forEach((element, index) => 
                  {
                    if(element.name.trim() === detailData.Teacher.trim() ) 
                    {
                      detailData.PublisherId = element.id;
                    }
                  });
                });  
                resp.data.data = dataArray;
                setTestHistory(resp.data.data);
                setTestHistoryData(resp.data.data);
              }
            }
            else {
              alert(resp.data.message);
              setShowLoader(false);
            }
          }
        },
        (error) => { alert("Error in test history"); 
        setShowLoader(false);
      }
      );
    }
    catch (error) {
      console.log("Test History Catch", error);
    }
  }

  const additionalFilter = (data) => 
  {
    if (data) {
      setFilterSubject('');
      setPublisher('');
      const initializeData = { publishers: [], standardSubjects: [] };
      initializeData.standardSubjects = filterDataKeyName(data,"SubjectName");
      initializeData.publishers = filterDataKeyName(data,"Teacher") ;
      setInitialize(initializeData);
      setIsFilter(false);
      return initializeData;
    }
  }
  const filterDataKeyName = (data,keyName)=>
  {
    const tempArray = [];
    data.forEach((element, index) => 
    {
        if(tempArray && tempArray.length > 0)
        {
          const found = tempArray.some(el => el.name ===  element[keyName]);
          if (!found) tempArray.push({id:index+1,name:element[keyName]?.trim()});
        }
        else
        {
          tempArray.push({id:index+1,name:element[keyName]?.trim()}); 
        }
    });
    return tempArray;
  }
  const reviewAnswer = (data) => {
    navigate('/dashboard/review-answer', { replace: true, state: data });
  }
  const viewQuestion = (idQuestionPaperDelivered) => 
  {
    const reqsBody = {
      "idTestPaper": idQuestionPaperDelivered,
      "idStudentSchool": idStudentSchool
    }; 
    setShowLoader(true);
    historyApi.genhtml(reqsBody).then(
      (resp) => 
      {
        if (resp.status === 200) {
          if (resp.data.status === 200) 
          {
            setShowLoader(false);
            navigate('/dashboard/view-question', { replace: true,state:resp.data});
          }
        }
        else 
        {
          setShowLoader(false);
          alert(resp.message);
        }
      },
      (error) => { setShowLoader(false); alert("error in  view question"); }
    );   
  }
  // console.log("testHistoryGrouped", JSON.stringify(testHistoryGrouped));
  const showMore = (data) => {
    alert(data);
  }
  const handleChange = (event) => 
  { 
    if(event.target.name === "subject")
    {
      setFilterSubject(event.target.value);
    }
    else if(event.target.name === "publisher")
    {
      setPublisher(event.target.value);
    }
    else{
      setAge(event.target.value)
    }
    console.log(event.target.value);
    console.log(event.target.name);
   
  };
  return (
    <Page title="History Test | XPSOLV">

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} className={classes.borderbotmten} >
          <Box component="h2" display="inline" className={classes.fontsizeheading}>Test <Box component="span" display="inline" className={classes.headingpart}>Received</Box></Box>
        </Stack>
        <Stack mb={5} className={classes.borderbotmten}>
        <Typography variant="h6" className={classes.headingtenw2}>Sea<Box component="span" display="inline" className={classes.headingpart}>rch</Box></Typography>
          <FormControl component="fieldset" className={classes.borderseprate}>

            <RadioGroup row aria-label="studentName" name="row-radio-buttons-group" defaultValue=""  color="secondary"
             sx={{
              '&.Mui-checked': {
                color: '#007c7c',
              },
            }}
            >
              {userDetails && userDetails?.map((item, index) => (
                <FormControlLabel key={index} className={classes.sepratelineStudent} spacing={5} onClick={(event) => setStudent(event, item?.FirstName,item?.LastName)} value={item?.FirstName} checked={item?.FirstName === studentName}  control={<Radio />} label={item?.FirstName} />
              ))}                         
            </RadioGroup>
          </FormControl>
          <Typography variant="h6" className={classes.headingtenw2}>Additional <Box component="span" display="inline" className={classes.headingpart}>Filter</Box></Typography>
          <Grid container spacing={3} >
            <Grid item lg={3} xs={6} md={3} sm={6} className={classes.dotspace}>
              <FormLabel component="legend" className={classes.specing} >Subject</FormLabel>
              <FormControl fullWidth>
                <Select
                className={classes.borderchangetext}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={handleChange}
                  name="subject"
                  value={filterSubject}
                  defaultValue=""
                >
                  <MenuItem value="">
                    <em className={classes.borderchangetext} value="">Select Subject</em>
                  </MenuItem>
                  {initialize?.standardSubjects?.map((option, index) => (
                    <MenuItem key={index} value={option.id} className={classes.borderchangetext}>
                      {option.name}
                    </MenuItem>
                  ))}

                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={3} xs={6} md={3} sm={6} className={classes.dotspace}>
              <FormLabel component="legend" className={classes.specing}>Published By</FormLabel>
              <FormControl fullWidth>
                <Select
                className={classes.borderchangetext}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={handleChange}
                  name="publisher"
                  value={filterPublisher}
                  defaultValue=""
                >
                  <MenuItem value="">
                    <em className={classes.borderchangetext} value="">Select Published By</em>
                  </MenuItem>
                  {initialize?.publishers?.map((option, index) => (
                    <MenuItem key={index} value={option.id} className={classes.borderchangetext}>
                      {option.name}
                    </MenuItem>
                  ))}

                </Select>
              </FormControl>
            </Grid>

       
            <Grid item lg={3} xs={6} md={3} sm={6} className={classes.dotspace}>
               <Button variant="contained" className={classes.buttoncomon} onClick={() => clearFilterVal()}>Clear All</Button> 
            </Grid>
          </Grid>
        </Stack>
        <>
          {/* {testHistoryGrouped?.map((itemList) => (
            <>
              <div className={classes.afterbeforeshape2}>{itemList?.name}
                <div className={classes.afterbeforeshape}> </div>
              </div> */}
          <Scrollbar className={classes.hidedesktop}>
            <TableContainer sx={{ minWidth: 800 }} className={classes.bordertable}>
              <Table>
                <UserListHead className={classes.headtable}
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={testHistory.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={(e) => handleSelectAllClick(e)} />
                <TableBody >

                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const { idQuestionPaperDelivered, Teacher, DateDeliveredOn, SubjectName, ChapterInfo, PFormat } = row;
                      const isItemSelected = selected.indexOf(idQuestionPaperDelivered) !== -1;
                      return (
                        <TableRow
                          className={classes.ternten}
                          hover
                          key={idQuestionPaperDelivered+index}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >

                          <TableCell className={classes.lefttext} component="th" scope="row" padding="none" align="center">

                            <Typography variant="subtitle2" align="center" className={classes.maxWidth}>
                              {Teacher}
                            </Typography>

                          </TableCell>
                          <TableCell className={classes.lefttext} component="th" scope="row" padding="none" align="center">

                            <Typography variant="subtitle2" align="center" className={classes.maxWidth}>
                              {SubjectName}
                            </Typography>

                          </TableCell>

                          <TableCell align="center" className={classes.widthaccordian}>
                          <Typography variant="subtitle2" align="center" className={classes.maxWidth21}>
                            <Tooltip title="Chapters"  arrow><Button variant="contained" className={classes.btnchapter2} onClick={()=>handleClickOpen(ChapterInfo)}  > <img src="/static/mock-images/avatars/list-check.png" alt="imgtest" className={classes.imgplan2} /></Button></Tooltip>
                          </Typography>
                          </TableCell>
                          <TableCell className={classes.lefttext} align="center">{DateDeliveredOn}</TableCell>
                          <TableCell className={classes.lefttext} align="center">{PFormat}</TableCell>
                          <TableCell className={classes.lefttext} align="right">
                           <IconButton className={classes.iconcolor} aria-label="View" color="primary" onClick={() => viewQuestion(idQuestionPaperDelivered)}> <Tooltip title="View"  arrow><VisibilityIcon /></Tooltip></IconButton>
                            {/* <IconButton className={classes.iconcolor} title="Send Mail" aria-label="Email" color="secondary"><MailIcon /></IconButton> */}
                          </TableCell>
                        </TableRow>


                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={testHistory.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {/* </>
          ))} */}
        </>
		
		 {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const { idQuestionPaperDelivered, Teacher, DateDeliveredOn, SubjectName, ChapterInfo, PFormat } = row;
                      const isItemSelected = selected.indexOf(idQuestionPaperDelivered) !== -1;
                      return (
        <Card key={index} className={classes.hidemobile}>
          <div className={classes.cardbox}>
            <CardHeader className={classes.cardheader}
              avatar={
                <Avatar aria-label="recipe" >
                  A
                </Avatar>
              }

              title= {Teacher}
              subheader=<p>{SubjectName}{" , "}{DateDeliveredOn}</p>
            />
            <Grid container className={classes.margingap}>
              <Grid item xs={12} >
                <Typography variant="p" component="div" className={classes.labelhead}>
                  Format
                </Typography>
              </Grid>
             
              <Grid item xs={12} >

                <Typography variant="p" component="div" className={classes.contentans}>
                  {PFormat}
                </Typography>
              </Grid>
            
              <Grid item xs={12} md={8}>
                
               <Button variant="contained" className={classes.btnchapter} onClick={()=>handleClickOpen(ChapterInfo)} > View Chapter</Button>
                
                <Button variant="contained" className={classes.btnchapter3}> <IconButton className={classes.iconcolor} title="View" aria-label="View" color="primary" onClick={() => viewQuestion(idQuestionPaperDelivered)}><Tooltip title="View"  arrow><VisibilityIcon /></Tooltip></IconButton></Button>
        
              </Grid>
            </Grid>
            </div>
        </Card>
		 );
        })}
        <>
        <Dialog
        className={classes.chaptersdiv}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.headingchapter}>
        <DialogTitle id="alert-dialog-title" className={classes.paddzero}>
          Chapters
        </DialogTitle>
        <DialogActions className={classes.closebtn}>
          <Button onClick={handleClose}><img src="/static/mock-images/avatars/preview.png" alt="imgtest" className={classes.imgcross} /></Button>
        </DialogActions>
        </div>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <ul className={classes.ulmainpoint} >
            {chapters && chapters?.map((item, index) =>
            <li key={index} className={classes.ullicontent}><Chip label={item.ChapterName} variant="outlined" />
            </li>)}                      
          </ul>
          </DialogContentText>
        </DialogContent>
   
      </Dialog>
        </>
      </Container>
    <Loader isLoader={showLoder} showHide={setShowLoader} />           
    </Page>
  );
}
