import xpSolvService from "../axios/axios";

class PaymentService {


    getOrderId(data){
        return Promise.resolve(xpSolvService.post('getpgid', data));
    }

    validatePayment(data){
        return Promise.resolve(xpSolvService.post('validatepg', data));
    }
    
}

export default PaymentService;