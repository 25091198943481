// ----------------------------------------------------------------------

const account = {
  displayName: 'Sivesh Singh',
  email: 'sivesh200@moqtest.com',
  photoURL: '/static/mock-images/avatars/sign-out-alt.png',
  
};

export default account;
