import * as React from 'react';
import { useState, useEffect } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

// material
import { LoadingButton } from '@material-ui/lab';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import '../components/css/style.css';


import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Block } from '@mui/icons-material';
// components
import Page from '../components/Page';
import Loader from '../components/Loader';
import { TestApi } from '../service/index';
import Iframe from './Iframe';

toast.configure();

const useStyles = makeStyles((theme) => ({
  heightwidth:{
    height:'1000vh',
    margin:0,
    padding:0,
    overflow:'scroll',
  },
  btnsend:{
    display: 'initial',
    justifyContent: 'none',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'end',
    display: 'flex',

    },
  },
  btnprint:{
    height: "56px", 
    marginTop: "7px", 
    marginRight: "10px", 
    padding: "0 14px",
    float:'left',
    [theme.breakpoints.up('sm')]: {
      display:'block',
      height:'56px',

    },
  },
  btnprint2:{
    marginRight:10,
    height:'56px',
    marginTop:'7px',
    display:'none',
    [theme.breakpoints.up('sm')]: {
      display:'block',
    },
  },
  aligndiv:{
    display:'block',
    textAlign:'center',
    [theme.breakpoints.up('sm')]: {
      display:'inline-flex',
    },
  },
  widthdropdown:{
    width:'163px',
    float:'left',
  }
/*   header:{
    display: "block",
  },
  footer:{
    display: "block",
  },
 [`@media print`]:  {
    header, footer: {
      display: 'none',
    }
  } */

}));


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ViewQuestionTest() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState('');
  const classes = useStyles();
  const [showUser, setShowUser] = useState({
    firstName: '', lastName: '', className: '', board: ''
  })
  const [chapterList, setChapterList] = useState([]);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [viewQuestionData, setViewQuestionData] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [downloadId, setDownloadID] = useState('');
  const [chapters, setChapters] = useState([]);
  const [chaptersIds, setChaptersIds] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showLoder, setShowLoader] = useState(false);
  const [html, setHtml] = useState('');
  const [isTeacher, setIsTeacher] = useState(false);
  const [personName, setPersonName] = useState([]);
  const [sections, setSections] = useState([]);
  const [idStudentSchool, setIdStudentSchool] = useState(0);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const tempArray = [];

  useEffect(() => {
    if (state) {
      setSelectedUser(state?.selectedUser);
      setIdStudentSchool(state?.selectedUser?.idStudentSchool);
      if(state?.selectedUser?.role === 'Teacher' || state?.role === 'Teacher')
			{
				setIsTeacher(true);
      }   
      if(state?.idStudentSchool){
        setIdStudentSchool(state?.idStudentSchool);
      }
      setHtml(state?.data);
      if(state?.classId){
        getSections(state?.classId)
      }
    }
  }, []);

  const handleClose = () => {
    // setOpen(false);
  };

  const getSections = (classId) =>
  {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    userInfo[0]?.ClassSectionInfo.forEach((element, index) => 
    {
    if(element.idClass === classId)
    {
      element?.SectionInfo.forEach((ele, index) => 
    {
        tempArray.push(ele?.Section);
    });
      setSections(tempArray);
    }
   }); 
  }

  const printQuestionPaper = () =>
  {
    // console.log('html', document.getElementById("printf").contentWindow);
    // document.getElementById("printf").contentWindow.document.margin=0;
   /*  console.log('innerhtml', document.getElementById("printf").contentWindow.document.body.innerHTML);
    const content = document.getElementById("printf").contentWindow.document.body.innerHTML;
    const newWin = document.getElementById("printf").contentWindow;
    newWin.document.write(content);
    newWin.document.close();
    newWin.focus();
    newWin.print(); */

    document.getElementById("printf").contentWindow.print();
  }
  const publishToStudent = () => 
	{
		try 
		{  
      if(html.includes('GUQPID-'))
      {
        const groupIdArray = html.split("GUQPID-");
        const secondArray = groupIdArray[1].split(")");
        const idTestPaper = secondArray[0].trim(); 
        if(typeof personName.toString() === 'string' && personName.toString().trim().length === 0){
          toast.info(`Sections required!`, {
            position: toast.POSITION.TOP_RIGHT
            });
            return true;
        }
				setOpen(true);
				setShowLoader(true);
				const reqsBody = {
					"idStudentSchool": idStudentSchool,
					"idTestPaper": idTestPaper,
          "SectionList": personName.toString()
				};
				TestApi.publishToStudent(reqsBody).then(
					(resp) => {
						if (resp.status === 200) 
						{
							setShowLoader(false);
							navigate('/dashboard/test-history', { replace: true });
						}
						else {
							setShowLoader(false);
							alert(resp.message);
						}
					},
					(error) => { setShowLoader(false); alert("Error in publish to student"); }
				);
			}
		}
		catch (error) {
			console.log("Error in publish to student Catch", error);
		} 
	}

  return (
    <Page title="Plan Test | XPSOLV">
        <Grid item lg={12} xs={12} className={classes.btnsend}>
          <div className={classes.aligndiv}>
       {isTeacher && <div>
      <FormControl sx={{ m: 1, width: 300 }} className={classes.widthdropdown}>
        <InputLabel id="demo-multiple-checkbox-label">Sections *</InputLabel>
        <Select
        
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput label="Sections *" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          required
        >
          {sections.map((sections) => (
            <MenuItem key={sections} value={sections}>
              <Checkbox checked={personName.indexOf(sections) > -1} />
              <ListItemText primary={sections} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>}
         {isTeacher && 
          <LoadingButton
            variant="contained"
            onClick={publishToStudent}
            className={classes.btnprint}
          >Publish to Student</LoadingButton> }
          <LoadingButton
            variant="contained"
            onClick={printQuestionPaper}
            className={classes.btnprint2}
          >Print</LoadingButton>
          </div>
        </Grid>
      <Grid container>
      {html && 
        // <iframe id="testPaper" className={classes.heightwidth} /* src={`/qp/${selectedUser}.html`} */ frameBorder="0"  scrolling="no"  width="100%"  title="description" />          
        // document.getElementById('testPaper').innerHTML = `data:text/html;charset=utf-8,${  html}`;
        
        <Iframe content={html} id="printf" name="printf" />                    
      }
      
      </Grid>
      <Loader isLoader={showLoder} showHide={setShowLoader} />
    </Page>
  );
}
