import {
    Alert, AlertTitle, Box, Button, Checkbox, Container, FormControlLabel, FormGroup, Grid, InputLabel,
    MenuItem, Select, Stack, Tooltip, Typography
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { makeStyles, useTheme } from '@material-ui/styles';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';

import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import Loader from '../components/Loader';
// components
import Page from '../components/Page';
import { TestApi } from '../service/index';

toast.configure();

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    borderchangetext: {
        marginTop: 0,
        fontSize: 14,
        '& label.Mui-focused': {
            color: 'white',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'yellow',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'yellow',
            },
        },
    },
    cardborder: {
        border: '1px solid #c8c8c8',
        borderRadius: 10,
        padding: 25,

    },
    colorunselect: {
        background: '#fff',
        boxShadow: "none",
        minWidth: "27px",
        width: "7px",
        height: "27px",
        borderRadius: "83px",
        padding: '14px',
        "&:hover": {
            background: "transparent",
        }
    },
    marginleftright: {
        marginRight: "13px",
        width: "12px",
        height: "27px",
        borderRadius: "110px",
        padding: "0",
        minWidth: "27px",
        background: "#fff",
        boxShadow: 'none',
        "&:hover": {
            background: "transparent",
        }
    },
    label: {
        fontSize: 13,
        marginTop: 12,

    },
    colorred: {
        color: 'red',
        fontSize: 13,
    },
    buttonspacing: {
        float: 'right',
        marginTop: '10px',
    },
    btntext: {
        fontWeight: 500,
        width: '100%',
        marginTop: 23,
        color: '#fff',
        background: '#007c7c',
        "&:hover": {
            background: '#007c7c',
        }
    },

    headingpart: {
        color: '#007c7c',
    },
    themecode: {
        fontWeight: 600,
        color: '#000',
        fontSize: 16,
        textAlign: 'center',
        borderBottom: '1px solid #e1e1e1',
        paddingBottom: 15,
        marginBottom: 14,

    },
    borderttep: {
        borderBottom: '1px solid #d9d9d9',
        paddingBottom: 18,
        marginBottom: 60,
    },
    imgplan: {
        width: '90%',
        margin: '92px auto',

        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    testdialog: {
        textAlign: 'center',
        padding: '20px 0',
    },
    imgplan3: {
        width: '250px',
        margin: 'auto',
        position: 'relative',
    },
    imgpdf: {
        border: '1px solid #e3e3e3',
        position: 'relative',
        padding: 20,
        borderRadius: '8px',
        width: '400px',
        margin: '28px auto',

    },
    pdfdownload: {
        borderRadius: '4px',
        margin: 'auto',
        display: 'block',
        marginTop: 17,
        fontWeight: 600,
        padding: '11px 17px',
        background: '#007c7c',
        "&:hover": {
            background: '#007c7c',
        }
    },
    sendemail: {
        borderRadius: '4px',
        margin: 'auto',
        display: 'block',
        marginTop: 17,
        fontWeight: 600,
        padding: '14px 15px',
        background: 'transparent',
        border: '1px solid #007c7c',
        color: '#007c7c',
        boxShadow: 'none',
    },
    testgenrate: {
        padding: '0px 11px',
        color: '#000',
        fontWeight: 600,
        marginTop: 20,
        textAlign: 'center',
        fontSize: 19,
    },

    textpdf: {
        textAlign: 'center',
        marginTop: 25,
    },
    borderbtm: {
        paddingBottom: 0,
    },
    bgtextbtn: {
        background: '#007c7c',
    },
    closebtn: {
        position: 'absolute',
        right: 0,

    },
    closebtnfont: {
        fontSize: 25,
    },
    mainsecrpadd: {
        paddingRight: 0,
        [theme.breakpoints.up('sm')]: {
            paddingRight: 15,
        },
    },
    mainsecrpadd2: {
        paddingRight: 0,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: 15,
        },
    },

    checkboxfont: {
        fontSize: '14px !important ',
    },
    borderbotmmain: {
        borderBottom: '1px solid #dcdcdc',
        paddingBottom: 12,
    },
    infoicons: {
        float: "left",
        width: "15px",
        marginTop: "2px",
        marginRight: "5px"
    },
    infotext: {
        fontSize: "12px",
        marginTop: "9px",
        marginBottom: "0",
        fontWeight: "400"
    },
    boldfont: {
        fontWeight: 600,
    },
    alertalignment: {
        marginTop: 15,
    },
    thefont:{
        fontSize:'13px',
        marginTop:5,
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, chapters, theme) {
    return {
        fontWeight:
            chapters.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function PlanTest() {
    const theme = useTheme();
    const navigate = useNavigate();
    const [showLoder, setShowLoader] = useState(false);
    const classes = useStyles();
    const [chapterList, setChapterList] = useState([]);
    const [initialize, setInitialize] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [chapters, setChapters] = useState([]);
    const [chaptersIds, setChaptersIds] = useState([]);
    const [isChapterRequired, setIsChapterRequired] = useState(false);
    const [isTeacher, setIsTeacher] = useState(false);
    const [followUp, setFollowUp] = useState(false);
    const [classess, setClassess] = useState([]);
    const [idClass, setIdClass] = useState();
    const [hasSubChapter, setHasSubChapter] = useState(false);
    const [subjectInfo, setSubjectInfo] = useState([]);
    const [subjectListInfo, setSubjectListInfo] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [classSections, setClassSections] = useState([]);
    const [personName, setPersonName] = useState([]);
    const [sections, setSections] = useState([]);


    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        setSelectedUser(userInfo[0]?.StudentInfo[0]);
        teacherClasses(userInfo[0]?.StudentInfo[0].idStudentSchool);
        setClassSections(userInfo[0]?.ClassSectionInfo);
        if (userInfo[0]?.StudentInfo && userInfo[0].StudentInfo.length === 1 && userInfo[0].StudentInfo[0]?.role === 'Teacher') {
            setIsTeacher(true);
        }
    }, []);

    const handleChangeSection = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const validationRules = {
        testSubject: Yup.string().required('Subject is required'),
    }
    if (isTeacher) {
        validationRules.class = Yup.string().required('Class is required');
    }
    const TestSchema = Yup.object().shape(validationRules);
    const formik = useFormik({
        initialValues: {
            testSubject: "",
            testSubSubject: "",
            chapterIds: "",
            class: "",
            SectionList: ""
        },
        validationSchema: TestSchema,
        onSubmit: (data) => {
            if (chaptersIds.length < 1) {
                setIsChapterRequired(true);
                formik.setSubmitting(false);
                return false;
            }
            createTest(data);
        },
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    const initializeTestByStudentIdAndClassId = (idSchool, idClass, idStudentSchool) => {
        try {
            const reqsBody = {
                "idSchool": idSchool,
                "idClass": idClass,
                "idStudentSchool": idStudentSchool,
				"testHWCall":2, // added to filter class, subjects, and chapters with no data 
            };
            TestApi.initializeTestByStudentIdAndClassId(reqsBody).then(
                (resp) => {
                    if (resp.status === 200) {
                        if (resp.data.status === 200) {
                            setInitialize(resp.data.data[0]);
                            setFollowUp(true);
                        }
                        else {
                            alert(resp.data.message);
                        }
                    }
                    else {
                        alert(resp.message);
                    }
                },
                (error) => { alert("Error in initializeTestByStudentIdAndClassId", error.message); }
            );
        }
        catch (error) {
            console.log("initializeTestByStudentIdAndClassId Catch", error);
        }
    }
    const teacherClasses = (idStudentSchool) => {
        try {
            const reqsBody = {
                "idStudentSchool": idStudentSchool,
				"testHWCall":2, // added to filter class, subjects, and chapters with no data 
            };
            TestApi.teacherClasses(reqsBody).then(
                (resp) => {
                    if (resp.status === 200) {
                        if (resp.data.status === 200) {
                            const response = resp.data?.data[0]?.ClassInfo;
                            setClassess(response);
                        }
                        else {
                            alert(resp.data.message);
                        }
                    }
                    else {
                        alert(resp.message);
                    }
                },
                (error) => { alert("Error in teacherClasses"); }
            );
        }
        catch (error) {
            console.log("teacherClasses Catch", error);
        }
    }
    const getChckeboxValue = (idChapter) => {
        console.log('event.target', idChapter, chaptersIds);
        const chapterArray = [...chaptersIds]

        const index = chapterArray.indexOf(idChapter);


        if (index > -1) {
            chapterArray.splice(index, 1);
        }

        else {
            chapterArray.push(idChapter);
        }
        setChaptersIds(chapterArray)
        if (chapterArray.length < 1) {
            setIsChapterRequired(true);
        }
        else {
            setIsChapterRequired(false);
        }

        setErrorMessage('');
    }
    const createTest = (data) => {
        try {
            const reqsBody = {
                "idStudentSchool": selectedUser.idStudentSchool,
                "chapterids": chaptersIds.toString(),
                "SectionList": personName.toString(),
                "idClassSubject": data.testSubject,
            };
            navigate('/dashboard/createHomework', { replace: true, state: reqsBody });
            return false;
        }
        catch (error) {
            console.log("create test Catch", error);
        }
    }

    const selectAll = (event) => {
        setChaptersIds([]);
        const chapterArray = [];
        if (chapterList) {
            chapterList.forEach((item) => {
                chapterArray.push(item.idChapter);
            })
        }

        setChaptersIds(chapterArray);
        setIsChapterRequired(false);
    }

    const unselectAll = (event) => {
        setChaptersIds([]);
        setIsChapterRequired(true);
    }

    const handleChange = (event) => {
        console.log('event', event.target.value);
        setErrorMessage('');
        if (event.target.value === '') {
            setSubjectListInfo([]);
            setSubjectInfo([]);
            setHasSubChapter(false);
            setChapterList([]);
            formik.setFieldValue("testSubject", event.target.value);
        }
        console.log('initialize', initialize);
        console.log('chapterlist', chapterList);
        if (initialize) {

            // eslint-disable-next-line array-callback-return
            initialize?.ClassSubjectInfo?.map((item) => {
                console.log('item', item);
                if (item.idClassSubject === event.target.value) {
                    setChaptersIds([]);
                    setChapters([]);
                    setSubjectListInfo(item.SubjectInfo);
                    setSubjectInfo(item.SubjectInfo);
                    if (item?.SubjectInfo.length > 1) {
                        setHasSubChapter(true);
                        formik.setFieldValue("testSubSubject", "ALL");

                        const tempChapterInfo = [];
                        // eslint-disable-next-line array-callback-return
                        item?.SubjectInfo?.map((subjectObj) => {
                            if (subjectObj?.ChapterInfo && subjectObj?.ChapterInfo.length > 0) {
                                subjectObj?.ChapterInfo?.forEach((chapterItem) => {
                                    tempChapterInfo.push(chapterItem);
                                });
                            }
                        });

                        if (tempChapterInfo && tempChapterInfo.length > 0) {
                            setChapterList(tempChapterInfo);
                        }
                    }
                    else {
                        setHasSubChapter(false);
                        setChapterList(item?.SubjectInfo[0]?.ChapterInfo);
                    }
                    //  multiselectRef.current.resetSelectedValues();0
                    formik.setFieldValue("testSubject", event.target.value);
                }
            });
        }
    };

    const tempArray = [];


    const handleChangeSubSubject = (event) => {
        console.log('event', event.target.value);
        setErrorMessage('');
        if (event.target.value === '') {
            setChapterList([]);
            formik.setFieldValue("testSubSubject", event.target.value);
        }
        console.log('initialize', subjectListInfo);
        console.log('chapterlist', chapterList);
        if (subjectListInfo && event.target.value.trim() !== "ALL") {
            // eslint-disable-next-line array-callback-return
            subjectListInfo?.map((item) => {
                console.log('item', item);
                if (item.SubChapter.trim() === event.target.value.trim()) {
                    setChaptersIds([]);
                    setSubjectInfo([item]);
                    setChapters(item.ChapterInfo);
                }
            });
        }
        else {
            setChaptersIds([]);
            setSubjectInfo(subjectListInfo);
        }
        formik.setFieldValue("testSubSubject", event.target.value);
    };
    const handleClassChange = (event) => {
        setErrorMessage('');
        console.log('event', event.target.value);
        if (event.target.value === '') {
            setChapterList([]);
            setChaptersIds([]);
            setInitialize([]);
            setSubjectListInfo([]);
            setSubjectInfo([]);
            setHasSubChapter(false);
            setFollowUp(false);
            formik.setFieldValue("class", event.target.value);
            formik.setFieldValue("testSubject", '');
            setPersonName([]);
        }
        else {
            classSections.forEach((item) => {
                if (item.idClass === event.target.value) {
                    item?.SectionInfo.forEach((ele, index) => {
                        tempArray.push(ele?.Section);
                    });
                    setSections(tempArray);
                    console.log('item', item);
                }
            })
            initializeTestByStudentIdAndClassId(selectedUser.idSchool, event.target.value, selectedUser.idStudentSchool);
            formik.setFieldValue("class", event.target.value);
            setIdClass(event.target.value);
            setFollowUp(true);
        }

    };
    return (
        <Page title="Add Homework| XPSOLV">
            <Container>

                <Stack direction="row" alignItems="center" className={classes.borderttep} justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Create <Box component="span" display="inline" className={classes.headingpart}>Homework</Box>
                    </Typography>
                </Stack>
                <Container>
                    <Grid container  >
                        <Grid item className={classes.cardborder} lg={8} md={7} sm={7} xs={12}>
                            <FormikProvider value={formik}>
                                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                    <Grid container  >

                                        {isTeacher && <><Grid item lg={12} md={6} sm={12} xs={12} className={classes.mainsecrpadd}>
                                            <Stack justifyContent="space-between">
                                                <InputLabel id="demo-simple-select-filled-label" className={classes.label}>Class</InputLabel>
                                                <Select

                                                    fullWidth
                                                    className={classes.borderchangetext}
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"
                                                    label="Class"
                                                    {...getFieldProps('class')}
                                                    onChange={handleClassChange}
                                                    error={touched.class && errors.class}
                                                    helpertext={touched.class && errors.class}
                                                >
                                                    <MenuItem value="">
                                                        <em className={classes.borderchangetext}>Select Class</em>
                                                    </MenuItem>
                                                    {classess?.map((option, index) => (
                                                        <MenuItem key={index} value={option.idClass} className={classes.borderchangetext}>
                                                            {option.ClassName}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {touched.class && errors.class && <p className={classes.colorred}>{errors.class}</p>}
                                            </Stack>
                                        </Grid> </>}
                                        {isTeacher && followUp && <Grid item lg={6} md={6} sm={12} xs={12} className={classes.mainsecrpadd}>
                                            <FormControl sx={{ width: '100%' }} className={classes.widthdropdown}>
                                                <InputLabel className={classes.thefont} id="demo-multiple-checkbox-label">Sections</InputLabel>
                                                <Select

                                                    labelId="demo-multiple-checkbox-label"
                                                    id="demo-multiple-checkbox"
                                                    multiple
                                                    value={personName}
                                                    onChange={handleChangeSection}
                                                    input={<OutlinedInput label="Sections *" />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={MenuProps}
                                                    required
                                                >
                                                    {sections.map((sections) => (
                                                        <MenuItem key={sections} value={sections}>
                                                            <Checkbox checked={personName.indexOf(sections) > -1} />
                                                            <ListItemText primary={sections} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            </Grid>
}
                                        {followUp && <><Grid item lg={6} md={6} sm={12} xs={12} className={classes.mainsecrpadd}>
                                            <Stack justifyContent="space-between">
                                                <InputLabel id="demo-simple-select-filled-label" className={classes.label}>Subject</InputLabel>
                                                <Select
                                                    className={classes.borderchangetext}
                                                    fullWidth
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"
                                                    label="Subject"
                                                    {...getFieldProps('testSubject')}
                                                    onChange={handleChange}
                                                    //	onClick={(e) => { handleClose(e) }}
                                                    error={touched.testSubject && errors.testSubject}
                                                    helpertext={touched.testSubject && errors.testSubject}
                                                >
                                                    <MenuItem value="">
                                                        <em className={classes.borderchangetext}>Select Subject</em>
                                                    </MenuItem>
                                                    {initialize?.ClassSubjectInfo?.map((option, index) => (
                                                        <MenuItem key={index} value={option.idClassSubject} className={classes.borderchangetext}>
                                                            {option.SubjectName}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {touched.testSubject && errors.testSubject && <p className={classes.colorred}>{errors.testSubject}</p>}
                                            </Stack>
                                        </Grid>
                                        </>}
                                        
                                    </Grid>
                                    {followUp && hasSubChapter && <><Stack spacing={2} justifyContent="space-between">
                                        <InputLabel id="demo-simple-select-filled-label" className={classes.label}>Sub-Subject</InputLabel>
                                        <Select
                                            className={classes.borderchangetext}
                                            fullWidth
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            label="Sub-Subject"
                                            {...getFieldProps('testSubSubject')}
                                            onChange={handleChangeSubSubject}
                                            //	onClick={(e) => { handleClose(e) }}
                                            error={touched.testSubSubject && errors.testSubSubject}
                                            helpertext={touched.testSubSubject && errors.testSubSubject}
                                        >
                                            <MenuItem value="ALL">
                                                <em className={classes.borderchangetext} >All</em>
                                            </MenuItem>
                                            {subjectListInfo?.map((option, index) => (
                                                <MenuItem key={index} value={option.SubChapter} className={classes.borderchangetext}>
                                                    {option.SubChapter}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {touched.testSubSubject && errors.testSubSubject && <p className={classes.colorred}>{errors.testSubSubject}</p>}
                                    </Stack></>}
                                    {followUp && <><Stack spacing={2} justifyContent="space-between">
                                        {chapterList.length > 0 && values.testSubject &&
                                            <Grid container >
                                                <Grid item xs={6} md={6}>
                                                    <InputLabel id="demo-simple-select-filled-label" className={classes.label}>Chapter</InputLabel>
                                                </Grid>
                                                <Grid item xs={6} md={6}>
                                                    <div className={classes.buttonspacing}>
                                                        <Tooltip title="Select All" arrow><Button variant="contained" onClick={(event) => { selectAll(event) }} className={classes.marginleftright}>
                                                            <img src="/static/mock-images/avatars/task.png" alt="iconback" style={{ width: '23px', height: '23px', margin: 0, marginLeft: 0 }} /></Button></Tooltip>
                                                        <Tooltip title="Unselect All" arrow><Button variant="contained" onClick={(event) => { unselectAll(event) }} className={classes.colorunselect} ><img src="/static/mock-images/avatars/x-mark.png" alt="iconback" style={{ minWidth: '19px', height: '19px', margin: 0, marginLeft: 0 }} /></Button></Tooltip>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        }
                                        {hasSubChapter && subjectInfo?.map((subjectOption, ind) => (
                                            <><Stack key={ind}>
                                                <Grid item md={12} className={classes.borderbotmmain} >
                                                    <InputLabel id="demo-simple-select-filled-label" className={classes.label}>{subjectOption?.SubChapter}</InputLabel>
                                                </Grid>
                                                <Grid container>
                                                    {subjectOption?.ChapterInfo?.map((option, index) => (
                                                        <Grid key={index} item xs={12} md={6}>
                                                            <FormGroup >

                                                                <FormControlLabel color="success"
                                                                    error={touched.chapterList && errors.chapterList}
                                                                    helpertext={touched.chapterList && errors.chapterList} name="chapterList"
                                                                    control={<Checkbox id={option.Chapter}
                                                                        error={touched.chapterList && errors.chapterList}
                                                                        helpertext={touched.chapterList && errors.chapterList} name="chapterList"
                                                                        checked={chaptersIds.indexOf(option.idChapter) >= 0}
                                                                        onChange={() => getChckeboxValue(option.idChapter)}
                                                                    />}
                                                                    label={<Typography className={classes.checkboxfont}>{option.Chapter}</Typography>}
                                                                />
                                                            </FormGroup>

                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Stack>
                                            </>
                                        ))}
                                        {followUp && !hasSubChapter && <>
                                            <Grid container>
                                                {chapterList?.map((option, index) => (
                                                    <Grid key={index} item xs={12} md={6}>
                                                        <FormGroup >

                                                            <FormControlLabel color="success"
                                                                error={touched.chapterList && errors.chapterList}
                                                                helpertext={touched.chapterList && errors.chapterList} name="chapterList"
                                                                control={<Checkbox id={option.Chapter}
                                                                    error={touched.chapterList && errors.chapterList}
                                                                    helpertext={touched.chapterList && errors.chapterList} name="chapterList"
                                                                    checked={chaptersIds.indexOf(option.idChapter) >= 0}
                                                                    onChange={() => getChckeboxValue(option.idChapter)}
                                                                />}
                                                                label={<Typography className={classes.checkboxfont}>{option.Chapter}</Typography>}
                                                            />
                                                        </FormGroup>

                                                    </Grid>
                                                ))}

                                            </Grid>
                                        </>
                                        }
                                        {isChapterRequired ? <p className={classes.colorred}>Chapter is required</p> : ''}
                                    </Stack> </>}
                                    {errorMessage && <Alert severity="error" className={classes.alertalignment}>
                                        <AlertTitle>Error</AlertTitle>
                                        {errorMessage}
                                    </Alert>}
                                    <LoadingButton
                                        spacing={2}
                                        size="large"
                                        type="submit"
                                        loading={isSubmitting}
                                        className={classes.btntext}
                                    >
                                        Create Homework
                                    </LoadingButton>
                                </Form>
                            </FormikProvider>

                        </Grid>
                        <Grid item lg={4} md={5} sm={5} xs={12}>

                            <img src="/static/mock-images/avatars/plantest2.png" alt="imgtest" className={classes.imgplan} />

                        </Grid>
                    </Grid>
                </Container>
            </Container>
            <Loader isLoader={showLoder} showHide={setShowLoader} />
        </Page>
    );

}
