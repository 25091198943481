import { Box, Grid, Link, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      maxWidth: 345,
    },
    textcolor: {
      color: '#007c7c',
    },
    textcolor2: {
      color: '#999',
      fontWeight: 500,
    },    
    accountsummary: {
      borderBottom: '1px solid #c9c9c9',
      paddingTop: 18,
      paddingLeft: 0,
      marginBottom: 22,
    }, 
    summaryaccout: {
        borderBottom: '1px solid #c9c9c9',
        padding: 10,
        paddingTop: 0,
        paddingLeft: 0,
        marginBottom: 0,
        width: 'auto',
        fontSize: 16,
        [theme.breakpoints.up('sm')]: {
          width: '978px',
        },
      },
    
  }));

export default function Support() {

      const classes = useStyles();

    return (
        <div style={{ marginTop: 1, padding: ' 0 50px', }}>
            {/* <Link to="/login" component={RouterLink} style={{ display: "flex", width: "75px", padding: "9px 7px 0 6px", lineHeight: "24px", background: "#089293", border: "none", color: "#fff", borderRadius: 6, marginBottom: "14px" }}> <img src="/static/mock-images/avatars/angle-left-free-icon-font.png" alt="imgtest" style={{ width: 15, marginTop: "3px", marginRight: "6px", marginBottom: "11px" }} />back</Link> */}
            {/* <h2>Support & Help</h2> */}
            <Typography variant="h4" gutterBottom className={classes.summaryaccout}>
                    Support <Box component="span" display="inline" className={classes.textcolor}>& Help</Box>
            </Typography>

           {/*  <h3 style={{ padding: ' 15px 0', fontSize: 16, color: '#797D7F'}}>Last updated on Dec 14, 2022</h3> */}

            {/* <div>                
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    You may contact us using the information below:
                </Typography>
            </div> */}

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Paper elevation={2} sx={{ padding: 1 }}>
                        <Typography sx={{ mb: 1.5, fontSize: 16, fontWeight:800 }} color="text.primary">
                            Xpsolv Users 
                        </Typography>
                        <Typography sx={{ mb: 1.5, fontSize: 14 }} color="text.primary">
                        Xpsolv has setup a support channel to receive feedbacks, complaints, and help requests through an email id. All applications users – student, parents, teachers and point of contact (POC) for schools – can use this email to write to Xpsolv support team. 
                        </Typography>
                        <Typography sx={{ mb: 2, fontSize: 14 }} color="text.primary">
                            For any feedbacks, complaints, and help requests, send an email to below mention support email id. 
                        </Typography>
                        <Typography sx={{ mb: 2, fontSize: 14 }} color="text.primary">
                            <span>Support Email Id:</span> <span style={{fontWeight:800}}><a href='mailto:connect@xpsolv.com'>connect@xpsolv.com</a></span>
                        </Typography>
                        <Typography sx={{ mb: 1.5, fontSize: 14 }} color="text.primary">
                        On receiving the email, a ticket will be created on support web portal and ticket number will be shared through email response. Xpsolv Support Team will work on the ticket and will resolve it in 2-3 working days. On resolution of ticket, updates will be shared with user through their email id, used for reporting the matter.  
                        </Typography>

                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Paper elevation={2} sx={{ padding: 1 }}>
                        <Typography sx={{ mb: 1.5, fontSize: 16, fontWeight:800 }} color="text.primary">
                            Single Point Of Contact – Schools 
                        </Typography>
                        <Typography sx={{ mb: 1.5, fontSize: 14 }} color="text.primary">
                            For schools, a single point of contact (SPOC) will be onboarded on support web portal (<Link href='https://support.xpsolv.com/'>https://support.xpsolv.com</Link>). SPOC will have access credentials (user id & password) to access the web portal. SPOC will have advantage of managing the support request for respective schools directly in the portal like creating a ticket directly on support portal, reviewing status of open tickets, commenting on the ticket whenever required to expedite the support etc. SPOC would be designated by school and will use an authorized email id, preferably on school domain wherever possible. His/her account will belong to school instead of himself/herself. 
                        </Typography>
                        <Typography sx={{ mb: 2, fontSize: 14 }} color="text.primary">
                            To request for SPOC account, an email must be sent to Xpsolv Support Email Id (connect@xpsolv.com). 
                        </Typography>
                        <Typography sx={{ mb: 2, fontSize: 14 }} color="text.primary">
                            <span style={{fontWeight:800}}>Subject:</span> <span>Request for SPOC account - school name </span>
                        </Typography>
                        <Typography sx={{ mb: 1.5, fontSize: 14 }} color="text.primary">
                            On receiving the email from SPOC, Xpsolv support team will initiate the account setup. SPOC will receive an email to reset his/her password. On completing the step, SPOC will be allowed to access his/her account on support web portal (<Link href='https://support.xpsolv.com/'>https://support.xpsolv.com</Link>). 
                        </Typography>

                    </Paper>
                </Grid>
         
            </Grid>
           
            <div>
                &nbsp;{/* <p style={{ fontSize: 14, }}>&nbsp;</p> */}
            </div>

        </div>
    );
}