class UtilitiesService {

    base64ToPDF(pdf, fileName) {
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
}

export default UtilitiesService;