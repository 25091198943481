import { Link as RouterLink, useNavigate} from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import { Card, Stack, Link, Container, Typography, Box } from '@material-ui/core';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';


// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));



// ----------------------------------------------------------------------

export default function Login() {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('token') && localStorage.getItem('userInfo')) {
     navigate('/dashboard/app', { replace: true });    
    }
  }, []);

  return (
    <RootStyle title="Login | XPSOLV">
      <AuthLayout>
        {/* Don’t have an account? &nbsp; */}
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/register">
          {/* Get started */}
        </Link>
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle style={{backgroundImage: "url(/static/illustrations/seamless.png)",backgroundSize: "contain",backgroundRepeat: "repeat"}}>
        <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Platform to  <Box component="span" display="inline"  style={{color:'#007c7c',}}>Practice Test</Box>
          </Typography>
          {/* <img src="/static/illustrations/illustration_login.png" alt="login" /> */}
          <img style={{borderRadius:'25px', margin:'2px 2px'}} src="/static/illustrations/login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }} style={{marginBottom:20,}}>
            <Typography variant="h5" gutterBottom >
              Login with registered Mobile No
            </Typography>
            {/* <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography> */}
          </Stack>
          {/* <AuthSocial /> */}

          <LoginForm />

        {/*   <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
             
              <Link variant="subtitle2" component={RouterLink} to="register">
                Get started
              </Link>
            </Typography>
          </MHidden> */}
        </ContentStyle>
      </Container>      
    </RootStyle>
  );
}
